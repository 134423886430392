export const Button = {
  // style object for base or default style
  baseStyle: {
    fontWeight: 'regular',
    borderRadius: '4px',
    width: 'fit-content',
    _focus: {
      boxShadow: 'none',
      outline: 'none',
    },
  },
  variants: {
    solid: {
      bg: 'blue.500',
      color: 'white',
      border: 'none',
      _hover: {
        bg: 'blue.700',
        _disabled: {
          bg: 'blue.500',
        },
      },
      _disabled: {
        bg: 'blue.500',
      },
      _active: {
        bg: 'blue.700',
      },
    },
    'solid-dest': {
      border: 'none',
      bg: 'red.500',
      color: 'white',
      _hover: {
        bg: 'red.700',
        _disabled: {
          bg: 'red.500',
        },
      },
      _disabled: {
        bg: 'red.500',
      },
      _active: {
        bg: 'red.700',
      },
    },
    outline: {
      bg: 'white',
      color: 'blue.500',
      border: '1px solid',
      borderColor: 'blue.400',
      _hover: {
        bg: 'blue.50',
        _disabled: {
          text: 'blue.400',
        },
      },
      _disabled: {
        bg: 'white',
        borderColor: 'blue.400',
        text: 'blue.400',
      },
      _active: {
        bg: 'blue.100',
        boxShadow: 'none',
      },
    },
    'outline-dest': {
      bg: 'white',
      color: 'red.500',
      border: '1px solid',
      borderColor: 'red.400',
      _hover: {
        bg: 'red.50',
        _disabled: {
          text: 'red.400',
        },
      },
      _disabled: {
        bg: 'white',
        borderColor: 'red.400',
        text: 'red.400',
      },
      _active: {
        bg: 'red.100',
        boxShadow: 'none',
      },
    },
    ghost: {
      color: 'blue.500',
      _hover: {
        bg: 'blue.50',
        _disabled: {
          text: 'blue.400',
        },
      },
      _disabled: {
        bg: 'white',
        text: 'blue.400',
      },
      _active: {
        bg: 'blue.100',
        boxShadow: 'none',
      },
    },
    'ghost-dest': {
      color: 'red.500',
      _hover: {
        bg: 'red.50',
        _disabled: {
          text: 'red.400',
        },
      },
      _disabled: {
        bg: 'white',
        text: 'red.400',
      },
      _active: {
        bg: 'red.100',
        boxShadow: 'none',
      },
    },
    link: {
      bg: 'none',
      color: 'blue.500',
      border: 'none',
      _hover: {
        textDecoration: 'none',
        color: 'blue.900',
        _disabled: {
          text: 'blue.400',
        },
      },
      _disabled: {
        text: 'blue.400',
      },
      _active: {
        color: 'blue.700',
      },
    },
    iconButton: {
      bg: 'none',
      color: 'blue.500',
      border: 'none',
      _hover: {
        textDecoration: 'none',
        color: 'blue.700',
        _disabled: {
          text: 'blue.400',
        },
      },
      _disabled: {
        text: 'blue.400',
      },
      _active: {
        color: 'blue.700',
      },
    },
  },
  defaultProps: {
    size: 'sm',
    variant: 'solid',
    colorScheme: 'blue',
  },
};
