import React, { MouseEventHandler } from 'react';

import { QualioTheme } from '../../theme';
import { SpaceProps } from 'styled-system';

import {
  TextButton,
  PrimaryButton,
  SecondaryButton,
  IconButton,
} from './Button.styles';

import { Spinner } from '../Spinner';

type DefaultProps = {
  /** The function triggered when the button is pressed */
  onClick?: MouseEventHandler<HTMLButtonElement>;
  /** The html type of the button (wraps the type property) */
  htmlType?: 'button' | 'submit' | 'reset';
  large?: boolean;
  disabled?: boolean;
  icon?: JSX.Element;
} & SpaceProps<QualioTheme>;

export type PrimaryButtonProps = {
  /** The UI of the button to be rendered */
  type?: 'primary';
  /** Disable the button */
  loading?: boolean;
  children: React.ReactNode;
} & DefaultProps;

export type SecondaryButtonProps = {
  /** The UI of the button to be rendered */
  type: 'secondary';
  /** Disable the button */
  loading?: boolean;
  children: React.ReactNode;
} & DefaultProps;

export type TextButtonProps = {
  /** The UI of the button to be rendered */
  type: 'text';
  large?: boolean;
  iconPosition?: 'left' | 'right';
  children: React.ReactNode;
} & DefaultProps;

export type IconButtonProps = {
  /** The UI of the button to be rendered */
  type: 'icon';
  title: string;
  large?: boolean;
  small?: boolean;
} & DefaultProps;

export type ButtonProps =
  | PrimaryButtonProps
  | SecondaryButtonProps
  | TextButtonProps
  | IconButtonProps;

const Button: React.ForwardRefRenderFunction<HTMLButtonElement, ButtonProps> = (
  props,
  ref,
) => {
  const { children, ...buttonProps } = props;

  if (!buttonProps.type) {
    buttonProps.type = 'primary';
  }
  switch (buttonProps.type) {
    case 'primary': {
      const { icon, loading, ...rest } = buttonProps;
      return (
        <PrimaryButton {...rest} ref={ref} type={buttonProps.htmlType}>
          {loading ? (
            <React.Fragment>
              <Spinner styles={{ position: 'absolute' }} />
              <div style={{ visibility: 'hidden' }}>{children}</div>
            </React.Fragment>
          ) : icon ? (
            <React.Fragment>
              {children}
              <i style={{ marginLeft: '8px' }}>
                {React.cloneElement(icon, {
                  style: { fontSize: '16px' },
                })}
              </i>
            </React.Fragment>
          ) : (
            children
          )}
        </PrimaryButton>
      );
    }
    case 'secondary': {
      const { icon, loading, ...rest } = buttonProps;
      return (
        <SecondaryButton {...rest} ref={ref} type={buttonProps.htmlType}>
          {loading ? (
            <React.Fragment>
              <Spinner secondary styles={{ position: 'absolute' }} />
              <div style={{ visibility: 'hidden' }}>{children}</div>
            </React.Fragment>
          ) : icon ? (
            <React.Fragment>
              {children}
              <i style={{ marginLeft: '8px' }}>
                {React.cloneElement(icon, {
                  style: { fontSize: '16px' },
                })}
              </i>
            </React.Fragment>
          ) : (
            children
          )}
        </SecondaryButton>
      );
    }
    case 'text': {
      const { icon, iconPosition, ...rest } = buttonProps;
      return (
        <TextButton {...rest} ref={ref} type={buttonProps.htmlType}>
          {icon && iconPosition === 'left' ? (
            <i style={{ marginRight: '8px' }}>
              {React.cloneElement(icon, {
                style: { fontSize: '16px' },
              })}
            </i>
          ) : null}
          {children}
          {icon && iconPosition === 'right' ? (
            <i style={{ marginLeft: '8px' }}>
              {React.cloneElement(icon, {
                style: { fontSize: '16px' },
              })}
            </i>
          ) : null}
        </TextButton>
      );
    }
    case 'icon': {
      const { icon, ...rest } = buttonProps;
      return (
        <IconButton {...rest} ref={ref} type={buttonProps.htmlType}>
          {icon}
        </IconButton>
      );
    }
  }
};

/**
 * @deprecated
 * Please use equivalent component in QAtoms or QComponents
 */

export default React.forwardRef<HTMLButtonElement, ButtonProps>(Button);
