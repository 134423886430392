import { ToastId, ToastOptions, ToastState } from '@chakra-ui/react';
import type { ToastPosition } from './toast.placement';

/**
 * Given an array of toasts for a specific position.
 * It returns the toast that matches the `id` passed
 */
export const findById = (
  arr: ToastOptions[],
  id: ToastId,
): ToastOptions | undefined => arr.find((toast) => toast.id === id);

/**
 * Given the toast manager state, finds the toast that matches
 * the id and return its position and index
 */
export function findToast(
  toasts: ToastState,
  id: ToastId,
): { position: ToastPosition; index: number } {
  const position = getToastPosition(toasts, id) as ToastPosition;

  const index = position
    ? toasts[position].findIndex((toast) => toast.id === id)
    : -1;

  return {
    position,
    index,
  };
}

/**
 * Given the toast manager state, finds the position of the toast that
 * matches the `id`
 */
export const getToastPosition = (
  toasts: ToastState,
  id: ToastId,
): ToastPosition | undefined =>
  Object.values(toasts)
    .flat()
    .find((toast) => toast.id === id)?.position;

/**
 * Given the toast manager state, checks if a specific toast is
 * still in the state, which means it is still visible on screen.
 */
export const isVisible = (toasts: ToastState, id: ToastId): boolean =>
  !!getToastPosition(toasts, id);

/**
 * Gets the styles to be applied to a toast's container
 * based on its position in the manager
 */
export function getToastStyle(position: ToastPosition): React.CSSProperties {
  const isRighty = position.includes('right');
  const isLefty = position.includes('left');

  let alignItems = 'center';
  if (isRighty) {
    alignItems = 'flex-end';
  }
  if (isLefty) {
    alignItems = 'flex-start';
  }

  return {
    display: 'flex',
    flexDirection: 'column',
    alignItems,
  };
}
