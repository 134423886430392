import { zodResolver } from '@hookform/resolvers/zod';
import { useMemo } from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';
import { z } from 'zod';
import { useFiltering } from './context';
import { FilterFormSchema } from './schemas';
import { FilterDefinitions, ResolvedFilters } from './types';

export const useFilteringForm = <
  T extends FilterDefinitions,
>(): UseFormReturn => {
  const { definitions, filters } = useFiltering<T>();

  const defaultValues = useMemo(
    () => createDefaultValues(filters, definitions),
    [filters, definitions],
  );

  const resolver = useMemo(
    () => zodResolver(schemaFromDefinitions(definitions)),
    [definitions],
  );

  return useForm({
    defaultValues,
    resolver,
    mode: 'onChange',
    reValidateMode: 'onChange',
  });
};

/**
 * Maps a record of filter definitions to a zod object schema
 * that can then be used with react-hook-form.
 */
const schemaFromDefinitions = <T extends FilterDefinitions>(
  definitions: T,
): FilterFormSchema<T> =>
  z.object(
    Object.entries(definitions).reduce(
      (acc, [key, { schema }]) => ({ ...acc, [key as keyof T]: schema }),
      {} as {
        [K in keyof T]: T[K]['schema'];
      },
    ),
  );

/**
 * Creates an initial value for each filter definition.
 * If a filter already has a value, that value is used.
 * Otherwise, an empty string is used (we use an
 * empty string to say that the input is 'controlled').
 *
 * Why do we need both `filters` and `definitions` here?
 * - `filters` only has the *active* filters, it does not
 *   contain unset filters.
 * - We need `definitions` in order to end up with an
 *   object that contains a default value for *every*
 *   possible filter.
 */
const createDefaultValues = <T extends FilterDefinitions>(
  filters: ResolvedFilters<T>,
  definitions: T,
) =>
  Object.keys(definitions).reduce(
    (acc, key: keyof T) => ({
      ...acc,
      [key]: filters[key]?.value ?? '',
    }),
    {},
  );
