import { Icon } from '@chakra-ui/react';
import { FiArrowUp, FiArrowDown } from 'react-icons/fi';
import { SortDirection } from '@tanstack/react-table';
import React from 'react';

export type SortingIconsProps = {
  direction: SortDirection | false;
};

export const SortingIcon: React.VFC<SortingIconsProps> = ({ direction }) => (
  <Icon
    visibility={direction === false ? 'hidden' : undefined}
    aria-hidden={direction === false}
    width="16px"
    height="16px"
    marginBottom="-3px"
    color="gray.500"
    as={direction === 'asc' ? FiArrowDown : FiArrowUp}
  />
);
