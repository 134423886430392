import { Thead, Tr } from '@chakra-ui/react';
import React from 'react';
import { HeaderGroup } from 'react-table';
import { QDataRow } from '../DataTable';
import { QHeader } from './Header';

export interface QTableHeadProps {
  headerGroups: HeaderGroup<QDataRow>[];
}

export const QTableHead: React.FC<QTableHeadProps> = ({ headerGroups }) => (
  <Thead>
    {headerGroups.map((headerGroup) => (
      // `getHeaderGroupProps` returns a interface with a key field.
      // eslint-disable-next-line react/jsx-key
      <Tr {...headerGroup.getHeaderGroupProps()}>
        {headerGroup.headers.map((column) => (
          <QHeader key={column.id} column={column} />
        ))}
      </Tr>
    ))}
  </Thead>
);
