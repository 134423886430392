import React from 'react';

export const ArrowRight: React.FC = (props) => {
  return (
    <svg
      {...props}
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.78 8.66667L8.39332 11.06L9.33332 12L13.3333 8L9.33332 4L8.39332 4.94L10.78 7.33333H2.66666V8.66667H10.78Z"
        fill="#0069FF"
      />
    </svg>
  );
};

export default ArrowRight;
