import React, { useMemo } from 'react';
import { QBox, QFlex, QSpacer, QStack } from '../../../QLayouts';
import { QIconButton, QSelect, QSelectItem, QText } from '../../../QAtoms';
import {
  PageSize,
  PaginationContextValue,
  usePaginationMaybe,
} from '../Pagination';

const PAGE_SIZE_OPTIONS = [
  {
    label: '15',
    value: 15,
  },
  {
    label: '30',
    value: 30,
  },
  {
    label: '50',
    value: 50,
  },
  {
    label: '100',
    value: 100,
  },
] satisfies QSelectItem<string, PageSize>[];

export const Pagination: React.VFC = () => {
  const pagination = usePaginationMaybe();
  if (!pagination) {
    return null;
  }

  const {
    pageSize,
    pageIndex,
    pageCount,
    itemCount,
    setPageIndex,
    setPageSize,
  } = pagination;

  const hasMore = useMemo(() => {
    const total = itemCount ?? 0;
    if (total < pageSize) {
      return false;
    }

    return (pageIndex + 1) * pageSize < total;
  }, [pageSize, pageIndex, itemCount]);

  return (
    <QFlex direction="row" align="center" height="52px">
      {pageCount || (itemCount ?? Infinity) < pageSize ? (
        <ExactItemCount itemCount={itemCount} />
      ) : (
        <ApproximateItemCount itemCount={itemCount} />
      )}
      <QSpacer />
      <QStack direction="row" align="center" spacing="8px">
        <QText fontSize="sm">Show</QText>
        <QBox w="77px">
          <QSelect
            isSearchable={false}
            options={PAGE_SIZE_OPTIONS}
            value={pageSize}
            onChange={(opt) => opt && setPageSize(opt?.value)}
          />
        </QBox>
        <QIconButton
          aria-label="previous page"
          iconName="ChevronLeft"
          onClick={() => setPageIndex(pageIndex - 1)}
          isDisabled={pageIndex === 0}
        />
        <QText fontSize="sm">Page {Math.trunc(pageIndex + 1)}</QText>
        <QIconButton
          aria-label="next page"
          iconName="ChevronRight"
          onClick={() => setPageIndex(pageIndex + 1)}
          isDisabled={!hasMore}
        />
      </QStack>
    </QFlex>
  );
};

type ItemCountProps = Pick<PaginationContextValue, 'itemCount'>;

const FormatNumber = new Intl.NumberFormat();

/**
 * Displays the given number as a label without modifying the value.
 */
const ExactItemCount: React.VFC<ItemCountProps> = ({ itemCount }) => (
  <QText fontSize="sm" fontWeight={600}>
    {FormatNumber.format(itemCount ?? 0)} {itemCount === 1 ? 'item' : 'items'}
  </QText>
);

/**
 * Displays the given number as a label with a plus "+" appended to it.
 * Rounds the number to the nearest 10 or 100 depending on the size of the number.
 */
const ApproximateItemCount: React.VFC<ItemCountProps> = ({ itemCount }) =>
  itemCount ? (
    <QText fontSize="sm" fontWeight={600}>
      {FormatNumber.format(
        Math.floor(itemCount / (itemCount > 200 ? 100 : 10)) * 10,
      )}
      + items
    </QText>
  ) : null;
