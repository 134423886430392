import React from 'react';

import { QText } from '../../QAtoms';
import { QModal, QModalBody, QModalHeader } from '../../QComponents';
import { DataProps } from '../../utils';

export type QConfirmationModalProps = {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  message: React.ReactElement;
  children: React.ReactElement;
} & DataProps;
export const QConfirmationModal: React.FC<QConfirmationModalProps> = ({
  isOpen,
  onClose,
  title,
  message,
  children,
  ...rest
}) => {
  return (
    <QModal isOpen={isOpen} onClose={onClose} {...rest}>
      <QModalHeader>
        <QText>{title}</QText>
      </QModalHeader>
      <QModalBody>{message}</QModalBody>
      {children}
    </QModal>
  );
};
