export const Switch = {
  baseStyle: {
    track: {
      bg: 'gray.400',
      _focus: {
        boxShadow: 'none',
        outline: 'none',
      },
    },
  },
};
