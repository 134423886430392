import React from 'react';

export const Check: React.FC = (props) => {
  return (
    <svg
      {...props}
      width={16}
      height={16}
      viewBox="0 0 16 16"
      // fill="none"
      xmlns="http://www.w3.org/2000/svg"
      fill="#0069FF"
      stroke="#0069FF"
    >
      <path
        d="M6.34999 11.7713L3.04999 8.4713L3.99265 7.52864L6.35099 9.8843L6.34999 9.8853L12.0067 4.22864L12.9493 5.1713L6.34999 11.7713Z"
        strokeWidth={0.3}
      />
    </svg>
  );
};

export default Check;
