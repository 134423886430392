import React, { useCallback, useMemo } from 'react';
import { PaginationContextValue, PageSize } from './types';
import { PaginationProvider } from './context';
import { usePaginationSearchParams } from './searchParams';
import { useControllableState } from '@chakra-ui/react';

export type LimitOffsetPaginationProviderProps = {
  limit: PageSize;
  offset: number;
  setLimit: (limit: PageSize) => void;
  setOffset: (offset: number) => void;
} & Pick<PaginationContextValue, 'itemCount' | 'pageCount' | 'setItemCount'>;

export const LimitOffsetPaginationProvider: React.FC<
  LimitOffsetPaginationProviderProps
> = ({
  limit,
  offset,
  setLimit,
  setOffset,
  itemCount: itemCountContext,
  pageCount: pageCountContext,
  children,
}) => {
  const setPageSize = useCallback(
    (size: PageSize) => {
      setLimit(size);
      setOffset(0);
    },
    [setLimit, setOffset],
  );
  const setPageIndex = useCallback(
    (index: number) => {
      setOffset(index * limit);
    },
    [limit, setOffset],
  );

  const [itemCount, setItemCount] = useControllableState({
    defaultValue: itemCountContext,
  });
  const [pageCount, setPageCount] = useControllableState({
    defaultValue: pageCountContext,
  });

  const info: PaginationContextValue = useMemo(
    () => ({
      pageSize: limit,
      setPageSize,
      pageIndex: offset / limit,
      setPageIndex,
      itemCount,
      pageCount,
      setItemCount,
      setPageCount,
    }),
    [
      offset,
      limit,
      pageCount,
      itemCount,
      setPageSize,
      setPageIndex,
      setItemCount,
      setPageCount,
    ],
  );

  return <PaginationProvider value={info}>{children}</PaginationProvider>;
};

export const LimitOffset = LimitOffsetPaginationProvider;

type BoundLimitOffsetProps = Omit<
  LimitOffsetPaginationProviderProps,
  'limit' | 'offset' | 'setLimit' | 'setOffset'
>;

export type UseLimitOffsetResult = {
  limit: PageSize;
  offset: number;
  Provider: React.FC<BoundLimitOffsetProps>;
};

export const LimitOffsetProvider: React.FC<BoundLimitOffsetProps> = ({
  children,
  ...props
}) => {
  const {
    pageSize: limit,
    pageIndex: offset,
    setPageSize: setLimit,
    setPageIndex: setOffset,
  } = usePaginationSearchParams('limit', 'offset');
  return (
    <LimitOffset
      {...props}
      limit={limit}
      offset={offset}
      setLimit={setLimit}
      setOffset={setOffset}
    >
      {children}
    </LimitOffset>
  );
};

/**
 * Simplifies using the Limit/Offset pagination provider by wrapping the
 * limit and offset state and integrating with the URL search params.
 */
export const useLimitOffset = (): UseLimitOffsetResult => {
  const { pageSize: limit, pageIndex: offset } = usePaginationSearchParams(
    'limit',
    'offset',
  );

  return {
    limit,
    offset,
    Provider: LimitOffsetProvider,
  };
};
