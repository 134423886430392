import { IconButton } from '@chakra-ui/react';
import { QSelect } from '../../Select';
import { QText } from '../../Text/Text';
import { QIcon } from '../../../QIcons';
import { QBox, QStack } from '../../../QLayouts';
import React from 'react';

export interface QPaginatorProps {
  canNextPage: boolean;
  canPreviousPage: boolean;
  nextPage: () => void;
  previousPage: () => void;
  hasPageSizeOptions: boolean;
  setPageSize: (pageSize: number) => void;
  pageIndex: number;
  pageOptions: readonly number[];
  pageSize?: number;
}

const pageSizeOptions = ['10', '25', '50', '100'].map((x) => ({
  label: x,
  value: x,
}));

export const QPaginator: React.FC<QPaginatorProps> = ({
  canNextPage,
  canPreviousPage,
  nextPage,
  previousPage,
  hasPageSizeOptions,
  setPageSize,
  pageIndex,
  pageOptions,
  pageSize,
}) => {
  const defaultValue = pageSizeOptions.find(
    (option) => option.value === String(pageSize),
  ) || [pageSizeOptions[0]];

  return (
    <QBox display="flex" justifyContent="end" alignItems="center">
      <QStack spacing={2} direction="row">
        {hasPageSizeOptions ? (
          <>
            <QBox display="flex" alignItems="center">
              <QText fontSize="sm">Show </QText>
            </QBox>
            <QBox w="80px">
              <QSelect
                options={pageSizeOptions}
                isSearchable={false}
                onChange={(e) => {
                  setPageSize(Number(e?.value));
                }}
                defaultValue={defaultValue}
                aria-label="page size dropdown"
              />
            </QBox>
          </>
        ) : null}
        <IconButton
          onClick={previousPage}
          isDisabled={!canPreviousPage}
          aria-label="previous page"
          variant="ghost"
          icon={<QIcon iconName="ChevronLeft" />}
        />
        <QBox display="flex" alignItems="center">
          <QText fontSize="sm" display="inline" weight="semibold">
            {pageIndex + 1}
          </QText>
          <QText fontSize="sm" display="inline" whiteSpace="pre">
            {` of ${pageOptions.length}`}
          </QText>
        </QBox>
        <IconButton
          onClick={nextPage}
          isDisabled={!canNextPage}
          aria-label="next page"
          variant="ghost"
          icon={<QIcon iconName="ChevronRight" />}
        />
      </QStack>
    </QBox>
  );
};
