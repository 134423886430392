import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertIconProps,
  AlertProps,
  AlertTitle,
  CloseButton,
} from '@chakra-ui/react';
import { QBox, QStack } from '../../QLayouts';
import React from 'react';
import { getIcon } from '../..';
import { IconNameType } from '../../types/Icons';
import { QMarginProps } from '../..';

type AlertStatus = 'info' | 'warning' | 'success' | 'error';

export interface QAlertProps extends QMarginProps {
  title?: string;
  description: string | React.ReactNode;
  status: AlertStatus;
  onAlertDismissed?: () => void;
  isInline?: boolean;
}

export interface QAlertIconProps
  extends AlertIconProps,
    Pick<QAlertProps, 'status'> {}

export const iconMapping = {
  info: 'ExclamationCircleFill',
  success: 'CheckCircleFilled',
  error: 'FillXCircleFill',
  warning: 'FillExclamationTriangleFill',
} satisfies Record<AlertStatus, IconNameType>;

const backgroundColorMapping = {
  info: 'blue.100',
  success: 'green.100',
  error: 'red.100',
  warning: 'yellow.100',
} as const;

const colorMapping = {
  info: 'blue.900',
  success: 'green.900',
  error: 'red.900',
  warning: 'yellow.900',
} as const;

const iconColorMapping = {
  info: 'blue.500',
  success: 'green.500',
  error: 'red.500',
  warning: 'yellow.500',
} as const;

export const QAlert: React.VFC<QAlertProps> = ({
  title,
  description,
  status = 'info',
  onAlertDismissed,
  isInline,
  ...rest
}) => (
  <Alert
    status={status}
    bg={backgroundColorMapping[status]}
    borderRadius="4px"
    py={3}
    px={4}
    alignItems="flex-start"
    {...rest}
  >
    <AlertIcon
      as={getIcon(iconMapping[status])}
      w={4}
      h={4}
      marginTop={1}
      color={iconColorMapping[status]}
    />
    {isInline ? (
      <QStack direction="row" color={colorMapping[status]}>
        <QAlertTitle>{title}</QAlertTitle>
        <QAlertDescription>{description}</QAlertDescription>
      </QStack>
    ) : (
      <QBox flex="1" color={colorMapping[status]}>
        <QAlertTitle>{title}</QAlertTitle>
        <QAlertDescription>{description}</QAlertDescription>
      </QBox>
    )}

    {onAlertDismissed ? (
      <CloseButton
        size="sm"
        marginLeft="auto"
        onClick={onAlertDismissed}
        color={colorMapping[status]}
        alignSelf="start"
      />
    ) : null}
  </Alert>
);
QAlert.displayName = 'QAlert';

export type QAlertBoxProps = Omit<
  QAlertProps,
  'title' | 'description' | 'isInline'
> &
  Pick<AlertProps, 'alignItems'> & {
    iconMarginTop?: AlertIconProps['marginTop'];
  };

export const QAlertBox: React.FC<QAlertBoxProps> = ({
  status = 'info',
  onAlertDismissed,
  children,
  iconMarginTop,
  ...rest
}) => (
  <Alert
    status={status}
    bg={backgroundColorMapping[status]}
    color={colorMapping[status]}
    borderRadius="4px"
    py={3}
    px={4}
    {...rest}
  >
    <AlertIcon
      as={getIcon(iconMapping[status])}
      w={4}
      h={4}
      marginTop={iconMarginTop}
      color={iconColorMapping[status]}
    />
    <QBox flex="1">{children}</QBox>
    {onAlertDismissed ? (
      <CloseButton
        position="absolute"
        right="8px"
        top="8px"
        onClick={onAlertDismissed}
      />
    ) : null}
  </Alert>
);

export const QAlertTitle: React.FC = ({ children }) =>
  children ? (
    <AlertTitle fontWeight="600" fontSize="14px">
      {children}
    </AlertTitle>
  ) : null;

export const QAlertDescription: React.FC = ({ children }) =>
  children ? (
    <AlertDescription display="block" fontWeight="400" fontSize="12px">
      {children}
    </AlertDescription>
  ) : null;
