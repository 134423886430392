import React, { forwardRef } from 'react';
import { Icon } from '@chakra-ui/react';
import { QIconProps } from './Icon';

export const Bell = forwardRef<SVGSVGElement, Partial<QIconProps>>(
  (props, ref) => {
    return (
      <Icon viewBox="0 0 24 24" color="currentColor" {...props} ref={ref}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="none"
        >
          <path
            fill="currentColor"
            fillRule="evenodd"
            d="M12 3a6 6 0 0 0-6 6v5a4 4 0 0 1-.536 2h13.072A4 4 0 0 1 18 14V9a6 6 0 0 0-6-6m10 13a2 2 0 0 1-2-2V9A8 8 0 0 0 4 9v5a2 2 0 0 1-2 2 1 1 0 1 0 0 2h20a1 1 0 1 0 0-2M9.768 20.135a1 1 0 0 1 1.367.363 1 1 0 0 0 1.73 0 1 1 0 0 1 1.73 1.004 3 3 0 0 1-5.19 0 1 1 0 0 1 .363-1.367"
            clipRule="evenodd"
          />
        </svg>
      </Icon>
    );
  },
);

Bell.displayName = 'Bell';

export default Bell;
