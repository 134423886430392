import { useDisclosure, UseDisclosureReturn } from '@chakra-ui/react';
import React, { createContext, useContext, useMemo, useState } from 'react';

export type QPropertiesPanelState = {
  available: boolean;
  setAvailable: (available: boolean) => void;
  disclosure: UseDisclosureReturn;
};

const context = createContext<QPropertiesPanelState | null>(null);

export const QPropertiesPanelStateProvider: React.FC = ({ children }) => {
  const [available, setAvailable] = useState(false);
  const disclosure = useDisclosure();

  const value = useMemo(
    () => ({ available, setAvailable, disclosure }),
    [available, disclosure],
  );
  return <context.Provider value={value}>{children}</context.Provider>;
};

export const useQPropertiesPanelStateContext = (): QPropertiesPanelState => {
  const ctx = useContext(context);
  if (!ctx) {
    throw new Error(
      'usePropertiesPanelContext must be used within a PropertiesPanelStateProvider',
    );
  }
  return ctx;
};
