import { ComponentWithAs, Link, LinkProps } from '@chakra-ui/react';
import React, { useCallback } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { navigateAcrossMFEs } from '../../hooks';

export type QLinkProps = Omit<LinkProps, 'color'> & {
  isCrossMFE?: boolean;
};

export const QLink: ComponentWithAs<'a', QLinkProps> = ({
  isCrossMFE,
  ...props
}) => {
  let navigate: NavigateFunction | null = null;
  try {
    navigate = useNavigate();
  } catch (e) {
    if (!isCrossMFE) {
      console.warn(
        'useNavigate is not available for this QLink. Falling back to default behaviour.',
        e,
      );
    }
  }

  const onClick = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      // If the user is holding the command or control keys,
      // let the browser handle it normally, because the user
      // wants to open this link in a new tab.
      if (!props.href || e.metaKey || e.ctrlKey) {
        return;
      }

      if (isCrossMFE) {
        e.preventDefault();
        navigateAcrossMFEs(props.href);
      } else if (navigate) {
        e.preventDefault();
        navigate(props.href);
      }
    },
    [navigate, isCrossMFE, props.href],
  );

  return (
    <Link
      onClick={props.isExternal ? undefined : onClick}
      {...props}
      color="blue.500"
    />
  );
};
