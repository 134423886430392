import React from 'react';
import * as Chakra from '@chakra-ui/react';
import { QFlex } from '../../../QLayouts';
import { QSpinner } from '../../../QAtoms';

export type QTableSpinnerProps = { colSpan: number };

export const QTableSpinner: React.VFC<QTableSpinnerProps> = ({ colSpan }) => (
  <Chakra.Tr>
    <Chakra.Td colSpan={colSpan}>
      <QFlex justifyContent="center">
        <QSpinner />
      </QFlex>
    </Chakra.Td>
  </Chakra.Tr>
);
