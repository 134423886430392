import React, { useEffect, FunctionComponent } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

import {
  Title,
  Button,
  Input,
  InputProps,
  TitleProps,
  ButtonProps,
} from '../../atoms';
import { FormProvider } from './Form.context';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type FormProps = { onSubmit: (any) => void; schema: any };

interface FormComposition {
  Title: FunctionComponent<TitleProps>;
  Input: FunctionComponent<InputProps>;
  Button: FunctionComponent<ButtonProps>;
}

/**
 * @deprecated
 * Please use equivalent component in QAtoms or QComponents
 */

export const Form: React.FC<FormProps> & FormComposition = ({
  children,
  schema,
  onSubmit,
}) => {
  const {
    handleSubmit,
    register,
    setValue,
    formState: { touchedFields, errors, dirtyFields },
  } = useForm({
    resolver: zodResolver(schema),
    mode: 'onTouched',
  });

  useEffect(() => {
    React.Children.forEach(children, (child: React.ReactNode) => {
      if (React.isValidElement(child) && child.type === Input) {
        if (child.props.name && child.props.initialValue) {
          const { name, initialValue } = child.props;
          setValue(name, initialValue);
        }
      }
    });
  }, []);

  const internalSubmit = (e) => {
    onSubmit(e);
  };

  return (
    <FormProvider
      value={{ register, touched: touchedFields, errors, dirty: dirtyFields }}
    >
      <form onSubmit={handleSubmit(internalSubmit)}>{children}</form>
    </FormProvider>
  );
};

Form.Button = Button;
Form.Input = Input;
Form.Title = Title;
