import React from 'react';

import { StyledTag } from './Tag.styles';
import { Text } from '..';
import { TypeOptions as TextTypeOptions } from '..';

export type TagTypeOptions = 'general' | 'info' | 'success' | 'error';

export type TagProps = {
  type?: TagTypeOptions;
  children: React.ReactNode;
};

const Tag: React.ForwardRefRenderFunction<HTMLDivElement, TagProps> = (
  props,
  ref,
) => {
  const { type = 'general', children } = props;
  const textType: { [key in TagTypeOptions]: TextTypeOptions } = {
    general: 'default',
    info: 'dark',
    success: 'success',
    error: 'error',
  };

  return (
    <StyledTag ref={ref} type={type}>
      <Text size="sm" type={textType[type]}>
        {children}
      </Text>
    </StyledTag>
  );
};

/**
 * @deprecated
 * Please use equivalent component in QAtoms or QComponents
 */

export default React.forwardRef<HTMLDivElement, TagProps>(Tag);
