import styled from 'styled-components';
export * from '@ant-design/icons';

import {
  CaretRight,
  CaretLeft,
  CaretUp,
  CaretDown,
  ArrowRight,
  ArrowLeft,
  ArrowUp,
  ArrowDown,
  Check,
  Close,
  Documents,
} from '../../icons/16px';

export const StyledCaretRight = styled(CaretRight)``;
export const StyledCaretLeft = styled(CaretLeft)``;
export const StyledCaretUp = styled(CaretUp)``;
export const StyledCaretDown = styled(CaretDown)``;

export const StyledArrowRight = styled(ArrowRight)``;
export const StyledArrowLeft = styled(ArrowLeft)``;
export const StyledArrowUp = styled(ArrowUp)``;
export const StyledArrowDown = styled(ArrowDown)``;

export const StyledCheck = styled(Check)``;
export const StyledClose = styled(Close)``;
export const StyledDocuments = styled(Documents)``;
