import React, { forwardRef } from 'react';
import { Icon } from '@chakra-ui/react';
import { QIconProps } from './Icon';

export const UserCircle = forwardRef<SVGSVGElement, Partial<QIconProps>>(
  (props, ref) => {
    return (
      <Icon viewBox="0 0 24 24" color="currentColor" {...props} ref={ref}>
        <svg
          fill="none"
          height="24"
          viewBox="0 0 20 20"
          width="24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            height="18.5"
            rx="9.25"
            stroke="currentColor"
            strokeWidth="1.5"
            width="18.5"
            x=".75"
            y=".75"
          />
          <path
            d="M10 6a1.5 1.5 0 100 3 1.5 1.5 0 000-3zM7.5 7.5a2.5 2.5 0 115 0 2.5 2.5 0 01-5 0zm-1.268 4.232A2.5 2.5 0 018 11h4a2.5 2.5 0 012.5 2.5v1a.5.5 0 01-1 0v-1A1.5 1.5 0 0012 12H8a1.5 1.5 0 00-1.5 1.5v1a.5.5 0 01-1 0v-1a2.5 2.5 0 01.732-1.768z"
            fill="currentColor"
            color="currentColor"
          />
        </svg>
      </Icon>
    );
  },
);

UserCircle.displayName = 'UserCircle';
