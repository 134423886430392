import React, { useMemo } from 'react';
import { RowData } from '@tanstack/react-table';

import { QBox } from '../../../QLayouts';
import { QMenuButton } from '../../../QAtoms';
import { CellEventGuard } from './CellEventGuard';
import { CellProps } from './types';
import { getDataCy, getMetaField } from './meta';
import { TableMenuContext, TableMenuContextProvider } from '../Menu/context';

export function QMenuCell<TData extends RowData>({
  row,
  column,
}: CellProps<TData, unknown>): React.ReactElement {
  const { items, hide } = getMetaField(column, 'menu');
  const context: TableMenuContext<TData> = useMemo(
    () => ({
      datum: row.original,
    }),
    [row],
  );

  const dataCy = getDataCy(column, row.original);

  if (hide?.(row.original)) {
    return <QBox data-cy={dataCy ? `${dataCy}-hidden` : 'menu-cell-hidden'} />;
  }

  return (
    <CellEventGuard>
      <TableMenuContextProvider value={context}>
        <QMenuButton
          data-cy={dataCy || 'menu-cell'}
          variant="icon"
          buttonLabel="options"
        >
          {items}
        </QMenuButton>
      </TableMenuContextProvider>
    </CellEventGuard>
  );
}
