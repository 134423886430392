import React from 'react';

export const ArrowLeft: React.FC = (props) => {
  return (
    <svg
      {...props}
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.21999 7.33333L7.60666 4.94L6.66666 4L2.66666 8L6.66666 12L7.60666 11.06L5.21999 8.66667H13.3333V7.33333H5.21999Z"
        fill="#0069FF"
      />
    </svg>
  );
};

export default ArrowLeft;
