import React from 'react';
import { flexRender, Row, RowData } from '@tanstack/react-table';
import * as Chakra from '@chakra-ui/react';
import { QTableRow } from './Row';
import { COMMON_CELL_PROPS } from './common';
import { CellEventGuardProvider } from '../Cells/CellEventGuard';

export type QTableBodyProps<T extends RowData> = {
  rows: readonly Row<T>[];
  enableSelecting?: boolean;
  children: React.ReactNode;
};

export const QTableBody = <T extends RowData>({
  rows,
  enableSelecting,
  children,
}: QTableBodyProps<T>): React.ReactElement => {
  return (
    <Chakra.Tbody verticalAlign="baseline" whiteSpace="normal">
      <CellEventGuardProvider>
        {rows.map((row) => (
          <QTableRow
            key={row.id}
            toggleSelected={
              enableSelecting ? row.getToggleSelectedHandler() : undefined
            }
            isSelected={row.getIsSelected()}
            data-cy={`row-${row.id}`}
          >
            {row.getVisibleCells().map((cell) => (
              <Chakra.Td {...COMMON_CELL_PROPS} key={cell.id}>
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </Chakra.Td>
            ))}
          </QTableRow>
        ))}
        {children}
      </CellEventGuardProvider>
    </Chakra.Tbody>
  );
};
