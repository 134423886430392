import React from 'react';

const AnalyticsContext =
  React.createContext<SegmentAnalytics.AnalyticsJS | undefined>(undefined);

export const useAnalytics = (): SegmentAnalytics.AnalyticsJS => {
  const analytics = React.useContext(AnalyticsContext);

  if (!analytics) {
    throw new Error('useAnalytics can only be used inside AnalyticsProvider');
  }

  return analytics;
};

export const AnalyticsProvider: React.FC<{
  analytics?: SegmentAnalytics.AnalyticsJS;
}> = ({ analytics, children }) => (
  <AnalyticsContext.Provider value={analytics}>
    {children}
  </AnalyticsContext.Provider>
);
