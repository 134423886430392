import React from 'react';
import { QBox, QFlex, QSpacer, QStack } from '../../QLayouts';
import { QDivider } from '../../QAtoms';
import { usePartitionedChildren } from '../../utils/partitionChildren';

export type QHeaderProps = {
  showDivider?: boolean;
}

export const QHeader: React.FC<QHeaderProps> = ({ children, showDivider = true }) => {
  const {
    QTabs,
    QTitle,
    QSubtitle,
    QButtonGroup,
    QHorizontalMetadata,
    QAlert,
    QApprovalRequestAlert,
  } = usePartitionedChildren(
    children,
    'QTabs',
    'QTitle',
    'QSubtitle',
    'QButtonGroup',
    'QHorizontalMetadata',
    'QAlert',
    'QApprovalRequestAlert',
  );

  return (
    <QStack direction="column" alignSelf="stretch" flexShrink={1} spacing="8px">
      <TopRow>{QTabs}</TopRow>
      {QButtonGroup ? (
        <QBox>
          <MiddleRow>
            {QTitle}
            <QSpacer />
            <QBox paddingTop="4px">{QButtonGroup}</QBox>
          </MiddleRow>
          {QSubtitle}
        </QBox>
      ) : (
        <MiddleRow>
          {QTitle}
          {QSubtitle}
        </MiddleRow>
      )}
      {QHorizontalMetadata}
      <AlertRow>
        {QAlert}
        {QApprovalRequestAlert}
      </AlertRow>
      {showDivider && <QDivider paddingTop="24px" />}
    </QStack>
  );
};

QHeader.displayName = "QHeader";

const TopRow: React.FC = ({ children }) =>
  React.Children.count(children) > 0 ? (
    <QBox paddingBottom="24px">{children}</QBox>
  ) : null;

const MiddleRow: React.FC = ({ children }) =>
  React.Children.count(children) > 0 ? (
    <QFlex direction="row">{children}</QFlex>
  ) : null;

const AlertRow: React.FC = ({ children }) =>
  React.Children.count(children) > 0 ? (
    <QStack spacing="8px" paddingTop="24px">
      {children}
    </QStack>
  ) : null;
