import styled, { css } from 'styled-components';
import { space } from 'styled-system';
import { themeGet } from '@styled-system/theme-get';
import { TitleStyleProps } from './Title';

const titleBase = css`
  color: ${themeGet('colors.gray.100')};
  font-family: ${themeGet('fonts.heading')};
`;

export const StyledH1 = styled.h1<TitleStyleProps>`
  font-size: ${themeGet('fontSizes.heading.5')};
  line-height: ${themeGet('lineHeights.heading.5')};

  ${titleBase}
  ${space}
`;

export const StyledH2 = styled.h2<TitleStyleProps>`
  font-size: ${themeGet('fontSizes.heading.4')};
  line-height: ${themeGet('lineHeights.heading.4')};

  ${titleBase}
  ${space}
`;

export const StyledH3 = styled.h3<TitleStyleProps>`
  font-size: ${themeGet('fontSizes.heading.3')};
  line-height: ${themeGet('lineHeights.heading.3')};

  ${titleBase}
  ${space}
`;

export const StyledH4 = styled.h4<TitleStyleProps>`
  font-size: ${themeGet('fontSizes.heading.2')};
  line-height: ${themeGet('lineHeights.heading.2')};

  ${titleBase}
  ${space}
`;

export const StyledH5 = styled.h5<TitleStyleProps>`
  font-size: ${themeGet('fontSizes.heading.1')};
  line-height: ${themeGet('lineHeights.heading.1')};

  ${titleBase}
  ${space}
`;
export const StyledH6 = styled.h6<TitleStyleProps>`
  font-size: ${themeGet('fontSizes.heading.0')};
  line-height: ${themeGet('lineHeights.heading.0')};

  ${titleBase}
  ${space}
`;
