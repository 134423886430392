import { format, isThisYear } from 'date-fns';

export function formatDate(
  date: Date | string | number,
  avoidYear?: boolean,
): string {
  return doFormat(date, 'MMM dd, yyyy', avoidYear ? 'MMM dd' : undefined);
}

export function formatDateTime(
  date: string | Date | number,
  avoidYear?: boolean,
): string {
  return doFormat(
    date,
    'MMM dd, yyyy HH:mm',
    avoidYear ? 'MMM dd, HH:mm' : undefined,
  );
}

function doFormat(
  date: Date | string | number,
  dateFormat: string,
  dateFormatNoYear?: string,
): string {
  if (!date) {
    return '';
  }
  try {
    const dateObject = date instanceof Date ? date : new Date(date);
    if (dateFormatNoYear && isThisYear(dateObject)) {
      return format(dateObject, dateFormatNoYear);
    }
    return format(dateObject, dateFormat);
  } catch (err) {
    console.error(err);
    return date.toString();
  }
}
