import React from 'react';
import { CloseButton, CloseButtonProps } from '@chakra-ui/react';
import { HTMLChakraProps } from '@chakra-ui/system';

export type QCloseButtonProps = Pick<
  CloseButtonProps,
  'onClick' | 'isDisabled' | 'size'
> &
  HTMLChakraProps<'button'>;

export const QCloseButton: React.FC<QCloseButtonProps> = (props) => {
  return <CloseButton {...props} />;
};

QCloseButton.displayName = 'QCloseButton';
