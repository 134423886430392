import React, { forwardRef } from 'react';
import { Icon } from '@chakra-ui/react';
import { QIconProps } from './Icon';

export const SettingsNotification = forwardRef<
  SVGSVGElement,
  Partial<QIconProps>
>((props, ref) => {
  return (
    <Icon viewBox="0 0 24 24" color="currentColor" {...props} ref={ref}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="none"
      >
        <path
          fill="currentColor"
          d="M22.551 9.432a6.02 6.02 0 0 1-3.9.415A2.65 2.65 0 0 0 20.825 11H21a1 1 0 0 1 0 2h-.094a2.65 2.65 0 0 0-2.423 1.601 2.65 2.65 0 0 0 .532 2.918l.068.069a1 1 0 0 1 0 1.415 1 1 0 0 1-1.416 0l-.06-.06-.008-.008a2.65 2.65 0 0 0-2.918-.532 2.65 2.65 0 0 0-1.601 2.423V21a1 1 0 0 1-2 0v-.113a2.65 2.65 0 0 0-1.705-2.415 2.65 2.65 0 0 0-2.894.543l-.069.068a1 1 0 0 1-1.415 0 1 1 0 0 1 0-1.416l.06-.06.008-.008a2.65 2.65 0 0 0 .532-2.918 2.65 2.65 0 0 0-2.423-1.601H3a1 1 0 0 1 0-2h.113a2.65 2.65 0 0 0 2.414-1.705 2.65 2.65 0 0 0-.542-2.894l-.068-.069a1 1 0 0 1 0-1.415 1 1 0 0 1 1.416 0l.06.06.008.008a2.65 2.65 0 0 0 2.729.607 1 1 0 0 0 .264-.073A2.65 2.65 0 0 0 11 3.174V3a1 1 0 0 1 2 0v.094a2.65 2.65 0 0 0 1.132 2.161 6.02 6.02 0 0 1 .436-3.806A3 3 0 0 0 12 0a3 3 0 0 0-3 3v.167a.65.65 0 0 1-.285.534 1 1 0 0 0-.199.064.65.65 0 0 1-.714-.127l-.054-.055a3 3 0 1 0-4.245 4.244l.055.055a.65.65 0 0 1 .127.714l-.024.059a.65.65 0 0 1-.585.425H3a3 3 0 1 0 0 6h.167a.65.65 0 0 1 .594.394l.004.01a.65.65 0 0 1-.127.714l-.055.055a3 3 0 0 0 3.27 4.895c.365-.151.696-.372.974-.651l.055-.055a.65.65 0 0 1 .714-.127l.059.023a.65.65 0 0 1 .425.586V21a3 3 0 1 0 6 0v-.168a.65.65 0 0 1 .394-.593l.01-.004a.65.65 0 0 1 .714.127l.055.055a3 3 0 0 0 4.895-.973 3 3 0 0 0-.651-3.271l-.055-.055a.65.65 0 0 1-.127-.714l.004-.01a.65.65 0 0 1 .594-.394H21a3 3 0 0 0 1.551-5.568"
        />
        <path
          fill="currentColor"
          d="M12 8a4 4 0 1 0 0 8 4 4 0 0 0 0-8m-2 4a2 2 0 1 1 4 0 2 2 0 0 1-4 0"
        />
        <circle cx="20" cy="4" r="4" fill="#00B277" />
      </svg>
    </Icon>
  );
});

SettingsNotification.displayName = 'SettingsNotification';

export default SettingsNotification;
