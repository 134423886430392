import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
export const StyledEmptyStateHeader = styled.header`
  display: flex;
  max-width: 800px;
  width: 80%;
  align-items: center;
  justify-content: center;
  box-shadow: 0 ${themeGet('sizes.3')} ${themeGet('sizes.7')}
    rgb(12 99 255 / 8%);
  border-radius: ${themeGet('sizes.5')};
  margin: 0 auto;
`;

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 70%;
  align-items: center;
  justify-content: center;
  margin: ${themeGet('sizes.7')};
  text-align: center;

  & p {
    color: ${themeGet('colors.gray.60')};
  }
`;
