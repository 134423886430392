import React from 'react';
import { useFiltering } from './context';
import { FilterDefinitions } from './types';
import { useFilteringForm } from './useForm';
import { FormProvider } from 'react-hook-form';

export type FilterCustomViewProps = {
  children: React.ReactNode;
};

export const FilterCustomView = <T extends FilterDefinitions>({
  children,
}: FilterCustomViewProps): React.ReactElement => {
  const filterContext = useFiltering<T>();
  const methods = useFilteringForm<T>();

  methods.watch(filterContext.setFilters);

  return (
    <FormProvider {...methods}>
      <form>{children}</form>
    </FormProvider>
  );
};
FilterCustomView.DisplayName = 'FilterCustomView';
