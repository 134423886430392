import React, { useEffect, useMemo } from 'react';
import {
  useTable,
  useSortBy,
  Column,
  usePagination,
  SortingRule,
} from 'react-table';
import { Table, Tbody } from '@chakra-ui/react';
import { QRow, QTableHead, QPaginator, QRowProps } from './internal';

type QDataItem = string | number | boolean | Date | React.ReactElement;
export type QDataRow = { [name: string]: QDataItem };

export enum QColumnType {
  DATE = 'DATE',
  DATE_NO_YEAR_IF_CURRENT = 'DATE_NO_YEAR_IF_CURRENT',
  DATE_TIME = 'DATE_TIME',
  DATE_TIME_NO_YEAR_IF_CURRENT = 'DATE_TIME_NO_YEAR_IF_CURRENT',
}

export type QDataColumn = Column<QDataRow> & {
  type?: QColumnType;
};

export type QDefaultSortingColumn = { id: string; desc?: boolean };

export type RowCallback = (row: QDataRow) => void;

export type QDataTableProps = {
  columns: readonly QDataColumn[];
  data: readonly QDataRow[];
  /**
   * Flag to determine if table should pin selected rows to top of table
   */
  shouldPin?: boolean;
  onRowClick?: RowCallback;
  hasPagination?: boolean;
  hasPageSizeOptions?: boolean;
  /**
   * The number of rows to display per page when pagination is enabled.
   */
  pageSize?: number;
  manualPagination?: QManualPagination;
  manualSortBy?: QManualSortBy;
};

export type QManualPagination = {
  paginationCallback: (pageIndex: number, pageSize: number) => void;
  pageCount: number;
  initialPageIndex?: number;
  initialPageSize?: number;
};

export type QManualSortBy = {
  sortByCallback?: (sortParams: SortingRule<QDataRow>[]) => void;
  defaultSortByColumn?: QDefaultSortingColumn[];
};

/**
 * @deprecated Prefer the new QDataTable component whenever possible.
 */
export const QDataTable: React.FC<QDataTableProps> = ({
  columns,
  data,
  onRowClick,
  hasPagination = true,
  hasPageSizeOptions = false,
  pageSize: pageSizeOverride,
  manualPagination,
  manualSortBy,
  shouldPin = false,
}) => {
  const tableOptions = {
    columns,
    data,
    manualPagination: manualPagination !== undefined,
    manualSortBy: manualSortBy?.sortByCallback !== undefined,
    autoResetPage: false,
  };

  if (manualSortBy?.defaultSortByColumn) {
    tableOptions['initialState'] = {
      sortBy: manualSortBy?.defaultSortByColumn,
    };
  }

  if (manualPagination) {
    tableOptions['pageCount'] = manualPagination.pageCount;
    tableOptions['autoResetPage'] = false;
    tableOptions['initialState'] = {
      ...tableOptions['initialState'],
      pageIndex: manualPagination.initialPageIndex ?? 0,
      pageSize: manualPagination.initialPageSize ?? 10,
    };
  }

  if (pageSizeOverride) {
    tableOptions['initialState'] = {
      ...tableOptions['initialState'],
      pageSize: pageSizeOverride,
    };
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    pageOptions,
    page,
    rows,
    prepareRow,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    setPageSize,
    gotoPage,
    state: { pageIndex, pageSize, sortBy },
  } = useTable(tableOptions, useSortBy, usePagination);

  const items = hasPagination ? page : rows;

  useEffect(() => {
    if (manualPagination) {
      manualPagination.paginationCallback(pageIndex, pageSize);
    }
    if (manualSortBy?.sortByCallback) {
      manualSortBy.sortByCallback(sortBy);
    }
  }, [pageIndex, pageSize, sortBy]);

  useMemo(() => {
    if (manualPagination && manualPagination.initialPageIndex !== undefined) {
      gotoPage(manualPagination.initialPageIndex);
    }
  }, [gotoPage, manualPagination]);

  const [pinnedRows, unpinnedRows] = useMemo(() => {
    const rows = items.reduce<
      [React.ReactElement<QRowProps>[], React.ReactElement<QRowProps>[]]
    >(
      (rowsArray, row) => {
        prepareRow(row);
        if (shouldPin && row.original.__isSelected) {
          rowsArray[0].push(
            <QRow key={row.id} row={row} onRowClick={onRowClick} />,
          );
        } else {
          rowsArray[1].push(
            <QRow key={row.id} row={row} onRowClick={onRowClick} />,
          );
        }
        return rowsArray;
      },
      [[], []],
    );
    return rows;
  }, [items]);

  return (
    <>
      <Table {...getTableProps()} mb={4}>
        <QTableHead headerGroups={headerGroups} />
        <Tbody {...getTableBodyProps()}>
          {pinnedRows}
          {unpinnedRows}
        </Tbody>
      </Table>
      {hasPagination ? (
        <QPaginator
          canNextPage={canNextPage}
          canPreviousPage={canPreviousPage}
          nextPage={nextPage}
          previousPage={previousPage}
          hasPageSizeOptions={hasPageSizeOptions}
          setPageSize={setPageSize}
          pageIndex={pageIndex}
          pageOptions={pageOptions}
          pageSize={manualPagination?.initialPageSize ?? pageSizeOverride}
        />
      ) : null}
    </>
  );
};
