import React from 'react';

import { SortingProvider } from './context';
import { useSortingSearchParams } from './searchParams';

export type SortingSearchParamKeyProps = {
  sortByKey?: string;
};

/**
 * A provider that stores the sorting state in the URL search params.
 */
export const DefaultSortingProvider: React.FC<SortingSearchParamKeyProps> = ({
  sortByKey = 'orderBy',
  children,
}) => {
  const value = useSortingSearchParams(sortByKey);
  return <SortingProvider value={value}>{children}</SortingProvider>;
};
