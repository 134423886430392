import React, { forwardRef } from 'react';
import { Icon } from '@chakra-ui/react';
import { QIconProps } from './Icon';
import { QTheme } from '../QTheme';

export const ResourceEvents = forwardRef<SVGSVGElement, Partial<QIconProps>>(
  (props, ref) => {
    return (
      <Icon viewBox="0 0 16 16" color="currentColor" {...props} ref={ref}>
        <svg
          width="16"
          height="16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            width="16"
            height="16"
            rx="2"
            fill={QTheme.colors.orange[100]}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.708 3.938a.52.52 0 1 1 1.042 0v.729h2.5v-.625a.625.625 0 1 1 1.25 0v.625h.834c.46 0 .833.354.833.791v6.334c0 .437-.373.791-.833.791H4.667c-.46 0-.833-.354-.833-.791V5.458c0-.437.373-.791.833-.791h1.042v-.73Zm1.667 2.604a.625.625 0 1 1 1.25 0v2.187a.625.625 0 1 1-1.25 0V6.542Zm1.25 4.062a.625.625 0 1 1-1.25 0 .625.625 0 0 1 1.25 0Z"
            fill={QTheme.colors.orange[900]}
          />
        </svg>
      </Icon>
    );
  },
);

ResourceEvents.displayName = 'ResourceEvents';
