import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { InputProps } from './Input';
import { CheckOutlined, WarningFilled } from '../Icon';

export const StyledLabel = styled.label<Partial<InputProps>>`
  display: flex;
  flex-direction: column;
`;

const FocusStyles = css`
  box-shadow: 0 0 0 ${themeGet('sizes.2')} ${themeGet('colors.primary.40')};
  color: ${themeGet('colors.gray.100')};
  outline: none;
`;
const HoverStyles = css`
  border: ${themeGet('sizes.1')} solid ${themeGet('colors.primary.60')};
  color: ${themeGet('colors.gray.100')};
`;
const ErrorStyles = css`
  border: ${themeGet('sizes.1')} solid ${themeGet('colors.error.border')};
  color: ${themeGet('colors.error.text')};

  &:hover:not(:disabled) {
    color: ${themeGet('colors.error.text')};
    border: ${themeGet('sizes.1')} solid ${themeGet('colors.error.text')};
  }
`;
const SuccessStyles = css`
  border: ${themeGet('sizes.1')} solid ${themeGet('colors.success.border')};
  color: ${themeGet('colors.success.text')};
  &:hover:not(:disabled) {
    color: ${themeGet('colors.success.text')};
    border: ${themeGet('sizes.1')} solid ${themeGet('colors.success.text')};
  }
`;
const DisabledStyles = css`
  background-color: ${themeGet('colors.gray.10')};
  border: ${themeGet('sizes.1')} solid ${themeGet('colors.divider')};
  color: ${themeGet('colors.gray.40')};
  cursor: not-allowed;
`;
const ReadonlyStyles = css`
  color: ${themeGet('colors.gray.100')};
`;
const LargeStyles = css`
  font-size: ${themeGet('fontSizes.body.2')};
  line-height: ${themeGet('lineHeights.body.2')};
  margin: ${themeGet('sizes.3')} ${themeGet('sizes.0')};
  padding: 7px 15px; /* to make the input 40px tall */
`;

const BaseStyles = css`
  width: 100%;
  padding: 5px 11px; /* to make the input 32px tall */
  border-radius: ${themeGet('radii.2')};
  margin: ${themeGet('sizes.4')} ${themeGet('sizes.0')};
  font-size: ${themeGet('fontSizes.body.1')};
  line-height: ${themeGet('lineHeights.body.1')};
  border: ${themeGet('sizes.1')} solid ${themeGet('colors.primary.40')};
  color: ${themeGet('colors.gray.80')};

  &::placeholder {
    color: ${themeGet('colors.gray.60')};
  }

  &:hover:not(:disabled) {
    ${HoverStyles}
  }
  &:focus:not(:disabled),
  &:focus-visible:not(:disabled),
  &:focus-within:not(:disabled) {
    ${FocusStyles}
  }
`;

export const InputIconWrapper = styled.i<Partial<InputProps>>`
  position: absolute;
  right: ${themeGet('sizes.5')};
  height: ${themeGet('fontSizes.body.2')};
  width: ${themeGet('fontSizes.body.2')};
  top: calc(${themeGet('lineHeights.body.1')} + 13px);

  ${(props) =>
    props.error &&
    css`
      color: ${themeGet('colors.error.icon')};
    `}
  ${(props) =>
    props.success &&
    css`
      color: ${themeGet('colors.success.icon')};
    `}
  ${(props) =>
    props.large &&
    css`
      height: 24px;
      width: 24px;
      top: 36px;
    `}

  ${(props) =>
    !props.label &&
    css`
      top: ${themeGet('fontSizes.body.2')};
    `}
`;
export const StyledCheckOutlined = styled(CheckOutlined)<{ large?: boolean }>`
  ${(props) =>
    props.large &&
    css`
      font-size: 24px;
    `}
`;
export const StyledWarningFilled = styled(WarningFilled)<{ large?: boolean }>`
  ${(props) =>
    props.large &&
    css`
      font-size: 24px;
    `}
`;

export const StyledInput = styled.input<Partial<InputProps>>`
  ${BaseStyles}
  ${(props) => props.large && LargeStyles}
  ${(props) => props.error && ErrorStyles}
  ${(props) => props.success && SuccessStyles}
  ${(props) => props.disabled && DisabledStyles}
  ${(props) => props.readonly && ReadonlyStyles}
`;

export const StyledTextarea = styled.textarea<Partial<InputProps>>`
  ${BaseStyles}
  ${(props) => props.error && ErrorStyles}
  ${(props) => props.success && SuccessStyles}
  ${(props) => props.disabled && DisabledStyles}
`;
