import React from 'react';
import { QFlex } from '../../../QLayouts';
import { usePartitionedChildren } from '../../../utils';

export const QSelectPlaceholder: React.FC = ({ children }) => {
  const { QIcon: icon, QText: text } = usePartitionedChildren(
    children,
    'QIcon',
    'QText',
  );

  return text.length ? (
    <QFlex alignItems={'center'}>
      {icon.length ? <QFlex mr={1}>{icon}</QFlex> : null}
      {React.cloneElement(text[0], { color: 'gray.500' } as never)}
    </QFlex>
  ) : null;
};

QSelectPlaceholder.displayName = 'QSelectPlaceholder';
