import React from 'react';

export const ArrowDown: React.FC = (props) => {
  return (
    <svg
      {...props}
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.333 10.78L4.94 8.393l-.94.94 4 4 4-4-.94-.94-2.393 2.387V2.667H7.333v8.113z"
        fill="#0069FF"
      />
    </svg>
  );
};

export default ArrowDown;
