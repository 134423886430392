import { Divider, DividerProps } from '@chakra-ui/react';
import React from 'react';

export type QDividerProps = DividerProps;

export const QDivider: React.FC<QDividerProps> = (props) => {
  return <Divider {...props} />;
};

QDivider.displayName = 'QDivider';
