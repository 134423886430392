import colors from '../colors';

export const Tabs = {
  baseStyle: {
    tabpanel: {
      px: 0,
    },
  },
  variants: {
    horizontal: {
      tab: {
        fontSize: 'sm',
        height: '36px',
        color: 'gray.700',
        position: 'relative',
        marginBottom: '-1px',
        _selected: {
          color: 'gray.900',
          '&::after': {
            content: '""',
            position: 'absolute',
            inset: 'auto 0 0',
            borderBottom: '4px solid',
            borderColor: 'blue.500',
          },
          _hover: {
            '&::after': {
              content: '""',
              position: 'absolute',
              inset: 'auto 0 0',
              borderBottom: '4px solid',
              borderColor: 'blue.500',
            },
          },
        },
        _hover: {
          '&::after': {
            content: '""',
            position: 'absolute',
            inset: 'auto 0 0',
            borderBottom: '1px solid',
            borderColor: 'blue.500',
          },
        },
        _focus: {
          boxShadow: 'none',
          outline: 'none',
        },
      },
      tablist: {
        borderBottom: '1px solid',
        borderColor: 'gray.200',
      },
    },
    vertical: {
      tab: {
        fontSize: 'sm',
        height: '40px',
        width: '256px',
        justifyContent: 'left',
        color: 'gray.700',
        _selected: {
          color: 'gray.900',
          borderLeft: '4px solid',
          borderColor: 'blue.500',
          marginLeft: '-1px',
          paddingLeft: '13px',
          _hover: {
            color: 'gray.900',
            background: 'none',
          },
        },
        _hover: {
          color: 'gray.900',
          background: 'blackAlpha.50',
        },
        _focus: {
          boxShadow: 'none',
          outline: 'none',
        },
      },
    },
    enclosed: {
      tab: {
        fontSize: 'sm',
        height: '36px',
        color: 'gray.700',
        position: 'relative',
        border: '0px transparent',
        _selected: {
          color: 'gray.900',
          outline: `1px solid ${colors.gray[200]}`,
          outlineOffset: '-1px',
          '&::after': {
            content: '""',
            position: 'absolute',
            inset: 'auto 0 0',
            borderBottom: '4px solid',
            borderColor: 'blue.500',
          },
          _hover: {
            '&::after': {
              content: '""',
              position: 'absolute',
              inset: 'auto 0 0',
              borderBottom: '4px solid',
              borderColor: 'blue.500',
            },
          },
        },
        _hover: {
          '&::after': {
            content: '""',
            position: 'absolute',
            inset: 'auto 0 0',
            borderBottom: '1px solid',
            borderColor: 'blue.500',
          },
        },
        _focus: {
          boxShadow: 'none',
          outline: 'none',
        },
      },
      tablist: {
        borderBottom: '1px solid',
        borderColor: 'gray.200',
        marginBottom: '0px',
      },
    },
  },
  defaultProps: {
    variant: 'primary',
  },
};
