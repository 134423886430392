import * as Chakra from '@chakra-ui/react';

export const BORDER_STYLE = {
  borderStyle: 'solid',
  borderY: '1px',
  borderColor: 'gray.200',
} satisfies Chakra.TableProps;

export const COMMON_CELL_PROPS = {
  fontSize: '14px',
  lineHeight: '21px',
  paddingX: '16px',
  paddingY: '13.5px',
} satisfies Chakra.TableCellProps;
