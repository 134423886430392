import React, { useMemo, useState } from 'react';
import { usePartitionedChildren } from '../../utils';
import { QStepVariantOptions } from './components';
import { QStepperContextProvider } from './StepperContext';

export type QStepType = {
  title: string;
  description?: string;
  children: React.ReactNode;
  variant: Exclude<QStepVariantOptions, 'active'>;
};

export type ContextQStepType = {
  index: number;
} & QStepType;

export type QStepperProps = {
  activeStep: number;
  onStepItemClicked: (index: number) => void;
};

export const QStepper: React.FC<QStepperProps> = ({
  activeStep,
  onStepItemClicked,
  children,
}) => {
  const [steps, setSteps] = useState<ContextQStepType[] | []>([]);

  const { QStepperPanelActions, unmatched } = usePartitionedChildren(
    children,
    'QStepperPanelActions',
  );

  useMemo(() => {
    // If a step is taller than the viewport, we want to
    // scroll to the top of the page when the step changes
    // so that new content is visible.
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [activeStep]);

  return (
    <QStepperContextProvider
      value={{
        activeStep,
        steps,
        setSteps,
        onStepItemClicked,
      }}
    >
      {unmatched}
      {QStepperPanelActions}
    </QStepperContextProvider>
  );
};
