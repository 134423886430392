import React from 'react';
import { CurrentUserProvider } from './current-user/current-user';
import { AnalyticsProvider } from './analytics/analytics';
import { CurrentUser } from '../types/CurrentUser';

export const UtilitiesProvider: React.FC<{
  analytics?: SegmentAnalytics.AnalyticsJS;
  user: CurrentUser;
}> = ({ analytics, user, children }) => (
  <CurrentUserProvider user={user}>
    <AnalyticsProvider analytics={analytics}>{children}</AnalyticsProvider>
  </CurrentUserProvider>
);

export * from './analytics/analytics';
