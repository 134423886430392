import React from 'react';

import { QButton } from '../../QAtoms';
import { QConfirmationModal } from '../ConfirmationModal/ConfirmationModal';
import { QModalActions } from '../../QComponents';

export type QDeleteConfirmationModalProps = {
  onConfirm: () => void;
  onClose: () => void;
  title: string;
  message: React.ReactElement;
  inProgress?: boolean;
};
export const QDeleteConfirmationModal: React.VFC<
  QDeleteConfirmationModalProps
> = ({ onConfirm, onClose, title, message, inProgress }) => {
  return (
    <QConfirmationModal
      isOpen
      onClose={onClose}
      title={title}
      message={message}
      data-cy="delete-confirmation-modal"
    >
      <QModalActions>
        <QButton
          variant="outline"
          isDisabled={inProgress}
          onClick={onClose}
          data-cy="cancel-delete-btn"
        >
          Cancel
        </QButton>
        <QButton
          isDestructive
          isLoading={inProgress}
          onClick={onConfirm}
          data-cy="confirm-delete-btn"
        >
          Delete
        </QButton>
      </QModalActions>
    </QConfirmationModal>
  );
};
