import React from 'react';
import { QFlex } from '../../QLayouts';
import { QSpinner } from '../../QAtoms';
import { DataProps } from '../../utils';

export type QPageLoaderProps = DataProps;

export const QPageLoader: React.VFC<QPageLoaderProps> = (props) => (
  <QFlex height="100%" minHeight="60vh" justify="center" align="center">
    <QSpinner {...props} size="lg" />
  </QFlex>
);
