const common = {
  fontSize: 'sm',
  lineHeight: 'sm',
  textTransform: 'none',
  letterSpacing: 0,
  borderBottomColor: 'gray.200',
  px: '8px',
  py: '10px',
  wordBreak: 'break-word'
};

export const Table = {
  parts: ['tbody', 'tr', 'th', 'td'],
  variants: {
    primary: {
      th: {
        fontWeight: 'semibold',
        borderBottom: '2px solid',
        whiteSpace: 'nowrap',
        ...common,
      },
      tbody: {
        tr: {
          _hover: {
            bg: 'gray.50',
          },
        },
        'tr.selected': {
          bg: 'blue.50',
          _hover: {
            bg: 'blue.50',
          },
        },
        td: {
          borderBottom: '1px solid',
          ...common,
        },
      },
    },
  },
  defaultProps: {
    variant: 'primary',
  },
};
