import React from 'react';
import { QBox } from '../../../../QLayouts';
import { QProgress, useQStepperContext } from '../../../..';

export const QStepperProgress: React.FC = () => {
  const { steps, activeStep } = useQStepperContext();
  return (
    <QBox pb={3}>
      <QProgress
        colorScheme="green"
        size="xs"
        value={((activeStep + 1) * 100) / steps.length}
      />
    </QBox>
  );
};

QStepperProgress.displayName = 'QStepperProgress';
