import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { StyledCheck } from '../Icon';

const disabledLabelStyle = css`
  color: ${themeGet('colors.gray.20')};
  cursor: not-allowed;
`;

export const StyledCheckboxTick = styled(StyledCheck)<{ disabled: boolean }>`
  fill: white;
  stroke: white;
  border: 1px solid ${themeGet('colors.gray.20')};
  border-radius: 3px;
  margin: 4px;
  position: absolute;
  top: 0;
  left: 0;
  transition: 0.1s all ease-in;

  ${(props) =>
    props.disabled &&
    css`
      cursor: not-allowed;

      background-color: ${themeGet('colors.gray.20')};
      fill: ${themeGet('colors.gray.20')};
      stroke: ${themeGet('colors.gray.20')};
    `}
`;

export const StyledCheckboxLabel = styled.label<{ disabled: boolean }>`
  color: ${themeGet('colors.gray.100')};
  font-family: ${themeGet('fonts.body')};
  display: inline-flex;
  flex-direction: column;
  font-size: ${themeGet('fontSizes.body.2')};
  line-height: ${themeGet('lineHeights.body.2')};
  margin: 0 ${themeGet('fontSizes.body.2')} 0 0;
  position: relative;
  padding-left: 28px;

  ${(props) => props.disabled && disabledLabelStyle}
`;

export const StyledCheckbox = styled.input.attrs({ type: 'checkbox' })`
  height: 1px;
  width: 1px;
  margin: -1px;
  opacity: 0;

  &:checked + ${StyledCheckboxTick} {
    background-color: ${themeGet('colors.primary.80')};
    border: none;
    ${(props) =>
      props.disabled &&
      css`
        cursor: not-allowed;
        background-color: ${themeGet('colors.primary.60')};
      `}
  }
`;
