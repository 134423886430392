import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

const disabledLabelStyle = css`
  color: ${themeGet('colors.gray.20')};
  cursor: not-allowed;
`;

export const StyledRadioTick = styled.div<{ disabled: boolean }>`
  height: ${themeGet('sizes.4')};
  width: ${themeGet('sizes.4')};
  border: 3px solid white;
  box-shadow: 0 0 0 1px ${themeGet('colors.gray.20')};
  border-radius: 50%;
  margin: ${themeGet('sizes.3')};
  padding: ${themeGet('sizes.3')};
  position: absolute;
  left: 0;
  transition: 0.1s all ease-in;
`;

export const StyledRadioLabel = styled.label<{ disabled: boolean }>`
  color: ${themeGet('colors.gray.100')};
  font-family: ${themeGet('fonts.body')};
  display: inline-flex;
  flex-direction: column;
  font-size: ${themeGet('fontSizes.body.2')};
  line-height: ${themeGet('lineHeights.body.2')};
  margin: 0 ${themeGet('sizes.5')} 0 0;
  position: relative;
  padding-left: 28px;

  ${(props) => props.disabled && disabledLabelStyle}
`;

export const StyledRadio = styled.input.attrs({ type: 'radio' })`
  height: 1px;
  width: 1px;
  margin: -1px;
  opacity: 0;

  &:checked + ${StyledRadioTick} {
    background-color: ${themeGet('colors.primary.80')};
  }

  ${(props) =>
    props.disabled &&
    css`
      cursor: not-allowed;
      background-color: ${themeGet('colors.gray.20')};
      fill: ${themeGet('colors.gray.20')};
      stroke: ${themeGet('colors.gray.20')};
    `}
`;
