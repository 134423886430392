import { RowData, Table } from '@tanstack/react-table';
import * as Chakra from '@chakra-ui/react';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { QColumnWidthDef } from '../Columns';
import { BORDER_STYLE } from './common';

export type QTableContainerProps<T extends RowData> = {
  table: Table<T>;
  tableBorder?: boolean;
  children: React.ReactNode;
};

export const QTableContainer = <T extends RowData>({
  table,
  tableBorder = true,
  children,
}: QTableContainerProps<T>): React.ReactElement => {
  const ref = useRef<HTMLTableElement>(null);
  const scrollShadow = useScrollingShadowStyle(ref);
  const tableLayout = useMemo(
    () => (hasAnyWidthDefinitions(table) ? 'fixed' : 'auto'),
    [table],
  );
  return (
    <Chakra.TableContainer overflowX="auto" ref={ref} boxShadow={scrollShadow}>
      <Chakra.Table
        {...(tableBorder ? BORDER_STYLE : {})}
        variant="unstyled"
        width="full"
        style={{
          tableLayout,
        }}
      >
        {children}
      </Chakra.Table>
    </Chakra.TableContainer>
  );
};

const WIDTH_KEYS = [
  'width',
  'minWidth',
  'maxWidth',
  'weight',
] satisfies (keyof QColumnWidthDef)[];

const hasAnyWidthDefinitions = <T,>(table: Table<T>): boolean =>
  table
    .getHeaderGroups()
    .some((group) =>
      group.headers.some((header) =>
        WIDTH_KEYS.some(
          (key) => header.column.columnDef.meta?.[key] !== undefined,
        ),
      ),
    );

/**
 * Supplies inset shadows to show that the element has hidden content that
 * can be scrolled into view on either side.
 */
const useScrollingShadowStyle = (
  ref: React.RefObject<HTMLElement>,
): Chakra.EffectProps['boxShadow'] => {
  const [shadow, setShadow] = useState('');

  const recalculateShadow = useCallback(() => {
    if (!ref.current) {
      return;
    }
    const { scrollLeft, scrollWidth, clientWidth } = ref.current;
    let newShadow = '';
    if (scrollLeft > 0) {
      // Same as Chakra's `inner` shadow.
      newShadow += 'inset 2px 0 4px 0 rgba(0, 0, 0, 0.06)';
    }
    if (scrollLeft < scrollWidth - clientWidth) {
      if (newShadow) {
        newShadow += ', ';
      }
      newShadow += 'inset -2px 0 4px 0 rgba(0, 0, 0, 0.06)';
    }
    setShadow(newShadow);
  }, [ref]);

  useEffect(() => {
    // Without setting a timeout, the scroll width will
    // be the same as the clientWidth on the first render.
    setTimeout(recalculateShadow, 1);
    ref.current?.addEventListener('scroll', recalculateShadow);
    window.addEventListener('resize', recalculateShadow);
    return () => {
      ref.current?.removeEventListener('scroll', recalculateShadow);
      window.removeEventListener('resize', recalculateShadow);
    };
  }, [recalculateShadow]);

  return shadow;
};
