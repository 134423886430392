import {
  MenuDivider,
  MenuDividerProps,
  MenuGroup,
  MenuGroupProps,
  MenuItem,
  MenuItemProps,
} from '@chakra-ui/react';
import React from 'react';

export type QMenuItemProps = MenuItemProps;

export const QMenuItem: React.FC<QMenuItemProps> = (props) => {
  const { children, ...rest } = props;
  return <MenuItem {...rest}>{children}</MenuItem>;
};

export interface QMenuGroupProps extends MenuGroupProps {
  title: string;
}

export const QMenuGroup: React.FC<QMenuGroupProps> = (props) => {
  const { children, title, ...rest } = props;
  return (
    <MenuGroup {...rest} title={title}>
      {children}
    </MenuGroup>
  );
};

export type QMenuDividerProps = MenuDividerProps;

export const QMenuDivider: React.FC<QMenuDividerProps> = (props) => {
  return <MenuDivider {...props} />;
};
