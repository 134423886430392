import React, { useCallback, useMemo } from 'react';
import { format } from 'date-fns/fp';
import utcToZonedTime from 'date-fns-tz/fp/utcToZonedTime';
import { formatInTimeZone } from 'date-fns-tz';
import { CurrentUser } from '../../types/CurrentUser';
import { parseISO } from 'date-fns';

export type CurrentUserContextType = {
  can: (permission: string) => boolean;
  cannot: (permission: string) => boolean;
  /**
   * Localise a UTC date to the user's selected timezone.
   */
  localiseDate: (utcDate: Date | string) => Date;
  /**
   * Localise and format a UTC date to the user's selected timezone.
   *
   * Formats to standard Qualio date/time formats.
   */
  formatDate: (utcDate: Date | string, includeTime?: boolean) => string;
} & CurrentUser;

const CurrentUserContext = React.createContext<
  CurrentUserContextType | undefined
>(undefined);

type CurrentUserProviderProps = {
  user: CurrentUser;
  children: JSX.Element;
};

export const useCurrentUser = (): CurrentUserContextType => {
  const context = React.useContext(CurrentUserContext);
  if (!context) {
    throw new Error(
      'useCurrentUser can only be used inside CurrentUserProvider',
    );
  }
  return context;
};

const formatDateTime = format("d LLL yyyy 'at' p");
const formatDate = format('d LLL yyyy');

export const CurrentUserProvider: React.FC<CurrentUserProviderProps> = ({
  user,
  children,
}) => {
  const can = useCallback(
    (permission: string) => user.permissions[permission],
    [user.permissions],
  );

  const cannot = useCallback(
    (permission: string) => !can(permission),
    [user.permissions],
  );

  const localiseDate = useCallback(utcToZonedTime(user.tz), [user.tz]);

  const format = useCallback(
    (utcDate: Date | string, includeTime = false) => {
      const date = utcDate instanceof Date ? utcDate : parseISO(utcDate);
      // Always localise when showing the time.
      if (includeTime) {
        return formatDateTime(localiseDate(date));
      }
      // Don't localise if the date is midnight,
      // as it might be displayed as the previous
      // day in the user's timezone.
      if (isUtcMidnight(date)) {
        return formatInTimeZone(date, 'Etc/UTC', 'd LLL yyyy');
      }
      return formatDate(localiseDate(date));
    },
    [localiseDate],
  );

  const value: CurrentUserContextType = useMemo(
    () => ({
      ...user,
      can,
      cannot,
      localiseDate,
      formatDate: format,
    }),
    [user, can, cannot],
  );

  return (
    <CurrentUserContext.Provider value={value}>
      {children}
    </CurrentUserContext.Provider>
  );
};

const isUtcMidnight = (date: Date | string) => {
  const d = new Date(date);
  return (
    d.getUTCHours() === 0 && d.getUTCMinutes() === 0 && d.getUTCSeconds() === 0
  );
};
