import React from 'react';
import { QHeading } from '..';

export const QTitle: React.FC = ({ children }) => (
  <QHeading size="lg" as="h1">
    {children}
  </QHeading>
);

QTitle.displayName = 'QTitle';
