export const fontSizes = {
  body: [12, 14, 16, 18].map((v) => `${v}px`),
  heading: [18, 21, 24, 28, 32, 36].map((v) => `${v}px`),
  root: '10px',
};

export const fontWeights = {
  normal: 400,
  bold: 600,
};

export const lineHeights = {
  body: [18, 20, 24, 28].map((v) => `${v}px`),
  heading: [28, 28, 32, 36, 40, 44].map((v) => `${v}px`),
};

export const fonts = {
  body: '"Open Sans", sans-serif',
  heading: '"Open Sans", sans-serif',
};
