import React from 'react';
import { QBox, QBoxProps } from '../../QLayouts';
import { excludeChildrenOfType, getChildrenOfType } from '../..';

export interface QDrawerHeaderProps extends Pick<QBoxProps, 'alignItems'> {
  children: React.ReactNode;
}

export const QDrawerHeader: React.FC<QDrawerHeaderProps> = ({
  alignItems = 'center',
  children,
}) => {
  return (
    <QBox display="flex" flex-direction="row" alignItems={alignItems}>
      <QBox
        display="flex"
        flex-direction="row"
        justifyContent="space-between"
        alignItems="center"
        flexGrow={1}
        mr={2}
      >
        {excludeChildrenOfType(children, 'QCloseButton')}
      </QBox>
      {getChildrenOfType(children, 'QCloseButton')}
    </QBox>
  );
};

QDrawerHeader.displayName = 'QDrawerHeader';
