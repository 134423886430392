export const Checkbox = {
  parts: ['control'],
  baseStyle: {
    color: 'gray.700',
    control: {
      borderColor: 'blue.200',
      border: '1px solid',
      _focus: {
        outline: 'none',
        boxShadow: 'none',
      },
      _hover: {
        borderColor: 'blue.400',
      },
      _indeterminate: {
        borderColor: 'blue.500',
        bg: 'blue.500',
        _hover: {
          bg: 'blue.700',
          borderColor: 'blue.700',
        },
      },
      _checked: {
        bg: 'blue.500',
        borderColor: 'blue.500',
        _hover: {
          bg: 'blue.700',
          borderColor: 'blue.700',
        },
        _disabled: {
          color: 'gray.400',
        },
      },
      _disabled: {
        bg: 'gray.100',
        borderColor: 'gray.100',
        _hover: {
          borderColor: 'gray.100',
        },
      },
      _readOnly: {
        bg: 'gray.100',
        borderColor: 'gray.100',
        color: 'gray.400',
        _hover: {
          borderColor: 'gray.100',
          bg: 'gray.100',
        },
      },
      _invalid: {
        borderColor: 'red.500',
        _hover: {
          borderColor: 'red.500',
        },
        _checked: {
          bg: 'red.500',
          borderColor: 'red.500',
          _hover: {
            bg: 'red.500',
          },
        },
      },
    },
    label: {
      color: 'gray.700',
      _disabled: {
        opacity: 1,
      },
    },
  },
  sizes: {
    sm: {
      control: {
        height: '16px',
        width: '16px',
      },
    },
  },
};
