import React from 'react';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalProps,
} from '@chakra-ui/react';
import { QButtonProps } from '../../QAtoms';
import { useChakraContainer } from '../../hooks/chakraContainer/chakraContainer';
import { usePartitionedChildren } from '../../utils';

export type QModalButtonProps = QButtonProps & { label?: string };

export type QModalProps = Pick<
  ModalProps,
  'size' | 'isOpen' | 'onClose' | 'trapFocus' | 'autoFocus'
>;

export const QModal: React.FC<QModalProps> = (props) => {
  const {
    size = 'md',
    isOpen,
    onClose,
    children,
    trapFocus,
    autoFocus = true,
    ...rest
  } = props;
  const { chakraContainerRef } = useChakraContainer();
  const { QModalHeader, QModalBody, QModalActions } = usePartitionedChildren(
    children,
    'QModalHeader',
    'QModalBody',
    'QModalActions',
  );

  return (
    <Modal
      size={size}
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={false}
      portalProps={{ containerRef: chakraContainerRef }}
      trapFocus={trapFocus}
      autoFocus={autoFocus}
    >
      <ModalOverlay bg="blackAlpha.400" />
      <ModalContent {...rest}>
        {QModalHeader}
        {QModalBody}
        {QModalActions.length > 0 && <ModalFooter>{QModalActions}</ModalFooter>}
      </ModalContent>
    </Modal>
  );
};
