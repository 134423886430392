import { QBox, QFlex, QStack } from '../../QLayouts';
import React, { ReactElement } from 'react';
import { QPerson, QText, QDotSeparator } from '../../QAtoms';
import { usePartitionedChildren } from '../../utils/partitionChildren';
import { ActivityStatus, QActivityProps } from './Activity';

export type QActivityCardContentProps = QActivityProps & {
  hasSubsequentActivity?: boolean;
};

export type QActivityCardContentChildrenProps = {
  unmatched: ReactElement[];
  hasSubsequentActivity: boolean;
};

const activityLabelMapping = {
  default: 'commented',
  approved: 'approved',
  declined: 'declined',
  reviewed: 'reviewed',
  commented: 'commented',
  edited: 'edited',
  versioned: 'versioned',
  changedStatus: 'changed status',
  markedResolved: 'marked as resolved',
} as const satisfies Record<ActivityStatus, string>;

const getActionLabelText = (
  status: ActivityStatus,
  providedActionLabel?: string,
): string => {
  if (
    providedActionLabel &&
    ['default', 'approved', 'declined', 'commented', 'versioned'].includes(
      status,
    )
  ) {
    return providedActionLabel;
  }
  return activityLabelMapping[status] ?? activityLabelMapping['commented'];
};

const ActivityChildren: React.FC<QActivityCardContentChildrenProps> = ({
  unmatched,
  hasSubsequentActivity,
}) => {
  if (!unmatched.length) {
    return null;
  }
  if (unmatched.length && !hasSubsequentActivity) {
    // render item without vertical line to icon of preceeding activity
    return (
      <QFlex direction="row" mt="8px">
        <QBox width="16px" mr="8px" />
        <QFlex width="546px">{unmatched}</QFlex>
      </QFlex>
    );
  }
  // render item with vertical line to icon of preceeding activity
  return (
    <QFlex
      direction="row"
      borderLeftColor="gray.400"
      ml="7px"
      borderLeftWidth="1px"
    >
      <QFlex direction="row" mt="8px" mb="16px">
        <QBox width="16px" />
        <QFlex width="546px">{unmatched}</QFlex>
      </QFlex>
    </QFlex>
  );
};

export const QActivityCardContent: React.FC<QActivityCardContentProps> = ({
  status,
  fullName,
  actionTimestamp,
  children,
  actionLabel,
  hasSubsequentActivity = false,
}) => {
  const { QTarget, unmatched } = usePartitionedChildren(children, 'QTarget');

  const actionLabelText = getActionLabelText(status, actionLabel);

  return (
    <>
      <QFlex direction="row" alignItems="center" width="560px">
        <QPerson status={status} fullName={fullName} />
        <QBox ml={1}>
          <QStack direction="row" spacing="1">
            <QText fontSize="sm" color="gray.500">
              {actionLabelText}
            </QText>
            {QTarget}
          </QStack>
        </QBox>
        <QBox mx={1}>
          <QDotSeparator />
        </QBox>
        <QText fontSize="sm" color="gray.500">
          {actionTimestamp}
        </QText>
      </QFlex>
      <ActivityChildren
        unmatched={unmatched}
        hasSubsequentActivity={hasSubsequentActivity}
      />
    </>
  );
};

QActivityCardContent.displayName = 'QActivityCardContent';
