import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

// ***********************************
// ************* STEPPER *************
// ***********************************
export const StepperWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const StepperProgressWrapper = styled.div`
  display: flex;
  z-index: 9;
`;
export const StepperProgressContainer = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  margin: 0 ${themeGet('sizes.7')};
  justify-content: space-between;
`;

// ***********************************
// *********** STEPPER BAR ***********
// ***********************************

const StepperBarBase = css`
  position: absolute;
  height: 2px;
  top: 15px;
  background: #c4c4c4;
  transition: width 1s cubic-bezier(0.23, 1, 0.32, 1) 0s;
  left: 64px;
`;

export const StepperProgressTitleWrapper = styled.div`
  width: 128px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`;
export const StepperProgressTitleDot = styled.div<{
  currentStep: number;
  index: number;
}>`
  /* Sizes 5 visible with sizes 3 border on each side */
  height: calc(calc(2 * ${themeGet('sizes.4')}) + ${themeGet('sizes.5')});
  width: calc(calc(2 * ${themeGet('sizes.4')}) + ${themeGet('sizes.5')});
  border: ${themeGet('sizes.4')} solid white;
  border-radius: 100%;
  background-color: ${({ currentStep, index }) =>
    currentStep === index + 1
      ? themeGet('colors.primary.100')
      : themeGet('colors.gray.20')};
`;

export const StepperBar = styled.div`
  ${StepperBarBase}
  right: 64px;
  z-index: -2;
  /* Gives a dashed line */
  background-image: linear-gradient(
    90deg,
    rgb(238, 238, 238) 33.33%,
    white 33.33%,
    white 50%,
    rgb(238, 238, 238) 50%,
    rgb(238, 238, 238) 83.33%,
    white 83.33%,
    white 100%
  );
  background-size: 25px 50px;
`;

export const StepperBarProgress = styled.div<{
  progress: number;
  dist: number;
}>`
  ${StepperBarBase}
  z-index: -1;
  /* This equation gives us responsive across screen sizes and number of points */
  width: ${({ progress, dist }) =>
    `calc(calc(${progress} * calc(100% - 128px)) / ${dist})`};
`;
