import React, { forwardRef } from 'react';
import { Icon } from '@chakra-ui/react';
import { QIconProps } from './Icon';

export const CheckCircleFilled = forwardRef<SVGSVGElement, Partial<QIconProps>>(
  (props, ref) => {
    return (
      <Icon viewBox="0 0 24 24" color="currentColor" {...props} ref={ref}>
        <path
          d="M12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23ZM17.7072 9.70708L10.7075 16.7073C10.52 16.895 10.2656 17.0005 10.0002 17.0006C9.73495 17.0006 9.48049 16.8953 9.29289 16.7077L6.29289 13.7176C5.90237 13.3271 5.90237 12.6939 6.29289 12.3034C6.68342 11.9128 7.31658 11.9128 7.70711 12.3034L9.99965 14.586L16.2922 8.29357C16.6826 7.90285 17.3157 7.90254 17.7065 8.29287C18.0972 8.6832 18.0975 9.31636 17.7072 9.70708Z"
          fill="currentColor"
        />
      </Icon>
    );
  },
);

CheckCircleFilled.displayName = 'CheckCircleFilled';

export default CheckCircleFilled;
