import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  QModal,
  QModalActions,
  QModalBody,
  QModalProps,
} from '../../QComponents';
import { QFlex, QStack } from '../../QLayouts';
import { QButton, QButtonGroup, QInput } from '../../QAtoms';
import { DataProps, usePartitionedChildren } from '../../utils';
import { useDataProvider } from '../Table/DataProviders';
import { DataView } from './View';
import {
  QSelectableRadioList,
  QSelectableRadioListProps,
} from './SelectableRadioList';
import { DatumPredicate, Selectable } from './types';
import {
  ConditionalErrorAlert,
  dropUnselectedAndMergeNew,
  getSelected,
  toMap,
} from './SelectHelpers';

export type SingleSelectProps<T> = Pick<QModalProps, 'isOpen'> &
  Pick<QSelectableRadioListProps<T>, 'defaultSortBy'> &
  DataProps & {
    /**
     * These accessors are a type-agnostic way to uniquely identify items.
     *
     * TODO: Simplfy this to just `id` in a breaking change, do away with
     * the object wrapping.
     */
    accessors: Pick<QSelectableRadioListProps<T>['accessors'], 'id'>;
    /**
     * Invoked when the user wishes to finalise their selection.
     *
     * This component will enter an in-progress state until this promise
     * settles.
     * This component doesn't care whether the promise resolves or rejects.
     *
     * @param selection - An array of the selected items only.
     * The selected items may include items that are no longer
     * available in the data provider.
     */
    onSelect: (selection: readonly T[]) => Promise<unknown>;
    /**
     * Invoked when the user wishes to cancel the selection process.
     */
    onCancel: () => void;
    /**
     * Optionally provide a function to determine if an item should be pre-selected.
     * The function should return only one item,
     * if more or less than one item is returned, then no item will be pre-selected
     */
    isItemPreSelected?: (item: T) => boolean;
    /**
     * Optionally provide a function to determine if an item should be disabled.
     */
    isItemDisabled?: (item: T) => boolean;
    /**
     * Defines which fields to display, as well as how to display them.
     */
    view: DataView<T>;
    /**
     * What action is going to happen when the user confirms their selection?
     * e.g. "Confirm", "Assign", etc
     */
    action?: string;
    searchPlaceholder?: string;
    size?: Extract<QModalProps['size'], '4xl' | '6xl'>;
    children?: React.ReactNode;
  };

export function SingleSelect<T>({
  accessors,
  onSelect,
  onCancel,
  isItemPreSelected,
  isItemDisabled,
  view,
  searchPlaceholder,
  defaultSortBy,
  action = 'Confirm',
  size = '4xl',
  isOpen,
  children,
  ...dataProps
}: Readonly<SingleSelectProps<T>>): React.ReactElement {
  const {
    data,
    onSearchTermChange,
    isLoading,
    isFetchingNextPage,
    error,
    fetchNextPage,
    hasNextPage,
  } = useDataProvider<T>();

  const [selections, setSelections] = useState<readonly Selectable<T>[]>([]);
  const [currentSearchTerm, setCurrentSearchTerm] = useState('');

  const preSelectedData = (data ?? []).filter((d) => isItemPreSelected?.(d));
  const preSelectedItem =
    preSelectedData.length === 1 ? preSelectedData[0] : undefined;

  const canSubmit = useMemo(() => {
    const selectedItem = getSelected(selections)[0];
    // Cannot submit when there is no preselected item initially
    if (!preSelectedItem && !selectedItem) {
      return false;
    }

    // Can submit when you clear a preSelected item
    if (preSelectedItem && !selectedItem) {
      return true;
    }

    // Can submit when you select a new item
    if (!preSelectedItem && selectedItem) {
      return true;
    }
    return (
      preSelectedItem &&
      selectedItem &&
      preSelectedItem[accessors.id] != selectedItem[accessors.id]
    );
  }, [selections]);

  const canClear = useMemo(() => {
    return selections.some((item) => item.__isSelected);
  }, [selections]);

  const [actionIsInProgress, setActionIsInProgress] = useState(false);

  // When new data comes in, drop any unselected items and merge in the new data.
  useEffect(() => {
    setSelections((selections) =>
      dropUnselectedAndMergeNew(
        selections,
        makeSelectable(data, isItemPreSelected, isItemDisabled),
        accessors.id,
      ),
    );
  }, [data]);

  const updateSelections = useCallback(
    (changed: readonly Selectable<T>[]) => {
      // Overwrite the changed items in the current selections.
      const map = toMap(changed, accessors.id);
      setSelections((selections) =>
        selections.map((datum) => map.get(`${datum[accessors.id]}`) ?? datum),
      );
    },
    [accessors.id],
  );

  const finaliseSelection = useCallback(async () => {
    setActionIsInProgress(true);
    // It's the client's responsibility to handle the promise.
    // We will asborb exceptions and log them to the console.
    onSelect(getSelected(selections))
      .then(() => {
        // Clear the selections so that the next time the modal is opened,
        // it doesn't show the previous selections.
        setSelections(
          dropUnselectedAndMergeNew(
            [],
            makeSelectable(data, isItemPreSelected, isItemDisabled),
            accessors.id,
          ),
        );
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setActionIsInProgress(false);
      });
  }, [onSelect, selections]);

  const cancelSelection = useCallback(() => {
    onCancel();
    //clear the filter
    onSearchTermChange('');
    // Clear the selections so that the next time the modal is opened,
    // it doesn't show the previous selections.
    setSelections(
      dropUnselectedAndMergeNew(
        [],
        makeSelectable(data, isItemPreSelected, isItemDisabled),
        accessors.id,
      ),
    );
  }, [onSelect]);

  const handleClearSelection = useCallback(() => {
    setSelections(
      dropUnselectedAndMergeNew(
        [],
        makeSelectable(data, undefined, isItemDisabled),
        accessors.id,
      ),
    );
  }, [onSelect]);

  const { QModalHeader } = usePartitionedChildren(children, 'QModalHeader');

  const dataCy = `${dataProps['data-cy'] ?? 'qlookup'}`;

  return (
    <QModal size={size} isOpen={isOpen} onClose={cancelSelection}>
      {QModalHeader}
      <QModalBody>
        <QStack spacing="28px" {...dataProps}>
          <QInput
            iconLeftName="Search"
            placeholder={searchPlaceholder}
            onChange={(e) => {
              setCurrentSearchTerm(e.target.value);
              onSearchTermChange(e.target.value);
            }}
            isClearable={true}
            data-cy={`${dataCy}-search-input`}
          />
          <ConditionalErrorAlert error={error} />
          <div
            style={{
              maxHeight: '60vh',
              minHeight: '280px',
              overflowY: 'scroll',
            }}
          >
            <QSelectableRadioList<T, Selectable<T>>
              data={selections.filter((item) => !item.__isHidden)}
              onDataChange={updateSelections}
              accessors={{
                ...accessors,
                selected: '__isSelected',
                disabled: '__isDisabled',
              }}
              view={view}
              defaultSortBy={defaultSortBy}
              isDataLoading={isLoading}
              currentSearchTerm={currentSearchTerm}
            />
            {hasNextPage && (
              <QFlex justifyContent="center">
                <QButton
                  variant="ghost"
                  onClick={fetchNextPage}
                  isLoading={isLoading || isFetchingNextPage}
                  data-cy={`${dataCy}-load-more`}
                >
                  Load more
                </QButton>
              </QFlex>
            )}
          </div>
        </QStack>
      </QModalBody>
      <QModalActions>
        <QButtonGroup>
          <QButton
            onClick={handleClearSelection}
            variant="link"
            isDisabled={!canClear}
            data-cy={`${dataCy}-clear`}
          >
            Clear selection
          </QButton>
        </QButtonGroup>
        <QButton
          onClick={cancelSelection}
          variant="outline"
          isDisabled={actionIsInProgress}
          data-cy={`${dataCy}-cancel`}
        >
          Cancel
        </QButton>
        <QButton
          onClick={finaliseSelection}
          isDisabled={!canSubmit}
          isLoading={actionIsInProgress}
          data-cy={`${dataCy}-submit`}
        >
          {action}
        </QButton>
      </QModalActions>
    </QModal>
  );
}

/**
 * Converts the data into selectable items by adding a selected key.
 * @param data - The data to convert. If undefined, an empty array will be used.
 * @param isItemPreselected - A predicate to determine if an item should be pre-selected.
 * If it is not provided, all items will be unselected.
 * @param isItemDisabled - A predicate to determine if an item should be disabled.
 * If it is not provided, all items will be enabled.
 */
const makeSelectable = <T,>(
  data: readonly T[] | undefined,
  isItemPreselected: DatumPredicate<T> | undefined,
  isItemDisabled: DatumPredicate<T> | undefined,
): Selectable<T>[] => {
  const itemsData = (data as readonly Selectable<T>[]) ?? [];
  const preSelectCount = itemsData.filter((d) => isItemPreselected?.(d)).length;
  return itemsData.map((d) => ({
    ...d,
    __isSelected:
      (isItemPreselected?.(d) && preSelectCount === 1) ??
      d.__isSelected ??
      false,
    __isDisabled: isItemDisabled?.(d) ?? d.__isDisabled ?? false,
  }));
};
