import React, { forwardRef } from 'react';

import { HTMLChakraProps, Text, TextProps } from '@chakra-ui/react';

export interface QTextProps
  extends HTMLChakraProps<'p'>,
    Pick<TextProps, 'fontSize' | 'color' | 'noOfLines' | 'casing'> {
  weight?: 'regular' | 'semibold';
}

export const QText = forwardRef<HTMLParagraphElement, QTextProps>(
  (props, ref) => {
    return <Text variant={props.weight} {...props} ref={ref} />;
  },
);

QText.displayName = 'QText';
