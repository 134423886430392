import React from 'react';
import {
  Button,
  ButtonGroup,
  ButtonGroupProps,
  ButtonProps,
} from '@chakra-ui/react';
import { QIcon, IconNameType } from '../..';

export interface QButtonProps
  extends Pick<
    ButtonProps,
    'isLoading' | 'onClick' | 'size' | 'isDisabled' | 'type' | 'width'
  > {
  isDestructive?: boolean;
  variant?: 'solid' | 'outline' | 'ghost' | 'link';
  leftIcon?: IconNameType;
  rightIcon?: IconNameType;
}

export type QButtonGroupProps = Pick<ButtonGroupProps, 'isDisabled' | 'size'>;

export const QButton: React.FC<QButtonProps> = (props) => {
  const {
    isDestructive = false,
    children,
    variant,
    leftIcon,
    rightIcon,
    ...rest
  } = props;
  let combinedVariant = 'solid';
  switch (true) {
    case isDestructive && !!variant:
      combinedVariant = `${variant}-dest`;
      break;
    case !isDestructive && !!variant:
      combinedVariant = `${variant}`;
      break;
    case isDestructive && !variant:
      combinedVariant = `solid-dest`;
      break;
    default:
      combinedVariant = 'solid';
      break;
  }

  return (
    <Button
      {...rest}
      variant={combinedVariant}
      leftIcon={leftIcon ? <QIcon iconName={leftIcon} /> : undefined}
      rightIcon={rightIcon ? <QIcon iconName={rightIcon} /> : undefined}
    >
      {children ? children : 'Button Label'}
    </Button>
  );
};
QButton.displayName = 'QButton';

export const QButtonGroup: React.FC<QButtonGroupProps> = (props) => {
  return <ButtonGroup {...props} />;
};
QButtonGroup.displayName = 'QButtonGroup';

export type QDestructiveButtonProps = Pick<
  QButtonProps,
  'onClick' | 'isLoading' | 'isDisabled'
>;
export const QDestructiveButton: React.FC<QDestructiveButtonProps> = (
  props,
) => <QButton variant="outline" isDestructive {...props} />;
QDestructiveButton.displayName = 'QDestructiveButton';
