import React from 'react';

export const Close: React.FC = (props) => {
  return (
    <svg
      {...props}
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.872 4L8 6.872L5.128 4L4 5.128L6.872 8L4 10.872L5.128 12L8 9.128L10.872 12L12 10.872L9.128 8L12 5.128L10.872 4Z"
        fill="#0C63FF"
      />
    </svg>
  );
};

export default Close;
