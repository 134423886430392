import React from 'react';
import { QualioTheme } from '../../theme';
import { SpaceProps } from 'styled-system';
import {
  StyledH1,
  StyledH2,
  StyledH3,
  StyledH4,
  StyledH5,
  StyledH6,
} from './Title.styles';

type SizeOptions = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
export type TitleStyleProps = SpaceProps<QualioTheme>;

export type TitleProps = {
  size?: SizeOptions;
};

/**
 * @deprecated
 * Please use equivalent component in QAtoms or QComponents
 */

export const Title: React.FC<TitleProps & TitleStyleProps> = ({
  size = 'h1',
  children,
  ...styles
}) => {
  const styledTitle: Record<SizeOptions, React.ReactElement> = {
    h1: <StyledH1 {...styles}>{children}</StyledH1>,
    h2: <StyledH2 {...styles}>{children}</StyledH2>,
    h3: <StyledH3 {...styles}>{children}</StyledH3>,
    h4: <StyledH4 {...styles}>{children}</StyledH4>,
    h5: <StyledH5 {...styles}>{children}</StyledH5>,
    h6: <StyledH6 {...styles}>{children}</StyledH6>,
  };

  return styledTitle[size];
};
