import React, { forwardRef } from 'react';
import { Icon } from '@chakra-ui/react';
import { QIconProps } from './Icon';
import { QTheme } from '../QTheme';

export const ResourceSuppliers = forwardRef<SVGSVGElement, Partial<QIconProps>>(
  (props, ref) => {
    return (
      <Icon viewBox="0 0 16 16" color="currentColor" {...props} ref={ref}>
        <svg fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="16" height="16" rx="2" fill={QTheme.colors.teal[100]} />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3 4.24998C3 4.01986 3.18655 3.83331 3.41667 3.83331H9.66667C9.89679 3.83331 10.0833 4.01986 10.0833 4.24998V5.91665H11.3333C11.4438 5.91665 11.5498 5.96055 11.628 6.03869L12.878 7.28869C12.9561 7.36683 13 7.47281 13 7.58331V9.66665C13 9.89677 12.8135 10.0833 12.5833 10.0833H12.0263C12.1163 10.2727 12.1667 10.4846 12.1667 10.7083C12.1667 11.5137 11.5137 12.1666 10.7083 12.1666C9.90292 12.1666 9.25 11.5137 9.25 10.7083C9.25 10.4846 9.30035 10.2727 9.39034 10.0833H6.60966C6.69965 10.2727 6.75 10.4846 6.75 10.7083C6.75 11.5137 6.09708 12.1666 5.29167 12.1666C4.48625 12.1666 3.83333 11.5137 3.83333 10.7083C3.83333 10.4846 3.88369 10.2727 3.97367 10.0833H3.41667C3.18655 10.0833 3 9.89677 3 9.66665V4.24998ZM9.25 9.24998V4.66665H3.83333V9.24998H9.25ZM10.0833 9.24998V6.74998H11.1607L12.1667 7.7559V9.24998H10.0833ZM5.29167 10.0833C4.94649 10.0833 4.66667 10.3631 4.66667 10.7083C4.66667 11.0535 4.94649 11.3333 5.29167 11.3333C5.63684 11.3333 5.91667 11.0535 5.91667 10.7083C5.91667 10.3631 5.63684 10.0833 5.29167 10.0833ZM10.7083 10.0833C10.3632 10.0833 10.0833 10.3631 10.0833 10.7083C10.0833 11.0535 10.3632 11.3333 10.7083 11.3333C11.0535 11.3333 11.3333 11.0535 11.3333 10.7083C11.3333 10.3631 11.0535 10.0833 10.7083 10.0833Z"
            fill={QTheme.colors.teal[900]}
          />
          <path
            d="M3.83333 4.66665H9.25V9.24998H3.83333V4.66665Z"
            fill={QTheme.colors.teal[900]}
          />
        </svg>
      </Icon>
    );
  },
);

ResourceSuppliers.displayName = 'ResourceSuppliers';
