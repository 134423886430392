import React from 'react';
import { QDotSeparator, QText } from '../index';
import { PersonStatus } from './Person';
import { QBox, QFlex } from '../../QLayouts';

type QPersonActionTypeAndTimestampProps = {
  status: PersonStatus;
  showPendingStatus?: boolean;
  actionTimestamp?: string;
};

const actionTextMapping: Record<PersonStatus, string> = {
  default: '',
  pending: 'Pending',
  approved: 'Approved',
  declined: 'Declined',
  reviewed: 'Reviewed',
  added: 'Added',
  removed: 'Removed',
  commented: 'Commented',
  edited: 'Edited',
  versioned: 'Versioned',
  changedStatus: 'Changed status',
  markedResolved: 'Marked as resolved',
};

export const QPersonActionTypeAndTimestamp: React.FC<
  QPersonActionTypeAndTimestampProps
> = ({ status, showPendingStatus, actionTimestamp }) => {
  if (status === 'pending') {
    return showPendingStatus ? (
      <QText fontSize="sm" color="gray.500">
        {actionTextMapping[status]}
      </QText>
    ) : null;
  }

  if (status === 'default') {
    return actionTimestamp ? (
      <QText fontSize="sm" color="gray.500">
        {actionTimestamp}
      </QText>
    ) : null;
  }

  return actionTimestamp ? (
    <QFlex direction="row" alignItems="center">
      <QText fontSize="sm" color="gray.500">
        {actionTextMapping[status]}
      </QText>
      <QBox mx={1}>
        <QDotSeparator />
      </QBox>
      <QText fontSize="sm" color="gray.500">
        {actionTimestamp}
      </QText>
    </QFlex>
  ) : null;
};
