import React from 'react';

export const CaretDown: React.FC = (props) => {
  return (
    <svg
      {...props}
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.00001 10.4754L12.0067 6.46872L11.0647 5.52539L8.00001 8.59206L4.93601 5.52539L3.99335 6.46806L8.00001 10.4754Z"
        fill="#0069FF"
      />
    </svg>
  );
};

export default CaretDown;
