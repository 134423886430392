import React from 'react';
import {
  Tab,
  TabList,
  TabListProps,
  TabPanel,
  TabPanelProps,
  TabPanels,
  TabPanelsProps,
  TabProps,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router';

export type QTabProps = TabProps;

export const QTab: React.FC<QTabProps> = (props) => <Tab {...props} />;
QTab.displayName = 'QTab';

export type QTabPanelProps = TabPanelProps;

export const QTabPanel: React.FC<QTabPanelProps> = (props) => (
  <TabPanel {...props} />
);

export type QTabListProps = TabListProps;

export const QTabList: React.FC<QTabListProps> = (props) => (
  <TabList {...props} />
);

export type QTabPanelsProps = TabPanelsProps;

export const QTabPanels: React.FC<QTabPanelsProps> = (props) => (
  <TabPanels {...props} />
);

export type QRouteTabProps = Pick<QTabProps, 'children' | 'isDisabled'> & {
  destination: string;
};

export const QRouteTab: React.VFC<QRouteTabProps> = ({
  destination,
  ...rest
}) => {
  const navigate = useNavigate();
  return <QTab onClick={() => navigate(destination)} {...rest} />;
};
QRouteTab.displayName = 'QRouteTab';
