import React from 'react';
import { ThemeProvider, createGlobalStyle } from 'styled-components';
import theme from '.';

const GlobalStyles = createGlobalStyle`
  * {
    box-sizing: border-box;
    font-family: "Open Sans", sans-serif;
    padding: 0;
    margin: 0;
  }
`;

/**
 * @deprecated
 * Please use equivalent component in QAtoms or QComponents
 */
export const QualioThemeProvider: React.FC = ({ children }) => (
  <ThemeProvider theme={theme}>
    <GlobalStyles />
    {children}
  </ThemeProvider>
);
