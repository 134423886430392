import React, { useContext } from 'react';
import {
  UseFormRegister,
  RegisterOptions,
  DeepMap,
  FieldValues,
  FieldError,
} from 'react-hook-form';

export type FormContextProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register: UseFormRegister<any>;
  touched: DeepMap<FieldValues, true>;
  dirty: DeepMap<FieldValues, true>;
  errors: DeepMap<FieldValues, FieldError>;
  options?: RegisterOptions;
};

const FormContext =
  React.createContext<FormContextProps | undefined>(undefined);
export const FormProvider = FormContext.Provider;

export const useInternalForm = (): FormContextProps => {
  const context = useContext(FormContext);
  if (!context) {
    throw new Error('Form elements must be used within a Form component');
  }

  return context;
};
