import React, { useMemo } from 'react';
import { QBox, QCenter } from '../../QLayouts';
import { QHeading, QText } from '../../QAtoms';
import { DataProps, usePartitionedChildren } from '../../utils';

export type QEmptyStateProps = {
  title: string;
  subtitle?: string | readonly string[];
  backgroundImageSrc?: string;
} & DataProps;

export const QEmptyState: React.FC<QEmptyStateProps> = ({
  children,
  title,
  subtitle,
  backgroundImageSrc,
  ...rest
}) => {
  const imageProps = useBackgroundImageStyle(backgroundImageSrc);
  const subtitles = (Array.isArray(subtitle) ? subtitle : [subtitle]).filter(
    (item) => item !== undefined,
  );
  const { QEmptyStateButtonGroup } = usePartitionedChildren(
    children,
    'QEmptyStateButtonGroup',
  );

  return (
    <QCenter w="100%">
      <QBox
        px={6}
        display="flex"
        flexDirection="column"
        textAlign="center"
        {...imageProps}
        {...rest}
      >
        <QHeading size="md" as="h2">
          {title}
        </QHeading>
        {subtitles.length > 0 ? (
          <QBox mt={4}>
            {subtitles.map((subtitle) => (
              <QText fontSize="sm" color="gray.500" key={subtitle}>
                {subtitle}
              </QText>
            ))}
          </QBox>
        ) : null}
        {QEmptyStateButtonGroup ?? null}
      </QBox>
    </QCenter>
  );
};

QEmptyState.displayName = 'QEmptyState';

const useBackgroundImageStyle = (backgroundImageSrc?: string) => {
  return useMemo(
    () =>
      backgroundImageSrc
        ? {
            bgImage: backgroundImageSrc,
            bgSize: 'contain',
            bgRepeat: 'no-repeat',
            width: '100%',
            py: 90,
            bgPosition: 'center',
          }
        : {
            maxWidth: '640px',
            m: 'auto',
            py: 10,
          },
    [backgroundImageSrc],
  );
};
