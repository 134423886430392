import React, { useMemo } from 'react';

type MaybeDisplayName = {
  displayName?: string;
};

type ArrayElement<T> = T extends readonly (infer U)[] ? U : never;

type PartitionKey<T extends readonly string[]> = ArrayElement<T> | 'unmatched';

export type PartitionResult<T extends readonly string[]> = Record<
  PartitionKey<T>,
  React.ReactElement<unknown>[]
>;

/**
 * Given an array of children and a list of display names, returns an object
 * with keys for each display name and an array of children for each key, as
 * well as an "unmatched" key for children that don't match any of the display
 * names.
 */
export const partitionChildren = <T extends readonly string[]>(
  children: React.ReactNode,
  ...displayNames: T
): PartitionResult<T> =>
  React.Children.toArray(children)
    .filter(React.isValidElement)
    .reduce((acc, child) => {
      const name = (child.type as MaybeDisplayName)?.displayName;

      // If the child matches one of the display names, use that as the key.
      // Otherwise, use "unmatched" as the key.
      const key = (
        name && displayNames.includes(name) ? name : 'unmatched'
      ) as PartitionKey<T>;

      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(child);
      return acc;
    }, buildInitialAccumulator(displayNames));

/**
 * Convenience hook for partitioning children and memoizing the result.
 * @returns An object with keys for each display name, as well as an `unmatched` key.
 */
export const usePartitionedChildren = <T extends readonly string[]>(
  children: React.ReactNode,
  ...displayNames: T
): PartitionResult<[...T]> =>
  useMemo(
    () => partitionChildren(children, ...displayNames),
    [children, displayNames],
  );

/**
 * Builds an initial accumulator object with keys for each display name (and "unmatched")
 * and an empty array for each key.
 *
 * This exists to mitigate the risk of accessing an undefined key in the accumulator
 * in the case that there are no children for a given display name.
 */
const buildInitialAccumulator = <T extends readonly string[]>(
  displayNames: T,
): PartitionResult<T> =>
  ([...displayNames, 'unmatched'] as PartitionKey<T>[]).reduce((acc, key) => {
    acc[key] = [];
    return acc;
  }, {} as PartitionResult<T>);
