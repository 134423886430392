import React, { forwardRef } from 'react';
import { Box, BoxProps } from '@chakra-ui/react';
import { DataProps } from '../../utils';

export type QBoxProps = BoxProps & DataProps;

export const QBox = forwardRef<HTMLDivElement, QBoxProps>((props, ref) => (
  <Box {...props} ref={ref} />
));
QBox.displayName = 'QBox';
