import React from 'react';

export const CaretLeft: React.FC = (props) => {
  return (
    <svg
      {...props}
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.52533 7.99995L9.532 12.0066L10.4747 11.064L7.408 7.99729L10.4747 4.93062L9.532 3.99329L5.52533 7.99995Z"
        fill="#0069FF"
      />
    </svg>
  );
};

export default CaretLeft;
