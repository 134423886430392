import {
  OnChangeFn,
  SortingOptions,
  SortingState,
} from '@tanstack/react-table';
import { createContext, useCallback, useContext, useMemo } from 'react';

export type SetSortFn = (column: string, descending: boolean) => void;

export type SortingContextValue = {
  /** ID of the column that is currently sorted */
  column: string | null;
  descending: boolean;
  setSort: SetSortFn;
};

const context = createContext<SortingContextValue | null>(null);

export const { Provider: SortingProvider } = context;

export const useSortingMaybe = (): SortingContextValue | null =>
  useContext(context);

// -------------------
// The code below is specific to @tanstack/react-table and adapts
// the more generic context above to make using it with the table
// easier.
//
// The reason this is separated from the code above is so that
// data providers, etc don't need to know about the table library.

export type TableSortingContextValue = {
  state?: SortingState;
} & SortingOptions<never>;

/**
 * Adapts a sorting context for use as table options.
 */
export const useSortingForTable = (): TableSortingContextValue => {
  const sorting = useSortingMaybe();

  const state = useMemo(
    () =>
      sorting?.column ? [{ id: sorting.column, desc: sorting.descending }] : [],
    [sorting],
  );

  const onSortingChange: OnChangeFn<SortingState> = useCallback(
    (updater) => {
      const newState = typeof updater === 'function' ? updater(state) : updater;
      if (newState.length === 0) {
        sorting?.setSort('', false);
        return;
      }

      if (newState.length > 1) {
        console.warn(
          'Multi-sort is not supported for manual sorting, only the first sort will be used.',
        );
      }
      sorting?.setSort(newState[0].id, newState[0].desc);
    },
    [sorting, state],
  );

  if (!sorting) {
    return {
      manualSorting: false,
      onSortingChange: undefined,
      state: undefined,
    };
  }

  return {
    manualSorting: true,
    onSortingChange,
    state,
  };
};
