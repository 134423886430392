import React, { useCallback, useMemo, useState } from 'react';
import { useCurrentUser } from '../../../../hooks';
import { DataContext } from '../context';
import { useInfiniteQuery } from 'react-query';

import { AxiosInstance } from 'axios';
import { ReferenceSearchResponseSchema } from './types';

const PAGE_SIZE = 15;

export type ReferenceSearchDataProviderProps = {
  client: AxiosInstance;
  qris: readonly string[];
  domains: readonly string[];
  children: React.ReactNode;
};

export const ReferenceSearch = ({
  children,
  client,
  qris,
  domains,
}: ReferenceSearchDataProviderProps): React.ReactElement => {
  const { companyId } = useCurrentUser();
  const [searchTerm, setSearchTerm] = useState('');

  const {
    data,
    isLoading,
    error,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery({
    queryKey: ['ReferenceSearch', companyId, qris, ...domains, PAGE_SIZE],

    queryFn: async ({ pageParam = undefined }) => {
      const response = await client.post('v2/references', {
        companyId,
        qris,
        domains,
        size: PAGE_SIZE,
        searchAfter: pageParam,
      });

      return ReferenceSearchResponseSchema.parse(response.data);
    },
    getNextPageParam: (lastPage) => {
      return lastPage.lastSearchAfterHash;
    },
  });

  const onSearchTermChange = useCallback(
    (term: string) => {
      setSearchTerm(term);
    },
    [searchTerm],
  );

  const referencesExtractedFromResponseData = useMemo(() => {
    return data?.pages.flatMap((page) => page.references).reverse() ?? [];
  }, [data]);

  return (
    <DataContext.Provider
      value={{
        data: referencesExtractedFromResponseData,
        isLoading,
        error,
        hasNextPage: hasNextPage ?? false,
        fetchNextPage,
        isFetchingNextPage,
        onSearchTermChange,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};
