import React, { useRef } from 'react';
import { HeaderGroup, RowData } from '@tanstack/react-table';
import * as Chakra from '@chakra-ui/react';
import { useHeaderWidths } from './hooks/useHeaderWidths';

export type QTableHeaderProps<T extends RowData> = {
  groups: readonly HeaderGroup<T>[];
};

export const QTableHeaderless = <T extends RowData>({
  groups,
}: QTableHeaderProps<T>): React.ReactElement => {
  const ref = useRef<HTMLTableSectionElement>(null);
  const widths = useHeaderWidths(ref, groups);
  return (
    <Chakra.Thead ref={ref}>
      {groups.map((headerGroup) => (
        <Chakra.Tr key={headerGroup.id}>
          {headerGroup.headers.map((header) => (
            <Chakra.Th
              key={header.id}
              colSpan={header.colSpan}
              width={`${widths[header.id]}px`}
              sx={{ padding: 0 }}
            />
          ))}
        </Chakra.Tr>
      ))}
    </Chakra.Thead>
  );
};
