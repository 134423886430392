import { RowData } from '@tanstack/react-table';
import React from 'react';
import { QTooltip } from '../../../QAtoms';
import { getDataCy, getMetaField } from './meta';
import { CellProps } from './types';
import { QIcon } from '../../../QIcons';
import { IconNameType } from 'types/Icons';

export function QIconCell<TData extends RowData>({
  getValue,
  column,
  row: { original },
}: CellProps<TData, IconNameType>): React.ReactElement {
  const { tooltip } = getMetaField(column, 'icon');
  const iconName = getValue();
  const tooltipContent = tooltip?.(original);

  const element = (
    <QIcon iconName={iconName} data-cy={getDataCy(column, original)} />
  );

  return tooltipContent ? (
    <QTooltip label={tooltipContent}>{element}</QTooltip>
  ) : (
    element
  );
}
