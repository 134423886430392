import { Icon } from '@chakra-ui/react';
import React, { forwardRef } from 'react';
import { QIconProps } from './Icon';

export const Events = forwardRef<SVGSVGElement, Partial<QIconProps>>(
  (props, ref) => {
    return (
      <Icon viewBox="0 0 120 120" color="currentColor" {...props} ref={ref}>
        <svg fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M35 9a4 4 0 0 1 4-4h2a4 4 0 0 1 4 4v6h30V9a4 4 0 0 1 4-4h2a4 4 0 0 1 4 4v6h15c5.523 0 10 4.477 10 10v80c0 5.523-4.477 10-10 10H20c-5.523 0-10-4.477-10-10V25c0-5.523 4.477-10 10-10h15V9Zm0 22v-6H20v15h80V25H85v6a4 4 0 0 1-4 4h-2a4 4 0 0 1-4-4v-6H45v6a4 4 0 0 1-4 4h-2a4 4 0 0 1-4-4Zm20 28a4 4 0 0 1 4-4h2a4 4 0 0 1 4 4v19.5a4 4 0 0 1-4 4h-2a4 4 0 0 1-4-4V59Zm-35-9v55h80V50H20Zm40 47.5a5 5 0 1 0 0-10 5 5 0 0 0 0 10Z"
            fill="currentColor"
          />
        </svg>
      </Icon>
    );
  },
);

Events.displayName = 'Events';
