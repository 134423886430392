import { Tr } from '@chakra-ui/react';
import React from 'react';
import { Row } from 'react-table';
import { QDataRow, RowCallback, QDataColumn } from '../DataTable';
import { QCell } from './Cell';

export interface QRowProps {
  row: Row<QDataRow>;
  onRowClick?: RowCallback;
}

export const QRow: React.FC<QRowProps> = ({ row, onRowClick }) => (
  <Tr
    className={row.original.isSelected ? 'selected' : ''}
    _hover={{
      cursor: onRowClick ? 'pointer' : '',
    }}
    {...row.getRowProps()}
    onClick={() => (onRowClick ? onRowClick(row.original) : null)}
    verticalAlign="baseline"
  >
    {row.cells.map((cell) => (
      <QCell
        key={cell.column.id}
        cell={cell}
        column={cell.column as QDataColumn}
      />
    ))}
  </Tr>
);
