import { QFlex } from '../../QLayouts';
import React from 'react';
import { QText, QIcon } from '../..';
import { IconNameType } from '../../types/Icons';
import { QUserIcon } from '../../QIcons/UserIcon';
import { QPersonActionTypeAndTimestamp } from './PersonActionTypeTimestamp';

type NonDefaultPersonStatus =
  | 'pending'
  | 'approved'
  | 'declined'
  | 'reviewed'
  | 'added'
  | 'removed'
  | 'commented'
  | 'edited'
  | 'versioned'
  | 'changedStatus'
  | 'markedResolved';

export type PersonStatus = 'default' | NonDefaultPersonStatus;

export interface QPersonProps {
  status: PersonStatus;
  fullName: string;
  /**
   * Show Pending text below the icon and person full name.
   */
  showPendingStatus?: boolean;
  actionTimestamp?: string;
}

type Colour = `${string}.${number}00`;

export const iconNameMapping: Record<NonDefaultPersonStatus, IconNameType> = {
  pending: 'Clock',
  approved: 'CheckCircleFilled',
  declined: 'FillXCircleFill',
  reviewed: 'Check',
  added: 'PlusSquare',
  removed: 'MinusSquare',
  commented: 'MessageSquare',
  edited: 'Edit3',
  versioned: 'FilePlus',
  changedStatus: 'Repeat',
  markedResolved: 'MessageCheck',
};

const iconColorMapping: Record<NonDefaultPersonStatus, Colour> = {
  pending: 'gray.700',
  approved: 'green.500',
  declined: 'red.500',
  reviewed: 'blue.500',
  added: 'green.500',
  removed: 'red.500',
  commented: 'purple.500',
  edited: 'purple.500',
  versioned: 'blue.500',
  changedStatus: 'blue.500',
  markedResolved: 'green.500',
};

const DEFAULT_TEXT_COLOR = 'gray.700';
const textColorMapping: Partial<Record<NonDefaultPersonStatus, Colour>> = {
  added: 'green.900',
  removed: 'red.900',
};

export const QPerson: React.FC<QPersonProps> = (props) => {
  const {
    status,
    fullName,
    showPendingStatus = false,
    actionTimestamp = undefined,
    ...rest
  } = props;

  const getIcon =
    status === 'default' ? (
      <QUserIcon />
    ) : (
      <QIcon
        iconName={iconNameMapping[status]}
        color={iconColorMapping[status]}
        size="sm"
      />
    );

  const shouldDisplayActionRow = showPendingStatus || actionTimestamp;

  return (
    <QFlex direction="column" {...rest}>
      <QFlex direction="row" alignItems="flex-start">
        <QFlex width="16px" height="21px" alignItems="center" mr="8px">
          {getIcon}
        </QFlex>
        <QText
          fontSize="sm"
          color={textColorMapping[status] ?? DEFAULT_TEXT_COLOR}
          fontWeight="semibold"
        >
          {fullName}
        </QText>
      </QFlex>

      {shouldDisplayActionRow ? (
        <QFlex direction="row">
          <QFlex width={'16px'} mr={'8px'} />
          <QPersonActionTypeAndTimestamp
            status={status}
            actionTimestamp={actionTimestamp}
            showPendingStatus={showPendingStatus}
          />
        </QFlex>
      ) : null}
    </QFlex>
  );
};
