import React, { useCallback } from 'react';
import type { RowData } from '@tanstack/react-table';
import { Link } from '@chakra-ui/react';
import { CellProps } from './types';
import { getDataCy, getMetaField } from './meta';
import { CellEventGuard } from './CellEventGuard';

/**
 * A cell that renders the value as if it were a link,
 * but invokes a callback instead of handling navigation.
 * Requires an `action` meta property on the column definition.
 */
export function QTextActionCell<TData extends RowData, TValue>({
  getValue,
  column,
  row: { original, index },
}: CellProps<TData, TValue>): React.ReactElement {
  const { onClick } = getMetaField(column, 'action');
  const invokeOnClick = useCallback(
    () => onClick(original, index),
    [onClick, original, index],
  );
  return (
    <CellEventGuard>
      <Link
        onClick={invokeOnClick}
        color="blue.500"
        data-cy={getDataCy(column, original)}
      >
        {getValue()}
      </Link>
    </CellEventGuard>
  );
}
