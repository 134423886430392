import React from 'react';
import ReactModal from 'react-modal';
import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

const OVERLAY_CLASSNAME = 'modal-overlay';
const MODAL_CLASSNAME = 'modal';

const Adapter: React.FC<
  Omit<ReactModal.Props, 'className'> & {
    className?: string;
    width: number;
  }
> = ({ className, ...props }) => (
  <ReactModal
    className={MODAL_CLASSNAME}
    overlayClassName={OVERLAY_CLASSNAME}
    portalClassName={className}
    {...props}
  />
);

export const StyledModal = styled(Adapter)`
  .${OVERLAY_CLASSNAME} {
    position: fixed;
    inset: 0;

    overflow: auto;
    background-color: rgba(0, 0, 0, 0.25);
    z-index: 2000;
  }

  .${MODAL_CLASSNAME} {
    display: block;
    padding: 0 ${themeGet('sizes.6')};
    position: relative;
    top: 100px;

    margin: 0 auto;
    width: ${(props) => `${props.width}px`};
    max-width: calc(100vw - ${themeGet('sizes.7')});

    background: ${themeGet('colors.white')};
    border: ${themeGet('sizes.1')} solid ${themeGet('colors.divider')};
  }
`;

export const StyledHeader = styled.header`
  flex-shrink: 0;
  padding: ${themeGet('sizes.5')} 0;
  border-bottom: ${themeGet('sizes.1')} solid ${themeGet('colors.divider')};
`;

export const StyledContent = styled.section`
  padding: ${themeGet('sizes.5')} 0;
`;

export const StyledFooter = styled.footer`
  flex-shrink: 0;
  padding: ${themeGet('sizes.5')} 0;
  border-top: ${themeGet('sizes.1')} solid ${themeGet('colors.divider')};
`;

export const StyledFooterContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  * {
    margin-left: ${themeGet('sizes.5')};
  }
`;
