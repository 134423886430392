import React from 'react';
import { QCenter } from '../index';
import * as icons from 'react-icons/fi';
import { Icon } from '@chakra-ui/react';

export const QUserIcon: React.FC = () => (
  <QCenter
    height="16px"
    width="16px"
    backgroundColor="gray.200"
    borderRadius="50%"
  >
    <Icon w={3} h={3} as={icons['FiUser']} color="gray.700" />
  </QCenter>
);
