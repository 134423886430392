import React from 'react';
import { Dot } from '../../../../QIcons/Dot';
import { SystemStyleObject } from '@chakra-ui/styled-system';
import { QIcon } from '../../../../QIcons';

export type QStepVariantOptions =
  | 'completed'
  | 'active'
  | 'invalid'
  | 'visited'
  | 'default'
  | 'locked';

export type QStepVariantStyle = {
  container: Partial<SystemStyleObject>;
  title: Partial<SystemStyleObject>;
  description: Partial<SystemStyleObject>;
  iconContainer: Partial<SystemStyleObject>;
  iconElement: React.ReactNode;
};

//Bases
const baseContainerStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  p: 2,
  borderRadius: 'sm',
  cursor: 'pointer',
  minHeight: '36px',
  _hover: {
    bg: 'gray.50',
  },
};

const baseTitleStyle = {
  fontSize: 'sm',
  color: 'gray.500',
};

const baseDescriptionStyle = {
  fontSize: 'xs',
  color: 'gray.500',
};

const baseIconContainer = {
  bg: 'none',
};

export const QStepVariantStyles: Record<
  QStepVariantOptions,
  QStepVariantStyle
> = {
  completed: {
    container: {
      ...baseContainerStyle,
    },
    title: {
      ...baseTitleStyle,
      color: 'gray.800',
    },
    description: {
      ...baseDescriptionStyle,
    },
    iconContainer: {
      ...baseIconContainer,
    },
    iconElement: <QIcon iconName="CheckCircleFilled" color="green.500" />,
  },
  active: {
    container: {
      ...baseContainerStyle,
      bg: 'blue.50',
      _hover: {
        bg: 'blue.50',
      },
    },
    title: {
      ...baseTitleStyle,
      color: 'gray.800',
      fontWeight: 'semibold',
    },
    description: {
      ...baseDescriptionStyle,
      color: 'gray.800',
    },
    iconContainer: {
      ...baseIconContainer,
      color: 'blue.500',
    },
    iconElement: <Dot />,
  },
  invalid: {
    container: {
      ...baseContainerStyle,
      bg: 'red.50',
      _hover: {
        boxShadow: `0 0 0 1px #FEB2B2`,
      },
    },
    title: {
      ...baseTitleStyle,
      color: 'gray.800',
    },
    description: {
      ...baseDescriptionStyle,
    },
    iconContainer: {
      ...baseIconContainer,
    },
    iconElement: <QIcon iconName="AlertCircle" color="red.500" />,
  },
  visited: {
    container: {
      ...baseContainerStyle,
    },
    title: {
      ...baseTitleStyle,
      color: 'gray.800',
    },
    description: {
      ...baseDescriptionStyle,
      color: 'gray.800',
    },
    iconContainer: {
      ...baseIconContainer,
      color: 'blue.200',
    },
    iconElement: <Dot />,
  },
  default: {
    container: {
      ...baseContainerStyle,
    },
    title: {
      ...baseTitleStyle,
    },
    description: {
      ...baseDescriptionStyle,
    },
    iconContainer: {
      ...baseIconContainer,
      color: 'blackAlpha.200',
    },
    iconElement: <Dot />,
  },
  locked: {
    container: {
      ...baseContainerStyle,
      _hover: {
        cursor: 'not-allowed',
      },
    },
    title: {
      ...baseTitleStyle,
    },
    description: {
      ...baseDescriptionStyle,
    },
    iconContainer: {
      ...baseIconContainer,
    },
    iconElement: <QIcon iconName="Lock" color="gray.500" />,
  },
};
