import styled from 'styled-components';

import { themeGet } from '@styled-system/theme-get';

export const StyledSpinner = styled.span<{ secondary?: boolean }>`
  text-indent: -9999em;
  display: inline-block;
  width: ${themeGet('sizes.5')};
  height: ${themeGet('sizes.5')};
  border-radius: 50%;
  background: ${(props) =>
    props.secondary
      ? themeGet('colors.primary.100')
      : themeGet('colors.white')};
  background: -moz-linear-gradient(
    left,
    ${(props) =>
      props.secondary
        ? themeGet('colors.primary.100')
        : themeGet('colors.white')},
    10%,
    rgba(255, 255, 255, 0) 42%
  );
  background: -webkit-linear-gradient(
    left,
    ${(props) =>
      props.secondary
        ? themeGet('colors.primary.100')
        : themeGet('colors.white')},
    10%,
    rgba(255, 255, 255, 0) 42%
  );
  background: -o-linear-gradient(
    left,
    ${(props) =>
      props.secondary
        ? themeGet('colors.primary.100')
        : themeGet('colors.white')},
    10%,
    rgba(255, 255, 255, 0) 42%
  );
  background: -ms-linear-gradient(
    left,
    ${(props) =>
      props.secondary
        ? themeGet('colors.primary.100')
        : themeGet('colors.white')},
    10%,
    rgba(255, 255, 255, 0) 42%
  );
  background: linear-gradient(
    to right,
    ${(props) =>
      props.secondary
        ? themeGet('colors.primary.100')
        : themeGet('colors.white')},
    10%,
    rgba(255, 255, 255, 0) 42%
  );
  position: relative;
  -webkit-animation: load3 1.4s infinite linear;
  animation: load3 1.4s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);

  &::before {
    width: 50%;
    height: 50%;
    background: ${(props) =>
      props.secondary
        ? themeGet('colors.primary.100')
        : themeGet('colors.white')};
    border-radius: 100% 0 0 0;
    position: absolute;
    top: 0;
    left: 0;
    content: '';
  }
  &::after {
    background: ${(props) =>
      props.secondary
        ? themeGet('colors.white')
        : themeGet('colors.primary.100')};
    width: 75%;
    height: 75%;
    border-radius: 50%;
    content: '';
    margin: auto;
    position: absolute;
    inset: 0;
  }
  @-webkit-keyframes load3 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes load3 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`;
