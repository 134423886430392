import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const CROSS_MFE_NAV_EVENT_KEY = 'qualio-x-mfe-nav';

type CrossMfeEventDetail = {
  to: string;
};

declare global {
  interface WindowEventMap {
    [CROSS_MFE_NAV_EVENT_KEY]: CustomEvent<CrossMfeEventDetail>;
  }
}

export type CrossMfeNavigateFunction = (to: string) => void;

/**
 * Listens for cross-MFE navigation events and invokes the
 * given function when they occur.
 *
 * Most developers probably want to use
 * {@link useCrossMfeNavigationListener} instead.
 *
 * @see {@link useCrossMfeNavigate}
 */
export const useCustomCrossMfeNavigationListener = (
  navigate: CrossMfeNavigateFunction,
): void => {
  useEffect(() => {
    const handleEvent = (e: CustomEvent<CrossMfeEventDetail>) =>
      navigate(e.detail.to);
    window.addEventListener(CROSS_MFE_NAV_EVENT_KEY, handleEvent);
    return () => {
      window.removeEventListener(CROSS_MFE_NAV_EVENT_KEY, handleEvent);
    };
  }, [navigate]);
};

/**
 * Navigates when it receives a cross-MFE navigation event.
 *
 * Requires a react router context.
 */
export const useCrossMfeNavigationListener = (): void =>
  useCustomCrossMfeNavigationListener(useNavigate());

/**
 * Call this function to dispatch an event which says you want to navigates
 * to a different microfrontend.
 *
 * Relies on an event listener to actually process the navigation.
 * @see {@link useCrossMfeNavigationListener}
 */
export const navigateAcrossMFEs: CrossMfeNavigateFunction = (to: string) =>
  window.dispatchEvent(
    new CustomEvent(CROSS_MFE_NAV_EVENT_KEY, {
      detail: { to },
    }),
  );

/**
 * Call this function to dispatch an event which says you want to navigates
 * to a different microfrontend.
 *
 * Relies on an event listener to actually process the navigation.
 * @see {@link useCrossMfeNavigationListener}
 *
 * This is a utility hook that just returns the {@link navigateAcrossMFEs}
 * function. If you prefer, you can call that function directly.
 */
export const useCrossMfeNavigate = (): CrossMfeNavigateFunction =>
  navigateAcrossMFEs;
