import React from 'react';
import type { DataProvider } from './types';

export const DataContext = React.createContext<DataProvider<unknown> | null>(
  null,
);

// FIXME: There is no guarentee that the DataContext T will be the T specified here.
export const useDataProvider = <T>(): DataProvider<T> => {
  const context = React.useContext(DataContext) as DataProvider<T> | null;
  if (!context) {
    throw new Error(
      'useDataProvider must be used within a DataContext Provider',
    );
  }

  return context;
};
