import React from 'react';

type StepperContextType = {
  onNext: () => Promise<void>;
  onBack: () => Promise<void>;
  registerStep: (title: string) => void;
  currentStepTitle: string;
  firstStepTitle: string;
  lastStepTitle: string;
  currentStep: number;
  stepTitles: string[];
  isNextLoading: boolean;
  isPreviousLoading: boolean;
  enableFinalNextStep: boolean;
};

const StepperContext = React.createContext<StepperContextType | null>(null);
/**
 * @deprecated
 * Please use equivalent component in QAtoms or QComponents
 */
export const useStepperContext = (): StepperContextType => {
  const context = React.useContext(StepperContext);
  if (!context) {
    throw new Error(
      `Stepper compound components cannot be rendered outside the Stepper component`,
    );
  }
  return context;
};

/**
 * @deprecated
 * Please use equivalent component in QAtoms or QComponents
 */
export const StepperContextProvider: React.FC<{
  value: StepperContextType;
}> = ({ value, children }) => (
  <StepperContext.Provider value={value}>{children}</StepperContext.Provider>
);
