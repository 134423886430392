import styled from '@emotion/styled';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const AttachmentsContainer = styled.div<{ QTheme: any }>`
  .filepond--wrapper {
    .filepond--panel-root {
      background-color: ${({ QTheme }) => QTheme.colors.gray[50]};
    }

    .filepond--credits {
      display: none;
    }

    .filepond--drop-label {
      font-size: 14px !important;
      color: ${({ QTheme }) => QTheme.colors.gray[900]};
    }

    .filepond--label-action {
      color: ${({ QTheme }) => QTheme.colors.blue[500]};
      text-decoration: none;
    }

    .filepond--sub-label {
      font-size: 12px !important;
      color: ${({ QTheme }) => QTheme.colors.gray[700]};
    }

    .filepond--file {
      color: ${({ QTheme }) => QTheme.colors.gray[900]};
      cursor: pointer;
      border: 1px solid ${({ QTheme }) => QTheme.colors.gray[200]};
      border-radius: 4px;

      &:hover {
        border: 1px solid ${({ QTheme }) => QTheme.colors.gray[400]};
      }

      .filepond--file-status {
        margin-right: 60px;

        .filepond--file-status-main {
          font-size: 12px;
        }

        .filepond--file-status-sub {
          font-size: 12px;
          color: ${({ QTheme }) => QTheme.colors.gray[500]};
          opacity: 1 !important;
        }
      }

      .filepond--progress-indicator.filepond--process-indicator {
        margin-top: 7px;
        margin-right: 30px;
        color: ${({ QTheme }) => QTheme.colors.blue[500]};
      }

      .filepond--file-action-button {
        &.filepond--action-retry-item-processing,
        &.filepond--action-abort-item-processing {
          margin-right: 30px;
        }

        &.filepond--file-action-button {
          cursor: pointer;
          color: #ffffff;
          background-color: transparent;
          width: 18px;
          height: 18px;
          margin-top: 7px;

          &::after {
            position: relative;
          }
        }

        &:focus,
        &:hover {
          box-shadow: none;
        }
      }

      .filepond--file-info {
        .filepond--file-info-main {
          font-size: 14px;
        }

        .filepond--file-info-sub {
          font-size: 12px;
          color: ${({ QTheme }) => QTheme.colors.gray[500]};
          opacity: 1 !important;
          white-space: nowrap;
        }
      }
    }

    .filepond--item {
      &[data-filepond-item-state='processing-complete'] .filepond--item-panel {
        background-color: white;
      }

      &[data-filepond-item-state='processing-error'] .filepond--file,
      &[data-filepond-item-state='load-invalid'] .filepond--file,
      &[data-filepond-item-state='remove-error'] .filepond--file,
      &[data-filepond-item-state='processing-revert-error'] .filepond--file {
        background-color: ${({ QTheme }) => QTheme.colors.red[50]};
        border: 1px solid ${({ QTheme }) => QTheme.colors.red[200]};
        cursor: default;

        &:hover {
          background-color: ${({ QTheme }) => QTheme.colors.red[50]};
          border: 1px solid ${({ QTheme }) => QTheme.colors.red[200]};
        }

        .filepond--file-status {
          color: ${({ QTheme }) => QTheme.colors.red[500]};
          margin-right: 60px;
          margin-top: 10px;
        }
      }

      &[data-filepond-item-state='load-invalid'] .filepond--file {
        .filepond--file-status {
          margin-top: 4px;

          .filepond--file-status-sub {
            color: ${({ QTheme }) => QTheme.colors.gray[500]};
          }
        }
      }

      &[data-filepond-item-state='processing-complete']
        .filepond--file
        .filepond--file-status {
        margin-top: 10px;
      }

      &[data-filepond-item-state~='processing'] .filepond--file-status {
        margin-top: 4px;
      }

      &[data-filepond-item-state*='busy'] .filepond--progress-indicator {
        color: ${({ QTheme }) => QTheme.colors.blue[500]};
      }
    }

    .filepond--item > .filepond--panel .filepond--panel-bottom {
      box-shadow: none;
    }

    .filepond--item-panel {
      background-color: white;
      border: none;
    }
  }
`;
