import React from 'react';
import type { RowData } from '@tanstack/react-table';
import { QLink, QText } from '../../../QAtoms';
import { CellProps } from './types';
import { getDataCy, getMetaField } from './meta';
import { CellEventGuard } from './CellEventGuard';

/**
 * A cell that renders the value as a link.
 * Requires a `link` meta property on the column definition.
 * If the link is declared as external, it will open in a new tab.
 * If the link is not null or undefined, the value will be rendered as a string.
 * @see https://reactrouter.com/en/main/components/link
 */
export function QTextLinkCell<TData extends RowData, TValue>({
  getValue,
  column,
  row,
}: CellProps<TData, TValue>): React.ReactElement {
  const { urlAccessor, isExternal, isCrossMFE } = getMetaField(column, 'link');

  const href = urlAccessor(row.original, row.index);

  return (
    <CellEventGuard>
      {href ? (
        <QLink
          href={href.toString()}
          isCrossMFE={isCrossMFE}
          isExternal={
            typeof isExternal === 'function'
              ? isExternal(row.original, row.index)
              : isExternal
          }
          data-cy={getDataCy(column, row.original)}
        >
          {getValue()}
        </QLink>
      ) : (
        <QText data-cy={getDataCy(column, row.original)}>{getValue()}</QText>
      )}
    </CellEventGuard>
  );
}
