import React from 'react';
import type { HeaderContext, RowData } from '@tanstack/react-table';
import { QCheckbox } from '../../../QAtoms';
import { CellProps } from './types';
import { CellEventGuard } from './CellEventGuard';
import { getDataCy } from './meta';

export function QCheckboxHeaderCell<TData extends RowData>({
  table,
}: Readonly<HeaderContext<TData, unknown>>): React.ReactElement {
  return (
    <QCheckbox
      isIndeterminate={table.getIsSomePageRowsSelected()}
      isChecked={table.getIsAllPageRowsSelected()}
      onChange={table.getToggleAllPageRowsSelectedHandler()}
    />
  );
}

export function QCheckboxCell<TData extends RowData>({
  column,
  row,
}: CellProps<TData, unknown>): React.ReactElement {
  const dataCy = getDataCy(column, row.original);
  return (
    <CellEventGuard enableHover>
      <QCheckbox
        isDisabled={!row.getCanSelect()}
        isChecked={row.getIsSelected()}
        onChange={row.getToggleSelectedHandler()}
        data-cy={dataCy}
      />
    </CellEventGuard>
  );
}
