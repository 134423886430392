import { ModalBody, ModalBodyProps } from '@chakra-ui/react';
import React from 'react';

export type QModalBodyProps = ModalBodyProps;

export const QModalBody: React.FC<QModalBodyProps> = (props) => {
  return <ModalBody {...props} />;
};

QModalBody.displayName = 'QModalBody';
