import React, { forwardRef } from 'react';
import { Icon } from '@chakra-ui/react';
import { QIconProps } from './Icon';
import { QTheme } from '../QTheme';

export const ResourceUsers = forwardRef<SVGSVGElement, Partial<QIconProps>>(
  (props, ref) => {
    return (
      <Icon viewBox="0 0 16 16" color="currentColor" {...props} ref={ref}>
        <svg
          width="16"
          height="16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="16" height="16" rx="8" fill={QTheme.colors.gray[100]} />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8 4.667a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5Zm-2.083 1.25a2.083 2.083 0 1 1 4.166 0 2.083 2.083 0 0 1-4.166 0ZM4.86 9.444c.39-.391.92-.61 1.473-.61h3.334a2.083 2.083 0 0 1 2.083 2.083v.833a.417.417 0 1 1-.833 0v-.833a1.25 1.25 0 0 0-1.25-1.25H6.333a1.25 1.25 0 0 0-1.25 1.25v.833a.417.417 0 1 1-.833 0v-.833c0-.553.22-1.083.61-1.473Z"
            fill={QTheme.colors.gray[900]}
          />
        </svg>
      </Icon>
    );
  },
);

ResourceUsers.displayName = 'ResourceUsers';
