import styled, { css } from 'styled-components';
import { space } from 'styled-system';
import { themeGet } from '@styled-system/theme-get';

const primaryButtonStyles = css`
  background-color: ${themeGet('colors.primary.100')};
  color: ${themeGet('colors.white')};
  border: none;

  &:hover {
    background-color: ${themeGet('colors.primary.110')};
  }
`;

const primaryDisabledButtonStyles = css`
  background-color: ${themeGet('colors.primary.40')};
  color: ${themeGet('colors.white')};
  cursor: not-allowed;
`;

const secondaryButtonStyles = css`
  background-color: ${themeGet('colors.white')};
  color: ${themeGet('colors.primary.100')};
  border-color: ${themeGet('colors.primary.80')};

  &:hover {
    background-color: ${themeGet('colors.primary.10')};
  }
`;

const secondaryDisabledButtonStyles = css`
  background-color: ${themeGet('colors.primary.10')};
  color: ${themeGet('colors.primary.40')};
  cursor: not-allowed;
`;

const BaseButton = css<{ large?: boolean }>`
  border: ${themeGet('borders.1')} ${themeGet('colors.primary.20')};
  border-radius: ${themeGet('radii.2')};
  padding: 0 ${themeGet('sizes.5')} 0 ${themeGet('sizes.5')};
  display: inline-flex;
  font-size: ${(props) =>
    props.large ? themeGet('fontSizes.body.2') : themeGet('fontSizes.body.1')};
  width: max-content;
  margin: 0;
  height: ${(props) => (props.large ? '40px' : themeGet('sizes.6'))};
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
  outline: none;

  ${space}
`;

export const PrimaryButton = styled.button`
  ${BaseButton}
  border: ${themeGet('borders.1')} solid currentColor;

  ${(props) =>
    props.disabled ? primaryDisabledButtonStyles : primaryButtonStyles}
`;
export const SecondaryButton = styled.button`
  ${BaseButton}

  ${(props) =>
    props.disabled ? secondaryDisabledButtonStyles : secondaryButtonStyles}
`;

const TextButtonOverrides = css<{ disabled?: boolean }>`
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;

  &:hover:not(:disabled) {
    color: ${themeGet('colors.primary.110')};
  }

  ${(props) =>
    props.disabled
      ? css`
          color: ${themeGet('colors.primary.40')};
          cursor: not-allowed;
        `
      : null}
`;

export const TextButton = styled.button`
  ${BaseButton}
  padding: 0;
  color: ${themeGet('colors.primary.100')};

  ${TextButtonOverrides}
`;

export const IconButton = styled.button<{ small?: boolean; large?: boolean }>`
  ${BaseButton}
  padding: ${themeGet('sizes.3')};
  font-size: ${themeGet('fontSizes.heading.2')};
  color: ${themeGet('colors.primary.100')};

  ${TextButtonOverrides}

  ${(props) =>
    props.small
      ? css`
          font-size: ${themeGet('fontSizes.body.2')};
          height: ${themeGet('lineHeights.body.2')};
          width: ${themeGet('lineHeights.body.2')};
        `
      : null}
  ${(props) =>
    props.large
      ? css`
          padding: ${themeGet('sizes.4')}; ;
        `
      : null}
`;
