import { z } from 'zod';

const V2ResultUrlsSchema = z.object({
  view: z.string().nullable(),
  data: z.string(),
});

const V2ResultSchemaBase = z.object({
  domain: z.string(),
  companyId: z.number().nullable(),
  resource: z.string(),
  resourceSubType: z.string().nullish(),
  resourceSubTypeName: z.string().nullish(),
  identifiers: z.object({
    id: z.string(),
    code: z.string().nullish(),
    qri: z.string(),
    matrixId: z.string().nullish(),
  }),
  status: z.string().nullable(),
  urls: V2ResultUrlsSchema,
  name: z.string(),
  displayLabel: z.string(),
  date: z.object({
    created: z.string().nullish(),
    lastModified: z.string().nullish(),
  }),
  owner: z
    .object({
      id: z.string(),
      fullName: z.string(),
      email: z.string().optional(),
    })
    .nullable(),
});

export const V2ResultSchema = V2ResultSchemaBase.transform((data) => ({
  ...data,
  id: `${data.resource}-${data.identifiers.id}`,
}));
export type V2Result = z.infer<typeof V2ResultSchema>;

export const V2ResponseSchema = z.object({
  results: z.array(V2ResultSchema),
  page: z.number(),
  total: z.number(),
  totalRelation: z.union([z.literal('eq'), z.literal('gte')]),
});

export type V2NonUserResult = z.infer<typeof V2ResultSchemaBase>;

export const V2QriResolverResponseSchema = z.record(z.string(), V2ResultSchema);
