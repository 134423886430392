import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { DrawerProps } from './Drawer';

export const DrawerWrapper = styled.div<Partial<DrawerProps>>`
  background-color: rgba(215, 219, 224, 0.7);
  inset: 0;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  position: absolute;
  transition: all ease-in 0.7s;
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
  z-index: 999;
`;

export const StyledHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: ${themeGet('sizes.1')} solid rgba(21, 7, 38, 0.2);
  padding-bottom: ${themeGet('sizes.5')};
  margin-bottom: ${themeGet('sizes.5')};
`;

export const StyledAside = styled.aside<Partial<DrawerProps>>`
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
  position: fixed;
  overflow-x: hidden;
  top: 0;
  right: ${({ visible }) => (visible ? 0 : '-100vw')};
  min-width: 400px;
  width: 50vw;
  max-width: 800px;
  height: 100%;
  background-color: white;
  box-shadow: 0 ${themeGet('sizes.4')} ${themeGet('sizes.5')} 0
    rgba(21, 7, 38, 0.2);
  transition: all ease-in 0.5s;
  overflow-y: scroll;
  padding: ${themeGet('sizes.5')};
  padding-bottom: 100px; /* This is to ensure it avoids the intercom button! */
  z-index: 1000;
`;
