import { Collapse, CollapseProps } from '@chakra-ui/react';
import React from 'react';

export type QCollapseProps = Omit<CollapseProps, 'in'> & {
  isOpen: boolean;
};

export const QCollapse: React.FC<QCollapseProps> = (props) => {
  const { isOpen, ...rest } = props;
  return <Collapse in={isOpen} {...rest} />;
};
