import React from 'react';
import {
  Radio,
  RadioGroup,
  RadioGroupProps,
  RadioProps,
} from '@chakra-ui/react';

export type QRadioProps = Pick<
  RadioProps,
  | 'isDisabled'
  | 'isChecked'
  | 'isInvalid'
  | 'value'
  | 'onClick'
  | 'onChange'
  | 'isReadOnly'
>;

export const QRadio: React.FC<QRadioProps> = (props) => {
  return <Radio {...props} size="md" />;
};

export type QRadioGroupProps = RadioGroupProps;

export const QRadioGroup: React.FC<QRadioGroupProps> = (props) => {
  const { children, ...rest } = props;

  return <RadioGroup {...rest}>{children}</RadioGroup>;
};
