import styled from '@emotion/styled';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const DateContainer = styled.div<{ QTheme: any; noPopper: boolean }>`

${({ noPopper }) => (noPopper ? '.react-datepicker-popper{display: none}' : '')}

.react-datepicker__close-icon::after {
  background-color: ${({ QTheme }) => QTheme.colors.white};
  color: ${({ QTheme }) => QTheme.colors.gray[700]};
  font-size: 24px;
  border-radius: 4px;
  width: 24px;
  height: 24px;
  padding: 0;
}

.react-datepicker__close-icon:hover::after {
  background-color: ${({ QTheme }) => QTheme.colors.blackAlpha[100]};
}

.react-datepicker-wrapper .chakra-input__left-element {
  z-index: 0;
}
.react-datepicker {
  border: 1px solid ${({ QTheme }) => QTheme.colors.gray[200]};
  box-shadow: ${({ QTheme }) => QTheme.shadows.outline}
  border-radius: ${({ QTheme }) => QTheme.radii.base};
}
.react-datepicker__triangle {
  display: none;
}
.react-datepicker__header {
  background-color: ${({ QTheme }) => QTheme.colors.white};
  border-bottom: 0;
}
.react-datepicker__day {
  border-radius: ${({ QTheme }) => QTheme.radii.sm};
  font-size: ${({ QTheme }) => QTheme.fontSizes.sm};
  margin: 0;
  width: 32px;
  height: 32px;
  line-height: 30px;
}
.react-datepicker__day-name {
  color: ${({ QTheme }) => QTheme.colors.gray[500]};
  font-size: ${({ QTheme }) => QTheme.fontSizes.xs};
}
.react-datepicker__day--keyboard-selected {
  color: ${({ QTheme }) => QTheme.colors.gray[700]};
  background: ${({ QTheme }) => QTheme.colors.white};
  border: 1px solid rgba(0, 0, 0, 0.08);
}
.react-datepicker__day--today{
  font-weight: 800;
}
.react-datepicker__day--today:hover{
  background: ${({ QTheme }) => QTheme.colors.gray[50]};
  color: ${({ QTheme }) => QTheme.colors.gray[700]};
}
.react-datepicker__day:hover {
  background: ${({ QTheme }) => QTheme.colors.gray[50]};
  color: ${({ QTheme }) => QTheme.colors.gray[700]};
}
.react-datepicker__day--selected {
  background: ${({ QTheme }) => QTheme.colors.blue[500]};
}
.react-datepicker__day--selected:hover {
  background: ${({ QTheme }) => QTheme.colors.blue[500]};
  color: ${({ QTheme }) => QTheme.colors.white};
}
`;
