import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useState,
} from 'react';

/**
 * The context for the cell event guard.
 * If the state is true, the the user's focus is inside the cell content,
 * and parents should not expect to receive click events and should style
 * themselves accordingly (i.e. in the case of the row, the hover color
 * should reflect that clicking will not select the row).
 *
 * [isInteractingWithCellContent, setIsInteractingWithCellContent]
 */
export type CellEventGuardContext = [
  boolean,
  Dispatch<SetStateAction<boolean>>,
];

const context = createContext<CellEventGuardContext>([
  false,
  (v) => console.error('No provider found for CellEventGuardContext', v),
]);

export const CellEventGuardProvider: React.FC = ({ children }) => {
  const state = useState(false); // NOSONAR: Don't care that state isn't destructured.
  return <context.Provider value={state}>{children}</context.Provider>;
};

export const useCellEventGuardContext = (): CellEventGuardContext =>
  useContext(context);

export type CellEventGuardProps = {
  enableHover?: boolean;
};

/**
 * A component that prevents events from propagating to the parent element.
 *
 * Sonar will complain about this element being interactive but lacking accessibility features.
 * However, this element is meant to be used as a barrier to prevent events from propagating to
 * the table row.
 * It is expected that this component will wrap other interactive components.
 */
export const CellEventGuard: React.FC<CellEventGuardProps> = ({
  enableHover = false,
  children,
}) => {
  const [, setIsInteractingWithCellContent] = useCellEventGuardContext();
  const onMouseOver = useCallback(
    () => setIsInteractingWithCellContent(true),
    [setIsInteractingWithCellContent],
  );
  const onMouseOut = useCallback(
    () => setIsInteractingWithCellContent(false),
    [setIsInteractingWithCellContent],
  );
  return (
    <span // NOSONAR
      onClick={stopPropagation}
      onMouseOver={enableHover ? undefined : onMouseOver}
      onFocus={enableHover ? undefined : onMouseOver}
      onMouseOut={enableHover ? undefined : onMouseOut}
      onBlur={enableHover ? undefined : onMouseOut}
      tabIndex={-1}
    >
      {children}
    </span>
  );
};

const stopPropagation: React.EventHandler<React.SyntheticEvent> = (e) =>
  e.stopPropagation();
