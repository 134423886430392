import React from 'react';
import { partitionChildren } from '../../utils';
import { QFlex } from '..';
import { Default as DefaultLayout, DefaultLayoutProps } from './Default';

export type FixedLayoutProps = DefaultLayoutProps & {
  width: 640 | 880;
};

export const Fixed: React.VFC<FixedLayoutProps> = ({
  children: [header, ...children],
  width,
  ...rest
}) => {
  const { QFooter, unmatched: content } = partitionChildren(
    children,
    'QFooter',
  );
  return (
    <DefaultLayout {...rest}>
      {header}
      <QFlex
        minW={`${width}px`}
        maxW={`${width}px`}
        direction="column"
        alignSelf="center"
        alignItems="stretch"
        flex="1"
      >
        {content}
      </QFlex>
      <>{QFooter}</>
    </DefaultLayout>
  );
};
