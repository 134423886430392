import React from 'react';
import { QFlex } from '../../QLayouts';
import { QButtonGroup, QButtonProps } from '../../QAtoms';
import { partitionChildren } from '../../utils';

export type QModalActionsProps = {
  children:
    | React.ReactElement<QButtonProps>
    | Array<React.ReactElement<QButtonProps>>;
};

/**
 * Usually, the children of this component are all `QButton` components,
 * and will be rendered inside a right-aligned `QButtonGroup`.
 *
 * You may wish to pass a `QDestructiveButton`, in which case it will be
 * rendered separately from the other buttons and be left-aligned.
 *
 * Any other non-QButton children will be rendered similarly: though it
 * is worth noting that the flex container uses `space-between` as its
 * justification, so bear this in mind when passing other components.
 * (i.e. You may wish to wrap them in your own QButtonGroup.)
 */
export const QModalActions: React.FC<QModalActionsProps> = ({ children }) => {
  const { QButton, unmatched } = partitionChildren(children, 'QButton');

  if (unmatched.length > 0) {
    return (
      <QFlex width="100%" direction="row" justify="space-between">
        {unmatched}
        <QButtonGroup>{QButton}</QButtonGroup>
      </QFlex>
    );
  }
  return <QButtonGroup>{QButton}</QButtonGroup>;
};

QModalActions.displayName = 'QModalActions';
