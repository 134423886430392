import React from 'react';

export type ReorderableContextValue = {
  onMoveUp: (id: string) => void;
  onMoveDown: (id: string) => void;
  canMoveUp: (id: string) => boolean;
  canMoveDown: (id: string) => boolean;
  onDelete?: (id: string) => void;
};

const context = React.createContext<ReorderableContextValue | null>(null);

export const ReorderableContextProvider = context.Provider;

export const useReorderableContext = (): ReorderableContextValue => {
  const value = React.useContext(context);
  if (!value) {
    throw new Error('QReorderableCardStack context not provided');
  }
  return value;
};
