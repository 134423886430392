import colors from '../../QTheme/colors';
import { QColors } from '../../QTypes';

const variants = QColors.reduce((obj, color) => {
  obj[color] = {
    container: {
      bg: `${color}.50`,
      color: `${color}.500`,
      border: `1px solid`,
      borderColor: `${color}.100`,
    },
    number: {
      color: `${color}.900`,
    },
    label: {
      color: `${color}.900`,
    },
    interactive: {
      cursor: 'pointer',
      _hover: {
        borderColor: `${color}.400`,
        boxShadow: 'md',
      },
      _selected: {
        bg: `${color}.100`,
        borderColor: `${color}.400`,
        boxShadow: 'md',
      },
    },
  };
  return obj;
}, {});

export const Stat = {
  parts: ['container', 'icon', 'label', 'number'],
  baseStyle: {
    container: {
      paddingX: '16px',
      paddingTop: '10px',
      paddingBottom: '10px',
      borderRadius: '4px',
      minWidth: '176px',
      bg: 'gray.50',
      color: 'gray.500', // This colors the nested <QIcon>
    },
    number: {
      fontWeight: '700',
      lineHeight: '32px',
    },
    label: {
      fontWeight: '600',
    },
  },
  defaultProps: {
    variant: 'gray',
  },
  variants: {
    ...variants,
    outline: {
      container: {
        bg: 'white',
        color: 'gray.500',
        border: '1px solid',
        borderColor: 'gray.200',
      },
      number: {
        color: 'gray.900',
      },
      label: {
        color: 'gray.900',
      },
    },
    ghost: {
      container: {
        color: 'gray.900',
        bg: 'white',
        border: '0px',
      },
    },
  },
  colors,
};
