import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Stringable } from '../QTypes';

type SetSearchParams = (
  newParams: Record<string, Stringable | Date | null>,
) => void;

export type UseCumulativeSearchParamsResult = [
  Omit<URLSearchParams, 'append' | 'delete' | 'set' | 'sort'>,
  SetSearchParams,
];

/**
 * Functions like `useSearchParams` from `react-router-dom`, with
 * the exception that it will not discard existing search params.
 *
 * A value of `null` will explicitly remove the search param.
 */
export const useCumulativeSearchParams =
  (): UseCumulativeSearchParamsResult => {
    const [searchParams, setSearchParams] = useSearchParams();

    const set: SetSearchParams = useCallback(
      (newParams) =>
        setSearchParams(
          (sp) => {
            Object.entries(newParams).forEach(([key, value]) => {
              if (value === null) {
                sp.delete(key);
              } else {
                sp.set(
                  key,
                  value instanceof Date
                    ? value.toISOString()
                    : value.toString(),
                );
              }
            });
            return sp;
          },
          {
            replace: true,
          },
        ),
      [setSearchParams],
    );

    return [searchParams, set];
  };
