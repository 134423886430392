import React from 'react';
import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerContent,
  DrawerProps,
  DrawerFooter,
  DrawerOverlay,
} from '@chakra-ui/react';
import { QCloseButton } from '../../QAtoms';
import { QStack } from '../../QLayouts';
import { useChakraContainer } from '../../hooks/chakraContainer/chakraContainer';
import { usePartitionedChildren } from '../..';
import { QDrawerHeader } from './DrawerHeader';

export interface QDrawerProps
  extends Pick<DrawerProps, 'size' | 'onClose' | 'isOpen' | 'variant'> {
  /**
   * @deprecated
   * Please use `QDrawerHeader` instead.
   */
  title?: string | React.ReactElement;
}

export const QDrawer: React.FC<QDrawerProps> = (props) => {
  const { isOpen, onClose, title, size = 'md', children, variant } = props;
  const { chakraContainerRef } = useChakraContainer();

  const {
    QHeader,
    QDrawerHeader: header,
    QDrawerFooter: footer,
    QBadge,
    unmatched,
  } = usePartitionedChildren(
    children,
    'QHeader',
    'QDrawerHeader',
    'QDrawerFooter',
    'QBadge',
  );

  const trapFocus = variant !== 'clickThrough';

  return (
    <Drawer
      isOpen={isOpen}
      placement="right"
      onClose={onClose}
      size={size}
      autoFocus={false}
      closeOnOverlayClick={true}
      variant={variant}
      blockScrollOnMount={true}
      trapFocus={trapFocus}
      portalProps={{ containerRef: chakraContainerRef }}
    >
      {variant !== 'clickThrough' && <DrawerOverlay bg="blackAlpha.400" />}
      <DrawerContent>
        <DrawerHeader>
          <QStack direction="row" alignItems="end" spacing="4px">
            {QBadge}
          </QStack>
          {QHeader.length > 0 && QHeader}
          {header.length > 0 && header}
          {!QHeader.length && !header.length && (
            <QDrawerHeader>
              {title}
              <QCloseButton onClick={onClose} />
            </QDrawerHeader>
          )}
        </DrawerHeader>
        <DrawerBody>{unmatched.length > 0 && unmatched}</DrawerBody>
        {footer.length > 0 && <DrawerFooter padding={0}>{footer}</DrawerFooter>}
      </DrawerContent>
    </Drawer>
  );
};
