import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react';
import { HeaderGroup, RowData } from '@tanstack/react-table';
import { QColumnWidthDef } from '../../Columns';

export type WidthRecord = Record<string, number>;

export const useHeaderWidths = <TData extends RowData>(
  ref: React.RefObject<HTMLTableSectionElement>,
  groups: readonly HeaderGroup<TData>[],
): WidthRecord => {
  const [widths, setWidths] = useState<WidthRecord>({});

  const recalculateWidths = useCallback(() => {
    if (!ref.current) {
      return;
    }
    const bounds = ref.current.getBoundingClientRect();

    const { newWidths } = pipeline(
      extractWidthsOrWeights,
      distributeRemainingWidthByWeight,
      growColumnsToFillRemainingWidth,
      shrinkColumnsToFitRemainingWidth,
    )({
      bounds,
      columns: groups
        .flatMap((g) => g.headers)
        .map(({ id, column }) => ({ id, ...column.columnDef.meta })),
      remainingWidth: bounds.width,
      newWidths: {},
      weights: {},
      growShrink: {},
    });

    setWidths(newWidths);
  }, [ref, groups]);

  // Recalculate widths on mount and parent resize
  // (i.e. when one of our parents in React changes size.)
  useLayoutEffect(recalculateWidths, [recalculateWidths]);

  // Recalculate widths on window resize.
  // (i.e. when the browser window changes size.)
  useEffect(() => {
    window.addEventListener('resize', recalculateWidths);
    return () => window.removeEventListener('resize', recalculateWidths);
  }, [recalculateWidths]);

  return widths;
};

type GrowShrink = {
  availableGrow: number;
  availableShrink: number;
};

type ColumnSizingInfo = { id: string } & Omit<
  QColumnWidthDef,
  'width' | 'minWidth' | 'maxWidth'
> & {
    // See Cells/meta.ts for why these fields are manually widened.
    width?: QColumnWidthDef['width'] | string;
    minWidth?: QColumnWidthDef['minWidth'] | string;
    maxWidth?: QColumnWidthDef['maxWidth'] | string;
  };

type PipelineContext = {
  bounds: Readonly<DOMRect>;
  columns: readonly ColumnSizingInfo[];
  remainingWidth: number;
  newWidths: WidthRecord;
  weights: Record<string, number>;
  growShrink: Record<string, GrowShrink>;
};

type PipelineFn = (ctx: PipelineContext) => PipelineContext;

/**
 * Compose a series of functions that operate on a pipeline context.
 * The pipeline does not support cancelling or conditional execution.
 * It just runs all functions in sequence.
 */
const pipeline =
  (...fns: PipelineFn[]) =>
  (ctx: PipelineContext) =>
    fns.reduce((acc, fn) => fn(acc), ctx);

/**
 * If a column has a specific width defined, extract it and store it.
 * Otherwise, store the weight of the column so that we can distribute
 * the remaining width among columns that lack a defined width later.
 */
const extractWidthsOrWeights: PipelineFn = (ctx) => {
  for (const { id, width, weight } of ctx.columns) {
    // Extract the width from the column definition
    const pixelWidth = calculatePixelWidth(width, ctx.bounds.width);
    ctx.newWidths[id] = pixelWidth;

    // If we lack a defined width, store the column weight.
    // If we have a defined width, subtract it from the remaining width.
    // We do this so that we can distribute the remaining width among
    // columns that lack a defined width later (using the weights).
    if (isNaN(pixelWidth)) {
      ctx.weights[id] = weight ?? 1;
    } else {
      ctx.remainingWidth -= pixelWidth;
    }
  }
  return ctx;
};

/**
 * Distribute the remaining width among columns that lack a defined width,
 * based on their column weights.
 * Desired widths are clamped to the min and max width of the column, so
 * this is not guaranteed to distribute the remaining width perfectly.
 */
const distributeRemainingWidthByWeight: PipelineFn = (ctx) => {
  const sumOfWeights = Object.values(ctx.weights).reduce((a, b) => a + b, 0);
  let availableWeightedWidth = ctx.remainingWidth;
  for (const column of ctx.columns) {
    // We only care about columns that don't have a specific width yet.
    if (!isNaN(ctx.newWidths[column.id])) {
      continue;
    }

    // Distribute the remaining width among columns that lack a defined width
    // based on their weight.
    const desiredWidth =
      (ctx.weights[column.id] / sumOfWeights) * availableWeightedWidth;

    // Extract the min and max width from the column definition and clamp
    // the desired with to the min and max width.
    const minWidth =
      calculatePixelWidth(column.minWidth, ctx.bounds.width) || 0;
    const maxWidth =
      calculatePixelWidth(column.maxWidth, ctx.bounds.width) || Infinity;
    const actualWidth = Math.min(Math.max(desiredWidth, minWidth), maxWidth);

    ctx.growShrink[column.id] = {
      availableGrow: maxWidth - actualWidth,
      availableShrink: actualWidth - minWidth,
    };

    ctx.newWidths[column.id] = actualWidth;
    ctx.remainingWidth -= actualWidth;

    // If we were constrained by min/max width, then there will be more/less
    // available space for other columns to take up.
    const delta = actualWidth - desiredWidth;
    availableWeightedWidth -= delta;
  }
  return ctx;
};

/**
 * Distribute any remaining width among columns that have room to grow,
 * weighted by their column weights.
 */
const growColumnsToFillRemainingWidth: PipelineFn = (ctx) => {
  // If no remaining width, then nothing to do.
  if (Math.floor(ctx.remainingWidth) <= 0) {
    return ctx;
  }

  const growers = Object.entries(ctx.growShrink).filter(
    ([, v]) => v.availableGrow > 0,
  );
  const sumOfWeights = growers.reduce((sum, [k]) => sum + ctx.weights[k], 0);

  const requiredGrowth = ctx.remainingWidth;

  for (const [id, { availableGrow }] of growers) {
    const weight = ctx.weights[id];
    const desiredGrow = (weight / sumOfWeights) * requiredGrowth;
    const actualGrow = Math.min(availableGrow, desiredGrow);
    ctx.newWidths[id] += actualGrow;
    ctx.remainingWidth -= actualGrow;
  }
  return ctx;
};

/**
 * Shrink columns that can be shrunk in order to fit the remaining width,
 * so that the column widths don't overflow the alloted space.
 */
const shrinkColumnsToFitRemainingWidth: PipelineFn = (ctx) => {
  // If no remaining width, then nothing to do.
  if (Math.floor(ctx.remainingWidth) >= 0) {
    return ctx;
  }

  const shrinkers = Object.entries(ctx.growShrink).filter(
    ([, v]) => v.availableShrink > 0,
  );
  const sumOfWeights = shrinkers.reduce((sum, [k]) => sum + ctx.weights[k], 0);

  const requiredShrinkage = -ctx.remainingWidth;

  for (const [id, { availableShrink }] of shrinkers) {
    const weight = ctx.weights[id];
    const desiredShrink = (weight / sumOfWeights) * requiredShrinkage;
    const actualShrink = Math.min(availableShrink, desiredShrink);
    ctx.newWidths[id] -= actualShrink;
    ctx.remainingWidth += actualShrink;
  }
  return ctx;
};

/**
 * Calculates the pixel width from a column width string.
 * If the width is a percentage, it is calculated based on the total width.
 * @returns The pixel width, or {@link NaN} if the input string is invalid.
 */
const calculatePixelWidth = (
  str: string | undefined,
  totalWidth: number,
): number => {
  const [width, format] = decodeWidth(str);
  if (format === '%') {
    return (width * totalWidth) / 100;
  }
  return width ?? NaN;
};

/**
 * Decodes a column width string into a number and a format.
 * @returns A tuple of the width and the format, or null or both entries
 * if the input string is invalid.
 */
const decodeWidth = (
  str: string | undefined,
): [number, '%' | 'px'] | [null, null] => {
  if (!str) {
    return [null, null];
  }
  const matches = /(\d+)(px|%)/.exec(str);
  if (!matches) {
    return [null, null];
  }
  const number = parseFloat(matches[1]);
  const format = matches[2] as `%` | `px`;
  return [number, format];
};
