import { RowData } from '@tanstack/react-table';
import { createContext, useContext } from 'react';

export type TableMenuContext<TData extends RowData> = {
  datum: TData;
};

const context = createContext<TableMenuContext<RowData> | null>(null);

export const TableMenuContextProvider = context.Provider;

// There is no guarantee that the T of the context will match the T of this function.
export const useTableMenuContext = <
  T extends RowData,
>(): TableMenuContext<T> => {
  const ctx = useContext(context);
  if (!ctx) {
    throw new Error('useMenuContext must be used within a MenuContextProvider');
  }
  return ctx as TableMenuContext<T>;
};
