import React from 'react';
import { QText } from '..';

export const QSubtitle: React.FC = ({ children }) => (
  <QText maxWidth="720px" fontSize="sm">
    {children}
  </QText>
);

QSubtitle.displayName = 'QSubtitle';
