import { Td } from '@chakra-ui/react';
import React from 'react';
import { Cell } from 'react-table';
import { useCurrentUser } from '../../../hooks';
import { formatDate, formatDateTime } from '../../../utils/dateFormat';
import { QColumnType, QDataColumn, QDataRow } from '../DataTable';

export interface QCellProps {
  cell: Cell<QDataRow>;
  column: QDataColumn;
}

export const QCell: React.FC<QCellProps> = ({ cell, column }) => {
  const { localiseDate } = useCurrentUser();

  const render: string | React.ReactNode = React.useMemo(() => {
    switch (column.type) {
      case QColumnType.DATE:
        return formatDate(localiseDate(cell.value));

      case QColumnType.DATE_NO_YEAR_IF_CURRENT:
        return formatDate(localiseDate(cell.value), true);

      case QColumnType.DATE_TIME:
        return formatDateTime(localiseDate(cell.value));

      case QColumnType.DATE_TIME_NO_YEAR_IF_CURRENT:
        return formatDateTime(localiseDate(cell.value), true);

      default:
        return cell.render('Cell');
    }
  }, [cell, column, localiseDate]);

  return <Td>{render}</Td>;
};
