import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import { space } from 'styled-system';
import { themeGet } from '@styled-system/theme-get';
import {
  TypeOptions,
  WithWeightTextProps,
  WithoutWeightTextProps,
} from './Text';

const textBase = css`
  font-family: ${themeGet('fonts.body')};
`;

const defaultWeight = css`
  font-weight: ${themeGet('fontWeights.normal')};
`;

const boldWeight = css`
  font-weight: ${themeGet('fontWeights.bold')};
`;

const defaultStyles = css`
  color: ${themeGet('colors.gray.80')};
`;
const darkStyles = css`
  color: ${themeGet('colors.primary.120')};
`;
const errorStyles = css`
  color: ${themeGet('colors.error.text')};
`;
const secondaryStyles = css`
  color: ${themeGet('colors.gray.60')};
`;
const successStyles = css`
  color: ${themeGet('colors.success.text')};
`;
const warningStyles = css`
  color: ${themeGet('colors.warning.text')};
`;

const typeStyles: Record<TypeOptions, FlattenSimpleInterpolation> = {
  default: defaultStyles,
  dark: darkStyles,
  error: errorStyles,
  secondary: secondaryStyles,
  success: successStyles,
  warning: warningStyles,
};

const weightStyles = {
  normal: defaultWeight,
  bold: boldWeight,
};

export const StyledLead = styled.p<WithoutWeightTextProps>`
  font-size: ${themeGet('fontSizes.body.3')};
  line-height: ${themeGet('lineHeights.body.3')};

  ${defaultWeight}
  ${textBase}
  ${space}

  ${({ type }) => type && typeStyles[type]}
`;

export const StyledParagraph = styled.p<WithWeightTextProps>`
  font-size: ${themeGet('fontSizes.body.2')};
  line-height: ${themeGet('lineHeights.body.2')};

  ${textBase}
  ${space}
  
  ${({ type }) => type && typeStyles[type]}
  ${({ weight }) => weight && weightStyles[weight]}
`;

export const StyledSmall = styled.p<WithWeightTextProps>`
  font-size: ${themeGet('fontSizes.body.1')};
  line-height: ${themeGet('lineHeights.body.1')};

  ${textBase}
  ${space}
  
  ${({ type }) => type && typeStyles[type]}
  ${({ weight }) => weight && weightStyles[weight]}
`;

export const StyledExtraSmall = styled.p<WithoutWeightTextProps>`
  font-size: ${themeGet('fontSizes.body.0')};
  line-height: ${themeGet('lineHeights.body.0')};

  ${defaultWeight}
  ${textBase}
  ${space}
  
  ${({ type }) => type && typeStyles[type]}
`;
