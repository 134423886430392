import React, { useMemo } from 'react';
import type { RowData } from '@tanstack/react-table';
import { useCurrentUser } from '../../../hooks';
import { QText } from '../../../QAtoms';
import { CellProps } from './types';
import { getDataCy, getMetaField } from './meta';

/**
 * Displays a date in the user's selected Qualio-timezone.
 */
export function QDateCell<TData extends RowData>({
  getValue,
  column,
  row,
}: CellProps<TData, Date | undefined>): React.ReactElement {
  const { formatDate } = useCurrentUser();
  const { includeTime, highlightPastDates } = getMetaField(column, 'date');
  const value = getValue();

  const shouldhighlight = useMemo(() => {
    let shouldHighlightPastDates = highlightPastDates === true;

    if (typeof highlightPastDates === 'function') {
      shouldHighlightPastDates = highlightPastDates(row.original);
    }
    return shouldHighlightPastDates && value && new Date() > value;
  }, [value]);

  return (
    <QText
      color={shouldhighlight ? 'red.500' : undefined}
      data-cy={getDataCy(column, row.original)}
    >
      {value ? formatDate(value, includeTime) : '-'}
    </QText>
  );
}
