import React, { forwardRef } from 'react';
import { Icon } from '@chakra-ui/react';
import { QIconProps } from './Icon';

export const ResourceDesignControls = forwardRef<
  SVGSVGElement,
  Partial<QIconProps>
>((props, ref) => {
  return (
    <Icon
      height="16"
      viewBox="0 0 16 16"
      color="currentColor"
      {...props}
      ref={ref}
    >
      <svg
        fill="none"
        height="16"
        viewBox="0 0 16 16"
        width="16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <clipPath id="a">
          <path d="m3 3h10v10h-10z" />
        </clipPath>
        <rect fill="#9decf9" height="16" rx="2" width="16" />
        <g clipPath="url(#a)">
          <path
            clipRule="evenodd"
            d="m7.81359 3.46074c.1173-.05865.25537-.05865.37268 0l4.16663 2.08333c.1412.07058.2304.21486.2304.37268s-.0892.3021-.2304.37268l-4.16663 2.08333c-.11731.05865-.25538.05865-.37268 0l-4.16667-2.08333c-.14116-.07058-.23032-.21486-.23032-.37268s.08916-.3021.23032-.37268zm-4.35301 4.353c.10292-.20582.3532-.28925.55902-.18634l3.98033 1.99017 3.98037-1.99017c.2058-.10291.4561-.01948.559.18634.1029.20583.0195.45611-.1864.55902l-4.16663 2.08334c-.11731.0586-.25538.0586-.37268 0l-4.16667-2.08334c-.20582-.10291-.28925-.35319-.18634-.55902zm0 2.08334c.10292-.20583.3532-.28926.55902-.18634l3.98033 1.99016 3.98037-1.99016c.2058-.10292.4561-.01949.559.18634.1029.20582.0195.45612-.1864.55902l-4.16663 2.0833c-.11731.0587-.25538.0587-.37268 0l-4.16667-2.0833c-.20582-.1029-.28925-.3532-.18634-.55902z"
            fill="#065666"
            fillRule="evenodd"
          />
        </g>
      </svg>
    </Icon>
  );
});

ResourceDesignControls.displayName = 'ResourceDesignControls';
