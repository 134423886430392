import React from 'react';
import { ContextQStepType } from './Stepper';

type QStepperContextType = {
  activeStep: number;
  steps: ContextQStepType[] | [];
  setSteps: (steps: ContextQStepType[] | []) => void;
  onStepItemClicked: (index: number) => void;
};

const QStepperContext = React.createContext<QStepperContextType | null>(null);

export const useQStepperContext = (): QStepperContextType => {
  const context = React.useContext(QStepperContext);
  if (!context) {
    throw new Error(
      `Stepper compound components cannot be rendered outside the Stepper component`,
    );
  }
  return context;
};

export const QStepperContextProvider: React.FC<{
  value: QStepperContextType;
}> = ({ value, children }) => (
  <QStepperContext.Provider value={value}>{children}</QStepperContext.Provider>
);
