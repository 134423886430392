import React, { useEffect } from 'react';
import { QToastProps } from '../../QAtoms';

export type ToastContextType = {
  showToast: (options: QToastProps) => void;
};

export const ToastContext = React.createContext<ToastContextType | undefined>(
  undefined,
);

const TOAST_EVENT_KEY = 'QToast';

declare global {
  interface WindowEventMap {
    [TOAST_EVENT_KEY]: CustomEvent<QToastProps>;
  }
}

export const useToastEventListener = (
  callback: (props: QToastProps) => void,
): void => {
  useEffect(() => {
    const invokeCallback = (e: CustomEvent<QToastProps>) => callback(e.detail);
    window.addEventListener(TOAST_EVENT_KEY, invokeCallback);
    return () => window.removeEventListener(TOAST_EVENT_KEY, invokeCallback);
  }, [callback]);
};

const delegatingToaster = {
  showToast: (props: QToastProps) =>
    window.dispatchEvent(new CustomEvent(TOAST_EVENT_KEY, { detail: props })),
};

export const useToastProvider = (): ToastContextType => {
  const context = React.useContext(ToastContext);
  if (!context) {
    if (window.location.hostname === 'localhost') {
      console.warn(
        'Toast context not found. Using delegating toaster, which dispatches window events instead of actually handling toasts.',
      );
    }
    return delegatingToaster;
  }
  return context;
};
