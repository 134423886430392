import React from 'react';
import { createPortal } from 'react-dom';
import { QBox } from '../../QLayouts';

const OUTLET_ID = 'q-properties-panel-outlet';

export const QPropertiesPanelOutlet: React.VFC = () => {
  return <QBox id={OUTLET_ID} />;
};

export const QPropertiesPanelPortal: React.FC = ({ children }) => {
  const destination = document.getElementById(OUTLET_ID);
  if (!destination) {
    console.error(
      'Cannot render QPropertiesPanel: QPropertiesPanelOutlet was not found!',
    );
    return null;
  }
  return createPortal(children, destination);
};
