import React from 'react';
import { QBox } from '../../QLayouts';
import { QIconButton } from '../../QAtoms';

export type QReorderButtonsProps = {
  onMoveUp: () => void;
  onMoveDown: () => void;
  canMoveUp?: boolean;
  canMoveDown?: boolean;
};

/**
 * Up and down buttons for reordering items.
 *
 * @see QReorderableCardStack
 */
export const QReorderButtons: React.VFC<QReorderButtonsProps> = ({
  onMoveUp,
  onMoveDown,
  canMoveUp = true,
  canMoveDown = true,
}) => (
  <QBox>
    <QIconButton
      aria-label="reorder up"
      iconName="ArrowUp"
      onClick={onMoveUp}
      isDisabled={!canMoveUp}
      size="sm"
    />
    <QIconButton
      aria-label="reorder down"
      iconName="ArrowDown"
      onClick={onMoveDown}
      isDisabled={!canMoveDown}
      size="sm"
    />
  </QBox>
);
QReorderButtons.displayName = 'QReorderButtons';
