import React from 'react';
import { DataProps } from '../../utils';
import { QBox } from '../../QLayouts/Box/Box';

export type QModalFullBodyProps = DataProps;

/**
 * This component accepts children component and
 * can be used to render that component in the center
 * of the wrapper with height of 280px.
 *
 * For example it can be used to show the blank state for a QModal
 * or show the Loading screen.
 */
export const QModalFullBody: React.FC<QModalFullBodyProps> = ({
  children,
  ...props
}) => {
  return (
    <QBox h="280px" alignContent="center" textAlign="center" {...props}>
      {children}
    </QBox>
  );
};

QModalFullBody.displayName = 'QModalFullBody';
