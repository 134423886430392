import React, { useMemo } from 'react';
import { PaginationContextValue } from './types';
import { PaginationProvider } from './context';
import { usePaginationSearchParams } from './searchParams';
import { useControllableState } from '@chakra-ui/react';

export type PaginationSearchParamKeyProps = {
  pageSizeKey?: string;
  pageIndexKey?: string;
};

export type AutoPaginationProviderProps = Omit<
  PaginationContextValue,
  'pageCount' | 'pageSize' | 'pageIndex' | 'setPageIndex' | 'setPageSize'
> &
  PaginationSearchParamKeyProps & {
    clientSide?: boolean;
  };

const AutoPaginationProvider: React.FC<AutoPaginationProviderProps> = ({
  children,
  pageSizeKey = 'pageSize',
  pageIndexKey = 'pageIndex',
  ...rest
}) => {
  const { pageSize, pageIndex, setPageSize, setPageIndex } =
    usePaginationSearchParams(pageSizeKey, pageIndexKey);

  const [itemCount, setItemCount] = useControllableState<number | undefined>({
    defaultValue: rest.itemCount,
    value: rest.itemCount,
  });

  const [pageCount, setPageCount] = useControllableState<number | undefined>({
    value: itemCount && Math.ceil(itemCount / pageSize),
  });

  const info: PaginationContextValue = useMemo(
    () => ({
      ...rest,
      pageSize,
      setPageSize,
      pageIndex,
      setPageIndex,
      pageCount,
      setPageCount,
      itemCount,
      setItemCount,
    }),
    [
      pageSize,
      pageIndex,
      setPageIndex,
      setPageSize,
      pageCount,
      setPageCount,
      itemCount,
      setItemCount,
      rest,
    ],
  );
  return <PaginationProvider value={info}>{children}</PaginationProvider>;
};

export const Auto = AutoPaginationProvider;
