import { Tag, TagLeftIcon, TagRightIcon } from '@chakra-ui/react';
import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { QText } from '../Text/Text';
import { QTooltip } from '../Tooltip/Tooltip';
import { DataProps } from '../../utils';
import * as icons from 'react-icons/fi';
import { IconNameType } from 'types/Icons';

export type QTagProps = {
  variantColor?:
    | 'default'
    | 'blue'
    | 'invertedBlue'
    | 'green'
    | 'red'
    | 'yellow'
    | 'orange'
    | 'invertedOrange'
    | 'purple'
    | 'pink'
    | 'gray';
  maxWidth?: string;
  children: React.ReactNode;
  leftIcon?: IconNameType;
  rightIcon?: IconNameType;
} & DataProps;

export const QTag = forwardRef<HTMLSpanElement, QTagProps>(
  (
    {
      variantColor = 'default',
      maxWidth,
      children,
      leftIcon,
      rightIcon,
      ...rest
    },
    ref,
  ) => {
    const textRef = useRef<HTMLParagraphElement | null>(null);
    const [textIsTruncated, setTextIsTruncated] = useState(false);
    useEffect(() => {
      if (!textRef.current) {
        return;
      }
      // The text has been truncated with an ellipse if the scrollWidth is
      // greater than the clientWidth (this means that it has overflowed
      // its bounding box and is not fully visible).
      setTextIsTruncated(
        textRef.current.scrollWidth > textRef.current.clientWidth,
      );
    }, [textRef.current]);

    const element = (
      <Tag
        ref={ref}
        variant={variantColor === 'default' ? 'outline' : variantColor}
        size="sm"
        maxWidth={maxWidth}
        {...rest}
      >
        {leftIcon && <TagLeftIcon as={icons[`Fi${leftIcon}`]} />}
        {maxWidth ? (
          <QText ref={textRef} isTruncated>
            {children}
          </QText>
        ) : (
          children
        )}
        {rightIcon && <TagRightIcon as={icons[`Fi${rightIcon}`]} />}
      </Tag>
    );

    if (textIsTruncated && children) {
      return <QTooltip label={children}>{element}</QTooltip>;
    }
    return element;
  },
);
QTag.displayName = 'QTag';
