import React from 'react';

export const Close: React.FC = (props) => {
  return (
    <svg
      {...props}
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.3333 14H2.66668C1.9303 14 1.33334 13.403 1.33334 12.6667V3.33333C1.33334 2.59695 1.9303 2 2.66668 2H6.66668C6.84347 2 7.01302 2.0703 7.13801 2.19533L8.27601 3.33333H13.3333C14.0697 3.33333 14.6667 3.93029 14.6667 4.66667V12.6667C14.6667 13.403 14.0697 14 13.3333 14ZM2.66668 4.66667V12.6667H13.3333V4.66667H2.66668Z"
        fill="#0C63FF"
      />
    </svg>
  );
};

export default Close;
