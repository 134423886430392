import React, { forwardRef } from 'react';
import { Icon } from '@chakra-ui/react';
import { QIconProps } from './Icon';
import { QTheme } from '../QTheme';

export const ResourceDocuments = forwardRef<SVGSVGElement, Partial<QIconProps>>(
  (props, ref) => {
    return (
      <Icon
        height="16"
        viewBox="0 0 16 16"
        color="currentColor"
        {...props}
        ref={ref}
      >
        <svg fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="16" height="16" rx="2" fill={QTheme.colors.blue[100]} />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.25 4.66669C4.25 3.97633 4.80964 3.41669 5.5 3.41669H8V4.25002V6.75002C8 6.98014 8.18655 7.16669 8.41667 7.16669H10.9167L11.75 7.16669V11.3334C11.75 12.0237 11.1904 12.5834 10.5 12.5834H5.5C4.80964 12.5834 4.25 12.0237 4.25 11.3334V4.66669ZM11.5429 6.37031C11.4969 6.30072 11.4437 6.23542 11.3839 6.17557L8.99112 3.7828C8.93126 3.72295 8.86596 3.66979 8.79638 3.62381L8.83333 3.66077L8.83333 4.83928L8.83333 6.33335H11.5059L11.5429 6.37031ZM5.5 10.7084C5.5 10.4782 5.68655 10.2917 5.91667 10.2917H10.0833C10.3135 10.2917 10.5 10.4782 10.5 10.7084C10.5 10.9385 10.3135 11.125 10.0833 11.125H5.91667C5.68655 11.125 5.5 10.9385 5.5 10.7084ZM5.91667 8.62502C5.68655 8.62502 5.5 8.81157 5.5 9.04169C5.5 9.27181 5.68655 9.45835 5.91667 9.45835H10.0833C10.3135 9.45835 10.5 9.27181 10.5 9.04169C10.5 8.81157 10.3135 8.62502 10.0833 8.62502H5.91667ZM5.5 7.37502C5.5 7.1449 5.68655 6.95835 5.91667 6.95835H7.16667C7.39679 6.95835 7.58333 7.1449 7.58333 7.37502C7.58333 7.60514 7.39679 7.79169 7.16667 7.79169H5.91667C5.68655 7.79169 5.5 7.60514 5.5 7.37502Z"
            fill={QTheme.colors.blue[900]}
          />
        </svg>
      </Icon>
    );
  },
);

ResourceDocuments.displayName = 'ResourceDocuments';
