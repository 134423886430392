import { useEffect } from 'react';
import { useFilteringMaybe } from './Filtering';
import { usePaginationMaybe } from './Pagination';

export const useResetPageIndexOnFilterChange = (): void => {
  const filtering = useFilteringMaybe();
  const pagination = usePaginationMaybe();

  useEffect(() => {
    pagination?.setPageIndex(0);
  }, [
    filtering?.searchTerm,
    filtering?.forcedAt,
    JSON.stringify(filtering?.filters),
  ]);
};
