import { chakra, Icon } from '@chakra-ui/react';
import React, { forwardRef, ForwardedRef, PropsWithChildren } from 'react';
import { IconType } from 'react-icons';
import * as icons from 'react-icons/fi';
import {
  BsFillCheckCircleFill,
  BsExclamationCircleFill,
  BsFillExclamationTriangleFill,
  BsFillXCircleFill,
} from 'react-icons/bs';
import { IconNameType } from '../types/Icons';
import { CheckCircleFilled } from './CheckCircleFilled';
import { OneDrive } from './OneDrive';
import { QualioAI } from './QualioAI';
import { ResourceCR } from './ResourceCR';
import { ResourceDocuments } from './ResourceDocuments';
import { ResourceEvents } from './ResourceEvents';
import { ResourceRegistry } from './ResourceRegistry';
import { ResourceSuppliers } from './ResourceSuppliers';
import { ResourceUsers } from './ResourceUsers';
import { Tools } from './Tools';
import Qualio from './Qualio';
import { Events } from './Events';
import BellNotification from './BellNotification';
import Bell from './Bell';
import Settings from './Settings';
import SettingsNotification from './SettingsNotification';
import { UserCircle } from './UserCircle';
import { ResourceDesignControls } from './ResourceDesignControls';
import { MessageCheck } from './MessageCheck';

export type QIconProps = {
  iconName: IconNameType;
  size?: 'xs' | 'sm' | 'md' | 'lg';
  color?: string;
};

function getSize(variant?: string) {
  switch (variant) {
    case 'xs':
      return 3;
    case 'md':
      return 5;
    case 'lg':
      return 6;
    default:
      return 4;
  }
}

type ForwardedSvgRef = ForwardedRef<SVGSVGElement>;
type ForwardedSpanRef = ForwardedRef<HTMLSpanElement>;

export const QIcon = forwardRef<Element, QIconProps>(
  ({ iconName, color, size: rawSize }, ref) => {
    const size = getSize(rawSize);
    const props = {
      color,
      width: size,
      height: size,
    } as const;

    switch (iconName) {
      case 'Bell':
        return <Bell {...props} ref={ref as ForwardedSvgRef} />;
      case 'BellNotification':
        return <BellNotification {...props} ref={ref as ForwardedSvgRef} />;
      case 'CheckCircleFilled':
        return <CheckCircleFilled {...props} ref={ref as ForwardedSvgRef} />;
      case 'OneDrive':
        return <OneDrive {...props} ref={ref as ForwardedSvgRef} />;
      case 'Qualio':
        return <Qualio {...props} ref={ref as ForwardedSvgRef} />;
      case 'QualioAI':
        return <QualioAI {...props} ref={ref as ForwardedSvgRef} />;
      case 'Tools':
        return <Tools {...props} ref={ref as ForwardedSvgRef} />;
      case 'Events':
        return <Events {...props} ref={ref as ForwardedSvgRef} />;
      case 'MessageCheck':
        return <MessageCheck {...props} ref={ref as ForwardedSvgRef} />;
      case 'ResourceCR':
        return <ResourceCR {...props} ref={ref as ForwardedSvgRef} />;
      case 'ResourceDocuments':
        return <ResourceDocuments {...props} ref={ref as ForwardedSvgRef} />;
      case 'ResourceEvents':
        return <ResourceEvents {...props} ref={ref as ForwardedSvgRef} />;
      case 'ResourceRegistry':
        return <ResourceRegistry {...props} ref={ref as ForwardedSvgRef} />;
      case 'ResourceDesignControls':
        return (
          <ResourceDesignControls {...props} ref={ref as ForwardedSvgRef} />
        );
      case 'ResourceSuppliers':
        return <ResourceSuppliers {...props} ref={ref as ForwardedSvgRef} />;
      case 'ResourceUsers':
        return <ResourceUsers {...props} ref={ref as ForwardedSvgRef} />;
      case 'UserCircle':
        return <UserCircle {...props} ref={ref as ForwardedSvgRef} />;
      case 'Settings':
        return <Settings {...props} ref={ref as ForwardedSvgRef} />;
      case 'SettingsNotification':
        return <SettingsNotification {...props} ref={ref as ForwardedSvgRef} />;

      // [Start] BS Icons
      // We avoid importing the icons via wildcard because it would massively
      // increase the bundle size. Therefore, we need a case per-icon.
      case 'FillCheckCircleFill':
        return (
          <ReactIconRefWrapper {...props} ref={ref}>
            <Icon {...props} as={BsFillCheckCircleFill} />
          </ReactIconRefWrapper>
        );
      case 'ExclamationCircleFill':
        return (
          <ReactIconRefWrapper {...props} ref={ref}>
            <Icon {...props} as={BsExclamationCircleFill} />
          </ReactIconRefWrapper>
        );
      case 'FillExclamationTriangleFill':
        return (
          <ReactIconRefWrapper {...props} ref={ref}>
            <Icon {...props} as={BsFillExclamationTriangleFill} />
          </ReactIconRefWrapper>
        );
      case 'FillXCircleFill':
        return (
          <ReactIconRefWrapper {...props} ref={ref}>
            <Icon {...props} as={BsFillXCircleFill} />
          </ReactIconRefWrapper>
        );
      // [END] BS Icons
      default:
        return (
          <ReactIconRefWrapper {...props} ref={ref}>
            <Icon {...props} as={icons[`Fi${iconName}`]} />
          </ReactIconRefWrapper>
        );
    }
  },
);
QIcon.displayName = 'QIcon';

export const getIcon = (iconName: IconNameType): IconType => {
  switch (iconName) {
    case 'FillCheckCircleFill':
      return BsFillCheckCircleFill;
    case 'ExclamationCircleFill':
      return BsExclamationCircleFill;
    case 'FillExclamationTriangleFill':
      return BsFillExclamationTriangleFill;
    case 'FillXCircleFill':
      return BsFillXCircleFill;
    default:
      return icons[`Fi${iconName}`];
  }
};

type ReactIconRefWrapperProps = PropsWithChildren<{
  width: ReturnType<typeof getSize>;
  height: ReturnType<typeof getSize>;
}>;

const ReactIconRefWrapper = forwardRef<Element, ReactIconRefWrapperProps>(
  ({ children, ...rest }, ref) => (
    <chakra.span
      {...rest}
      ref={ref as ForwardedSpanRef}
      display="inline-flex"
      verticalAlign="baseline"
      alignItems="center"
      justifyContent="center"
    >
      {children}
    </chakra.span>
  ),
);
ReactIconRefWrapper.displayName = 'ReactIconRefWrapper';
