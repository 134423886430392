import React from 'react';
import { StyledSpinner } from './Spinner.styles';

export type SpinnerProps = {
  secondary?: boolean;
  styles?: React.CSSProperties | undefined;
};

const Spinner: React.ForwardRefRenderFunction<HTMLDivElement, SpinnerProps> = (
  props,
  ref,
) => {
  const { secondary = false, styles = {} } = props;

  return (
    <StyledSpinner
      aria-label="Loading..."
      aria-busy="true"
      role="contentinfo"
      style={styles}
      ref={ref}
      secondary={secondary}
    />
  );
};

/**
 * @deprecated
 * Please use equivalent component in QAtoms or QComponents
 */

export default React.forwardRef<HTMLDivElement, SpinnerProps>(Spinner);
