import React, { useRef } from 'react';
import { flexRender, HeaderGroup, RowData } from '@tanstack/react-table';
import * as Chakra from '@chakra-ui/react';
import { BORDER_STYLE, COMMON_CELL_PROPS } from './common';
import { QTableRow } from './Row';
import { SortingIcon } from './SortingIcon';
import { useHeaderWidths } from './hooks/useHeaderWidths';

export type QTableHeaderProps<T extends RowData> = {
  groups: readonly HeaderGroup<T>[];
};

export const QTableHeader = <T extends RowData>({
  groups,
}: QTableHeaderProps<T>): React.ReactElement => {
  const ref = useRef<HTMLTableSectionElement>(null);
  const widths = useHeaderWidths(ref, groups);
  return (
    <Chakra.Thead ref={ref} {...BORDER_STYLE}>
      {groups.map((headerGroup) => (
        <QTableRow key={headerGroup.id}>
          {headerGroup.headers.map((header) => (
            <Chakra.Th
              {...STATIC_HEAD_PROPS}
              key={header.id}
              colSpan={header.colSpan}
              width={`${widths[header.id]}px`}
              data-cy={`table-header-${header.id}`}
              onClick={
                header.column.getCanSort()
                  ? () => header.column.toggleSorting()
                  : undefined
              }
              _hover={{
                cursor: header.column.getCanSort() ? 'pointer' : undefined,
              }}
              sx={getHeaderStyle(header.column.columnDef.header)}
            >
              {header.isPlaceholder
                ? null
                : flexRender(
                    header.column.columnDef.header,
                    header.getContext(),
                  )}
              {header.column.getCanSort() && (
                <SortingIcon direction={header.column.getIsSorted()} />
              )}
            </Chakra.Th>
          ))}
        </QTableRow>
      ))}
    </Chakra.Thead>
  );
};

const getHeaderStyle = (column: string | React.ReactNode) => {
  if (typeof column === 'string' && column.toLowerCase() === 'id') {
    return {
      textTransform: 'uppercase',
    };
  }
  return {
    textTransform: 'lowercase',
    '::first-letter': {
      textTransform: 'uppercase',
    },
  };
};

const STATIC_HEAD_PROPS = {
  ...COMMON_CELL_PROPS,
  fontWeight: 600,
  userSelect: 'none',
  whiteSpace: 'normal',
  letterSpacing: 'normal',
  verticalAlign: 'baseline',
  backgroundColor: 'gray.50',
} satisfies Chakra.TableHeadProps;
