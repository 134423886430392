import React from 'react';
import * as Chakra from '@chakra-ui/react';
import { QFlex } from '../../../QLayouts';
import { QEmptyState } from '../../../QComponents';

export type QEmptyInitiallyTableProps = {
  colSpan: number;
  children: React.ReactElement[];
};

export const QEmptyInitiallyTable: React.VFC<QEmptyInitiallyTableProps> = ({
  colSpan,
  children,
}) => (
  <Chakra.Tr>
    <Chakra.Td colSpan={colSpan}>
      <QFlex justifyContent="center">
        {children.length === 0 ? (
          <QEmptyState
            title="There are no items to show"
            data-cy="data-table-empty"
          />
        ) : (
          children
        )}
      </QFlex>
    </Chakra.Td>
  </Chakra.Tr>
);
