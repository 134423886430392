import React from 'react';

export type QSelectItem<
  TLabel extends React.ReactChild = string,
  TValue = string,
> = {
  label: TLabel;
  value: TValue;
  options?: never;
};

export type QSelectGroup<
  TGroupLabel extends React.ReactChild = string,
  TLabel extends React.ReactChild = string,
  TValue = string,
> = {
  label: TGroupLabel;
  options: QSelectItem<TLabel, TValue>[];
  value?: never;
};

export const isGroup = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  thing: QSelectItem<any, unknown> | QSelectGroup<any, any, unknown>,
): thing is QSelectGroup => thing.options !== undefined;
