import React from 'react';
import { ChakraProvider, ThemeProvider } from '@chakra-ui/react';
import { CSSReset, QTheme } from '..';
import { ChakraContainerProvider } from '../hooks/chakraContainer/chakraContainer';

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'chakra-scope': React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
      >;
    }
  }
}

export const QualioQThemeProvider: React.FC = ({ children }) => (
  <chakra-scope>
    <ChakraProvider theme={QTheme} resetCSS={false}>
      <ThemeProvider theme={QTheme}>
        <ChakraContainerProvider>
          <CSSReset />
          {children}
        </ChakraContainerProvider>
      </ThemeProvider>
    </ChakraProvider>
  </chakra-scope>
);
