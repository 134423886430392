import React from 'react';
import * as Chakra from '@chakra-ui/react';
import { QFlex } from '../../../QLayouts';
import { QText } from '../../../QAtoms';

export type QEmptyFilteredTableProps = { colSpan: number };

export const QEmptyFilteredTable: React.VFC<QEmptyFilteredTableProps> = ({
  colSpan,
}) => (
  <Chakra.Tr>
    <Chakra.Td colSpan={colSpan}>
      <QFlex justifyContent="center">
        <QText fontSize="sm">
          Your search and/or filtering produced no results.
        </QText>
      </QFlex>
    </Chakra.Td>
  </Chakra.Tr>
);
