import { Row, RowData } from '@tanstack/react-table';
import { createContext, useContext } from 'react';

export type SelectionRecord = Record<string, boolean>;

type RowPredicate<TData extends RowData> = (
  row: Pick<Row<TData>, 'id' | 'original'>,
) => boolean;

export type SelectionContextValue = {
  selection: SelectionRecord;
  setSelection: (
    selected: SelectionRecord | ((prev: SelectionRecord) => SelectionRecord),
  ) => void;
  canSelect?: RowPredicate<unknown>;
};

const context = createContext<SelectionContextValue | null>(null);

export const { Provider } = context;

export const useSelectionMaybe = (): SelectionContextValue | null =>
  useContext(context);
