import React, { useCallback, useEffect, useState } from 'react';
import {
  HTMLChakraProps,
  Textarea,
  TextareaProps,
  useControllableState,
} from '@chakra-ui/react';
import { QStack } from '../../QLayouts';
import { usePartitionedChildren } from '../../utils/partitionChildren';
import { CharacterCount, QCharacterCountProvider } from './CharacterCount';

type Omitted =
  | 'isInvalid'
  | 'isDisabled'
  | 'value'
  | 'onChange'
  | 'placeholder'
  | 'isReadOnly'
  | 'size'
  | 'maxLength';
export type QTextareaProps = Omit<HTMLChakraProps<'textarea'>, Omitted> &
  Pick<
    TextareaProps,
    | 'isInvalid'
    | 'isDisabled'
    | 'value'
    | 'onChange'
    | 'placeholder'
    | 'isReadOnly'
    | 'size'
  > & {
    /**
     * @deprecated Use `{@link QCharacterCounter} instead.`
     * This prop was implicitly provided by the {@link HTMLChakraProps} for 'textarea'.
     */
    maxLength?: number | undefined;
  };

export const QTextarea: React.FC<QTextareaProps> = ({ children, ...props }) => {
  const [value, setValue] = useControllableState({
    defaultValue: '',
    value: props.value?.toString(),
    shouldUpdate: () => true, // Issue: `prev` is always empty, so clearing the input becomes impossible.
  });

  const [count, setCount] = useState<CharacterCount>({
    current: value.length,
    max: null,
  });

  useEffect(
    () =>
      setCount((c) => ({
        ...c,
        current: value.length,
      })),
    [value],
  );

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      const newValue = e.target.value.slice(0, count.max || undefined);
      if (value != newValue) {
        setValue(newValue);
        props.onChange?.({ ...e, target: { ...e.target, value: newValue } });
      }
    },
    [setValue, value, props.onChange, count.max],
  );

  // We don't destructure the `unmatched` key, so extra children will be silently discarded.
  const { CharacterCount: characterCounters } = usePartitionedChildren(
    children,
    'CharacterCount',
  );

  return (
    <QCharacterCountProvider value={[count, setCount]}>
      <QStack flexGrow={1}>
        <Textarea
          {...props}
          onChange={onChange}
          value={value}
          variant="outline"
        />
        {characterCounters}
      </QStack>
    </QCharacterCountProvider>
  );
};
