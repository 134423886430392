import { useControllableState } from '@chakra-ui/react';
import React, { useEffect, useMemo } from 'react';
import {
  FilterDefinitions,
  ResolvedFilters,
  useFilteringMaybe,
} from '../Filtering';
import * as Pagination from '../Pagination';
import { useSortingMaybe } from '../Sorting';
import { DataContext } from './context';
import type { DataProvider } from './types';

export type FilterOptionFn<T> = (
  item: Readonly<T>,
  searchTerm: string | undefined,
  filters: ResolvedFilters<FilterDefinitions> | undefined,
) => boolean;

export type FixedDataProviderProps<T> = {
  data: T[];
  filterOption?: FilterOptionFn<T>;
  isLoading?: boolean;
  children: React.ReactNode;
};

/**
 * A data provider that uses a fixed array of data.
 * It does not include any loading or pagination logic.
 */
export const Fixed = <T,>({
  data,
  filterOption,
  isLoading = false,
  children,
}: FixedDataProviderProps<T>): React.ReactElement => {
  const filtering = useFilteringMaybe();
  const sorting = useSortingMaybe();

  // While Fixed data provider does not support pagination, we still need to
  // provide the item count to the pagination provider so that downstream
  // elements (e.g. tables) can display the correct count of the items.
  const pagination = Pagination.usePaginationMaybe();
  useEffect(() => {
    pagination?.setItemCount?.(data.length);
  }, [data.length, pagination?.setItemCount]);

  // If no filtering provider was found, we still need to provide a search term state.
  const [searchTerm, setSearchTerm] = useControllableState({
    value: filtering?.searchTerm,
    defaultValue: '',
    onChange: filtering?.setSearchTerm,
    shouldUpdate: (prev, next) => prev !== next || next === '',
  });

  useEffect(() => {
    if (sorting) {
      console.warn(
        'It is recommended to NOT use manual sorting with fixed data; the table itself usually has more appropriate sorting functions.',
      );
    }
  }, [sorting]);

  const value = useMemo(() => {
    const providedData = filterData(
      data,
      searchTerm,
      filtering?.filters,
      filterOption,
    );

    if (sorting?.column) {
      providedData.sort(naiveSorter(sorting.column));
      if (sorting.descending) {
        providedData.reverse();
      }
    }

    return {
      data: providedData,
      onSearchTermChange: setSearchTerm,
      isLoading,
      fetchNextPage: () =>
        console.log('Fixed data provider does not support pagination'),
      isFetchingNextPage: false,
      hasNextPage: false,
    } satisfies DataProvider<T>;
  }, [data, isLoading, filtering, sorting, setSearchTerm, filterOption]);

  return <DataContext.Provider value={value}>{children}</DataContext.Provider>;
};

/**
 * Convenience component for fixed data that includes a pagination provider.
 */
export const FixedWithAutoPagination = <T,>({
  data,
  children,
  ...rest
}: FixedDataProviderProps<T>): React.ReactElement => (
  <Fixed data={data} {...rest}>
    <Pagination.Auto itemCount={data?.length} clientSide>
      {children}
    </Pagination.Auto>
  </Fixed>
);

/**
 * Creates a sorting function that compares two objects by the given key.
 * It is naive in that it only supports strings and numbers, and returns
 * 0 in every other case. Also, it is not alphanumerically aware.
 */
const naiveSorter = (key: string) => (a: unknown, b: unknown) => {
  if (typeof a !== 'object' || typeof b !== 'object' || !a || !b) {
    return 0;
  }
  const aValue = a[key];
  const bValue = b[key];
  if (typeof aValue === 'string' && typeof bValue === 'string') {
    return aValue.localeCompare(bValue);
  }
  if (typeof aValue === 'number' && typeof bValue === 'number') {
    return aValue - bValue;
  }
  return 0;
};

const filterData = <T,>(
  data: T[],
  searchTerm: string | undefined,
  filters: ResolvedFilters<FilterDefinitions> | undefined,
  filterOption: FilterOptionFn<T> | undefined,
): T[] => {
  if (!searchTerm && !filters) {
    return data;
  }
  if (!filterOption) {
    throw new Error(
      `Developer Error: Filtering is enabled but no filterOption was provided.
When using a Filtering provider with a Fixed Data Provider, you must provide a \`filterOption\` prop.`,
    );
  }
  return data.filter((item) => filterOption(item, searchTerm, filters));
};
