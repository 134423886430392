import { IconButton, IconButtonProps } from '@chakra-ui/react';
import { QIcon } from '../..';
import React from 'react';
import { IconNameType } from '../../types/Icons';

export interface QIconButtonProps
  extends Pick<
    IconButtonProps,
    'isLoading' | 'onClick' | 'size' | 'isDisabled' | 'aria-label' | 'icon'
  > {
  iconName: IconNameType;
  size?: 'xs' | 'sm' | 'md' | 'lg';
  variant?: Extract<IconButtonProps['variant'], 'ghost' | 'outline'>;
  isActive?: boolean;
}

export const QIconButton: React.FC<QIconButtonProps> = (props) => {
  const { iconName, variant = 'ghost', isActive, ...rest } = props;

  return (
    <IconButton
      {...rest}
      variant={variant}
      icon={<QIcon iconName={iconName} size={props.size} />}
      isActive={isActive}
    />
  );
};
