import React, { forwardRef } from 'react';
import { Icon } from '@chakra-ui/react';
import { QIconProps } from './Icon';

export const BellNotification = forwardRef<SVGSVGElement, Partial<QIconProps>>(
  (props, ref) => {
    return (
      <Icon viewBox="0 0 24 24" color="currentColor" {...props} ref={ref}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="none"
        >
          <path
            fill="currentColor"
            d="M14.578 1.427a8 8 0 0 0-8.235 1.916A8 8 0 0 0 4 9v5a2 2 0 0 1-2 2 1 1 0 1 0 0 2h20a1 1 0 1 0 0-2 2 2 0 0 1-2-2v-4a6 6 0 0 1-2-.341V14a4 4 0 0 0 .536 2H5.464A4 4 0 0 0 6 14V9a6 6 0 0 1 8.034-5.645 6 6 0 0 1 .544-1.928m-3.443 19.071a1 1 0 0 0-1.73 1.004 3 3 0 0 0 5.19 0 1 1 0 0 0-1.73-1.004 1 1 0 0 1-1.73 0"
          />
          <circle cx="20" cy="4" r="4" fill="#00B277" />
        </svg>
      </Icon>
    );
  },
);

BellNotification.displayName = 'BellNotification';

export default BellNotification;
