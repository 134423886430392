import React, { useEffect, useRef, useState } from 'react';
import type { RowData } from '@tanstack/react-table';
import { QText, QTooltip } from '../../../QAtoms';
import { CellProps } from './types';
import { getDataCy } from './meta';

/**
 * A simple cell that renders the ID column in the table.
 */
export function QIDCell<TData extends RowData>({
  getValue,
  column,
  row: { original },
}: CellProps<TData, string | number>): React.ReactElement {
  const cellValue = getValue();
  const textRef = useRef<HTMLParagraphElement | null>(null);
  const [isEllipsisActive, setIsEllipsisActive] = useState(false);

  // we need the combination of a state and useEffect here to first allow the component to be
  // rendered and then activate the Ellipsis. To achieve this, we need to set the dependency for
  // useEffect to be an empty array to avoid repetative re-renders.
  useEffect(() => {
    const element = textRef.current;

    setIsEllipsisActive(
      element
        ? element.offsetWidth < element.scrollWidth ||
            element.offsetHeight < element.scrollHeight
        : false,
    );
  }, []);

  const element = (
    <QText
      noOfLines={2}
      wordBreak="break-all"
      ref={textRef}
      data-cy={getDataCy(column, original)}
    >
      {cellValue}
    </QText>
  );

  if (isEllipsisActive) {
    return (
      <QTooltip label={cellValue} placement="right">
        {element}
      </QTooltip>
    );
  }

  return element;
}
