import React, { useState, useMemo } from 'react';
import { AxiosInstance } from 'axios';
import { useCurrentUser } from '../../../../hooks';
import { DataContext } from '../context';
import type { DataProvider } from '../types';
import { useInfiniteQuery, useQuery } from 'react-query';
import { SearchResponseSchemaFor } from './types';
import { SearchIndex } from '../../../../types/domains/Search';
import { useDebounce } from '../../../../hooks/useDebounce';

export type QuickSearchDataProviderProps<T extends SearchIndex> = {
  /**
   * Used to fetch data. Axios client should be configured with a base url and
   * credentials required to access the global search api.
   */
  client: AxiosInstance;
  /**
   * The Qualio entity type to search for.
   * This dictates the response schema from the QuickSearch API.
   */
  entity: T;
  children: React.ReactNode;
  /**
   * List of QRIs to pre-populate the results in addition to the query
   */
  defaultDataQRIs?: readonly string[];
};

const PAGE_SIZE = 10;

export const QuickSearch = <T extends SearchIndex>({
  client,
  entity,
  children,
  defaultDataQRIs,
}: QuickSearchDataProviderProps<T>): React.ReactElement => {
  const { companyId } = useCurrentUser();

  const [searchTerm, setSearchTerm] = useState('');

  const schema = SearchResponseSchemaFor(entity);

  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const qriResult = useQuery(
    ['QuickSearch', entity, companyId, 'qris', defaultDataQRIs],
    async () => {
      if (!defaultDataQRIs || defaultDataQRIs.length === 0) {
        return undefined;
      }
      const response = await client.post('/quicksearch/qri', {
        qris: defaultDataQRIs,
        companyId,
      });
      return schema.parse(response.data);
    },
  );

  const { data, ...result } = useInfiniteQuery({
    queryKey: [
      'QuickSearch',
      entity,
      companyId,
      debouncedSearchTerm,
      PAGE_SIZE,
    ],
    queryFn: async ({ pageParam = 1 }) => {
      const params = new URLSearchParams({
        companyId: companyId.toString(),
        term: debouncedSearchTerm,
        indices: entity,
        size: PAGE_SIZE.toString(),
        page: pageParam.toString(),
      });
      const response = await client.get(`/quicksearch?${params}`);
      return schema.parse(response.data);
    },
    getNextPageParam: (lastPage) => {
      return lastPage.results.length < PAGE_SIZE
        ? undefined
        : lastPage.page + 1;
    },
  });

  const allData = useMemo(() =>
    // Our props declaration and the `.parse` of the response is enough to ensure that the
    // results are in fact of type `T[]` but Typescript is not omniscient enough to know that.
    {
      const qriSearchResults =
        (qriResult.data?.results as unknown as T[]) ?? [];
      const queryResults =
        data?.pages.flatMap((page) => page.results as unknown as T[]) ?? [];
      return [...qriSearchResults, ...queryResults];
    }, [data, qriResult, defaultDataQRIs]);
  return (
    <DataContext.Provider
      value={
        {
          data: allData,
          onSearchTermChange: setSearchTerm,
          hasNextPage: result.hasNextPage ?? false,
          ...result,
          isLoading: qriResult.isLoading || result.isLoading,
          error: qriResult.error ?? result.error,
        } satisfies DataProvider<T>
      }
    >
      {children}
    </DataContext.Provider>
  );
};
