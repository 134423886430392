import type { AccessorFn, ColumnMeta, RowData } from '@tanstack/react-table';
import type { To } from 'react-router';
import { QColumnWidthDef } from '../Columns';
import {
  CellProps,
  HighlightPastDates,
  TagStatusMapping,
  TooltipAccessor,
} from './types';

export type RowPredicate<TData extends RowData> = (row: TData) => boolean;

declare module '@tanstack/table-core' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface ColumnMeta<TData extends RowData, TValue> extends QColumnWidthDef {
    // Manually extending QColumnWidthDef to widen the width fields to allow
    // string values. This patches a rather confusing issue where tsc complains
    // about the column defintions in client repos not having compatible
    // meta fields.
    width?: QColumnWidthDef['width'] | string;
    minWidth?: QColumnWidthDef['minWidth'] | string;
    maxWidth?: QColumnWidthDef['maxWidth'] | string;

    'data-cy'?: string | ((row: TData) => string);

    link?: {
      urlAccessor: AccessorFn<TData, To | null | undefined>;
      isExternal: boolean | AccessorFn<TData, boolean>;
      isCrossMFE: boolean;
    };
    action?: {
      onClick: AccessorFn<TData, void>;
    };
    date?: {
      includeTime: boolean;
      highlightPastDates: HighlightPastDates<TData>;
    };
    tag?: {
      statuses: Readonly<TagStatusMapping>;
    };
    status?: {
      statuses: Readonly<TagStatusMapping>;
      tooltip?: TooltipAccessor<TData>;
    };
    icon?: {
      tooltip?: TooltipAccessor<TData>;
    };
    menu?: {
      items: React.ReactElement | readonly React.ReactElement[];
      hide?: RowPredicate<TData>;
    };
  }
}

export class MissingMetaError extends Error {
  constructor(column: string, field: keyof ColumnMeta<unknown, unknown>) {
    super(
      `Missing Meta: Column "${column}" is missing the "${field}" meta field.`,
    );

    // Set the prototype explicitly.
    // https://github.com/microsoft/TypeScript-wiki/blob/81fe7b91664de43c02ea209492ec1cea7f3661d0/Breaking-Changes.md#extending-built-ins-like-error-array-and-map-may-no-longer-work
    Object.setPrototypeOf(this, MissingMetaError.prototype);
  }
}

/**
 * Gets the value of a meta field from a column.
 * Throws an error if the field is missing.
 */
export const getMetaField = <
  TData extends RowData,
  TValue,
  TKey extends keyof ColumnMeta<TData, TValue>,
>(
  column: CellProps<TData, TValue>['column'],
  key: TKey,
): NonNullable<ColumnMeta<TData, TValue>[TKey]> => {
  const val = column.columnDef.meta?.[key];
  if (val === null || val === undefined) {
    throw new MissingMetaError(column.id, key);
  }
  return val;
};

export const getDataCy = <TData extends RowData, TValue>(
  column: CellProps<TData, TValue>['column'],
  data: TData,
): string | undefined => {
  const val = column.columnDef.meta?.['data-cy'];
  if (typeof val === 'function') {
    return val(data);
  }
  return val;
};
