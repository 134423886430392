import React from 'react';

import { Heading, HeadingProps, HTMLChakraProps } from '@chakra-ui/react';

export interface QHeadingProps
  extends HTMLChakraProps<'h2'>,
    Pick<HeadingProps, 'size' | 'as'> {
  size?: '3xl' | '2xl' | 'xl' | 'lg' | 'md' | 'sm' | 'xs';
}

export const QHeading: React.FC<QHeadingProps> = (props) => {
  const { size = 'md', ...rest } = props;
  return <Heading {...rest} size={size} />;
};
