import { ModalHeader } from '@chakra-ui/react';
import { excludeChildrenOfType } from '../..';
import React from 'react';
import { QBox } from '../..';
import { getChildrenOfType } from '../..';

type QModalHeaderProps = {
  children: React.ReactNode;
};

export const QModalHeader: React.FC<QModalHeaderProps> = ({ children }) => {
  return (
    <ModalHeader>
      {excludeChildrenOfType(children, 'QCloseButton')}
      <QBox position="absolute" top="15px" right="15px">
        {getChildrenOfType(children, 'QCloseButton')}
      </QBox>
    </ModalHeader>
  );
};

QModalHeader.displayName = 'QModalHeader';
