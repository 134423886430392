import React, { useMemo } from 'react';
import { v1 as uuidv1 } from 'uuid';

import {
  ContextQStepType,
  QStepperContextProvider,
  QStepperProps,
  QStepProps,
} from '../../../QComponents';
import { QBox, QStack } from '../..';
import { usePartitionedChildren } from '../../../utils';
import { SplitPanelLeft, SplitPanelLayoutProps } from '../SplitPanel';

export type SplitPanelWithStepperProps = Omit<
  SplitPanelLayoutProps,
  'children'
> &
  QStepperProps & {
    children: [React.ReactElement, ...React.ReactNode[]];
  };

/** A specialisation of the split panel layout that enables the use of a stepper
 * in the panel area.
 *
 * Simply supply a header as the first child, followed by any number of QStep and child components.
 *
 * You do **NOT** need wrap your step contents in QStepPanel components.
 *
 * @example
 * <QBodyLayout.SplitPanelLeftWithStepper
 *     activeStep={activeStep}
 *    onStepItemClicked={(index) => setActiveStep(index)}
 *  >
 *    <MyHeaderComponent />
 *    <QStepperProgress /> // optional
 *    <QStepsActions> // optional
 *      <MyStepActionComponent />
 *    </QStepsActions>
 *    <QStep
 *      title="Step One"
 *      description="This is step one"
 *    />
 *    <QStep
 *      title="Step Two"
 *      description="This is step two"
 *    />
 *    <MyFirstStepContents />
 *    <MySecondStepContents />
 *  </QBodyLayout.SplitPanelLeftWithStepper>
 */
export const SplitPanelLeftWithStepper: React.VFC<
  SplitPanelWithStepperProps
> = ({ children: [header, ...children], activeStep, onStepItemClicked }) => {
  const {
    QStep,
    QStepperProgress,
    QStepsActions,
    QFooter,
    unmatched: StepContents,
  } = usePartitionedChildren(
    children,
    'QStep',
    'QStepperProgress',
    'QStepsActions',
    'QFooter',
  );

  const MappedSteps = useMemo(
    () =>
      QStep.map((step, i) =>
        React.cloneElement(step, {
          key: uuidv1(),
          index: i,
          children: undefined,
          ...(step.props as QStepProps),
        } as ContextQStepType),
      ),
    QStep,
  );

  if (QStep.length !== StepContents.length) {
    console.error(
      'The number of QStep elements should always match the number of content elements.',
      {
        QStepCount: QStep.length,
        ContentCount: StepContents.length,
      },
    );
  }
  return (
    <QStepperContextProvider
      value={{
        activeStep,
        steps: MappedSteps.map((s) => s.props as ContextQStepType),
        setSteps: () =>
          console.error('setSteps is not supported in SplitPanelWithStepper'),
        onStepItemClicked,
      }}
    >
      <SplitPanelLeft>
        {header}
        <QBox
          w="100%"
          p={4}
          borderRadius="base"
          border="1px"
          borderColor="gray.200"
        >
          {QStepperProgress}
          <QStack direction="column" spacing={3}>
            {MappedSteps}
          </QStack>
          {QStepsActions}
        </QBox>
        <QBox h="100%" w="100%">
          {StepContents[activeStep]}
        </QBox>
        <>{QFooter.at(activeStep) ?? QFooter.at(0) ?? null}</>
      </SplitPanelLeft>
    </QStepperContextProvider>
  );
};
