import React, { forwardRef } from 'react';
import { Icon } from '@chakra-ui/react';
import { QIconProps } from './Icon';

export const Qualio = forwardRef<SVGSVGElement, Partial<QIconProps>>(
  (props, ref) => {
    return (
      <Icon viewBox="0 0 20 20" {...props} ref={ref}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 85.97 86">
          <g xmlns="http://www.w3.org/2000/svg" fill="#3d4cf2">
            <path d="m37 85.58a42.65 42.65 0 0 1 -33.6-25.76 3.22 3.22 0 0 1 2.67-4.42c14.41-1.16 26.36 5.31 39 12.19 5.31 2.9 11.54 6.21 16.55 8.29a3.17 3.17 0 0 1 .17 5.8 43.09 43.09 0 0 1 -24.79 3.9zm-25.54-23.89c12.54 20.25 33.54 18.87 40.54 16.81-3.45-1.71-6.79-3.54-10-5.32-10.22-5.57-19.93-10.88-30.54-11.49z" />
            <path d="m61.28 69.23c-4.35-2.06-10.07-5.09-13.37-6.89-12.74-6.94-26.74-14.73-44.2-12.72a3.11 3.11 0 0 1 -3.57-2.89 46 46 0 0 1 .26-9.73 43 43 0 0 1 50.6-36.24 43.19 43.19 0 0 1 34.74 46.71 39.81 39.81 0 0 1 -4.09 14.32 15.55 15.55 0 0 1 -20.37 7.44zm-10.28-12.48c3.44 2 8.7 4.74 12.95 6.68a9.13 9.13 0 0 0 12-4.42 36.63 36.63 0 1 0 -69.6-16.01c5.88 0 18.1-1.62 44.65 13.75z" />
          </g>
        </svg>
      </Icon>
    );
  },
);

Qualio.displayName = 'Qualio';

export default Qualio;
