import { RowData } from '@tanstack/react-table';
import React, { useLayoutEffect, useRef, useState } from 'react';
import { QStack, QBox } from '../../../QLayouts';
import { QTag, QText, QTooltip } from '../../../QAtoms';
import { getDataCy, getMetaField } from './meta';
import { CellProps } from './types';

const WILDCARD_STATUS = '*';

/**
 * Displays a list of tags in a horizontal row. If the tags overflow the
 * container, the excess tags are hidden and a tooltip is displayed showing the
 * full list of tags.
 */
export function QTagCell<TData extends RowData>({
  getValue,
  column,
  row: { original },
}: CellProps<TData, readonly string[]>): React.ReactElement {
  const { statuses } = getMetaField(column, 'tag');
  const tags = getValue();

  const containerRef = useRef<HTMLDivElement>(null);
  const tagRefs = useRef<(HTMLSpanElement | null)[]>([]);
  const [isComputingLayout, setIsComputingLayout] = useState(true);
  const [numHiddenTags, setNumHiddenTags] = useState(0);

  useLayoutEffect(() => {
    if (!isComputingLayout || !containerRef.current) {
      return;
    }

    const containerWidth =
      containerRef.current?.getBoundingClientRect().width ?? 0;

    const tagWidths = tagRefs.current.map(
      (el) => el?.getBoundingClientRect().width,
    );

    // Count the number of tags that will fit within the container.
    let i = 0;
    for (let remainingWidth = containerWidth; i < tags.length; i++) {
      remainingWidth -= tagWidths[i] ?? 0;
      if (remainingWidth < 0) {
        break;
      }
    }
    const numVisibleTags = Math.max(1, i);
    setNumHiddenTags(tags.length - numVisibleTags);
    setIsComputingLayout(false);
  }, [tags, tagRefs.current]);

  const fallbackVariant = statuses[WILDCARD_STATUS];

  const dataCy = getDataCy(column, original);

  return (
    <QBox ref={containerRef}>
      <QStack
        direction="row"
        spacing="8px"
        position={isComputingLayout ? 'absolute' : undefined}
        data-cy={dataCy}
      >
        {tags.slice(0, tags.length - numHiddenTags).map((tag, i) => (
          <QTag
            key={tag}
            ref={(el) => (tagRefs.current[i] = el)}
            variantColor={statuses[tag] ?? fallbackVariant}
            maxWidth="200px"
            data-cy={dataCy && `${dataCy}-${tag}`}
          >
            {tag}
          </QTag>
        ))}
        {numHiddenTags > 0 && (
          <OverflowTags tags={tags.slice(tags.length - numHiddenTags)} />
        )}
      </QStack>
    </QBox>
  );
}

type OverflowTagsProps = {
  tags: readonly string[];
};

/**
 * Displays a tooltip with a list of tags that.
 */
const OverflowTags: React.VFC<OverflowTagsProps> = ({ tags }) => (
  <QTooltip label={<StackedTags tags={tags} />}>
    <QText color="blue.500" cursor="pointer">
      +{tags.length}
    </QText>
  </QTooltip>
);

/**
 * Displays a list of tags in a vertical column.
 */
const StackedTags: React.VFC<OverflowTagsProps> = ({ tags }) => (
  <QBox>
    {tags.map((tag) => (
      <QText key={tag}>{tag}</QText>
    ))}
  </QBox>
);
