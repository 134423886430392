import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { QText } from '../Text/Text';

type CharacterLimit = number | null;

export type CharacterCount = {
  current: number;
  max: CharacterLimit;
};

const context = createContext<
  [CharacterCount, Dispatch<SetStateAction<CharacterCount>>]
>([
  { current: 0, max: null },
  (newState) => {
    console.error(
      "Developer Error: You're trying to use a QCharacterCount outside of a QCharacterCountProvider.",
      newState,
    );
  },
]);

export const QCharacterCountProvider = context.Provider;

export type QCharacterCountProps = {
  max: CharacterLimit;
};

export const QCharacterCount: React.FC<QCharacterCountProps> = ({ max }) => {
  const [count, setCount] = useContext(context);

  useEffect(() => setCount((c) => ({ ...c, max })), [max]);

  const color = useMemo(
    () => (count.max && count.current >= count.max ? 'red.900' : 'gray.900'),
    [count],
  );

  return (
    <QText fontSize="12px" color={color}>
      Character counter: {count.current}/{max || '∞'}
    </QText>
  );
};
QCharacterCount.displayName = 'CharacterCount';
