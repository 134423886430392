import { useCallback } from 'react';
import { useCumulativeSearchParams } from '../../../hooks/cumulativeSearchParams';
import { PageSize } from './types';

export type UsePaginationSearchParamsResult = {
  pageSize: PageSize;
  pageIndex: number;
  setPageSize: (size: PageSize) => void;
  setPageIndex: (index: number) => void;
};

/**
 * Handles pagination state variables as search params.
 * Will reset the page index to 0 when the page size changes.
 *
 * Despite the naming of the function arguments, this hook can
 * be used for any pagination method (e.g. limit/offset).
 */
export const usePaginationSearchParams = (
  pageSizeKey = 'pageSize',
  pageIndexKey = 'pageIndex',
): UsePaginationSearchParamsResult => {
  const [searchParams, setSearchParams] = useCumulativeSearchParams();
  const pageSize = parseInt(searchParams.get(pageSizeKey) ?? '15') as PageSize;
  const pageIndex = parseInt(searchParams.get(pageIndexKey) ?? '0');
  const setPageSize = useCallback(
    (size: PageSize) => {
      setSearchParams({ [pageSizeKey]: size.toString(), [pageIndexKey]: '0' });
    },
    [pageSizeKey, pageIndexKey, setSearchParams],
  );
  const setPageIndex = useCallback(
    (index: number) => {
      setSearchParams({ [pageIndexKey]: index.toString() });
    },
    [pageIndexKey, setSearchParams],
  );
  return {
    pageSize,
    pageIndex,
    setPageSize,
    setPageIndex,
  };
};
