import React from 'react';
import { usePartitionedChildren } from '../../utils';

export const QEmptyStatePrimaryButton: React.FC = ({ children }) => {
  const { QButton } = usePartitionedChildren(children, 'QButton');

  // Only one primary action button of type QButton is allowed but not required for all use cases
  return QButton[0] ?? null;
};

QEmptyStatePrimaryButton.displayName = 'QEmptyStatePrimaryButton';
