import React from 'react';
import { QFlex, QSpacer } from '../../QLayouts';
import { QButton } from '../../QAtoms';
import { partitionChildren } from '../../utils/partitionChildren';
import {
  QReorderButtons,
  QReorderButtonsProps,
} from '../ReorderButtons/ReorderButtons';

const CardFooter: React.FC = ({ children }) => {
  const { QButton, unmatched } = partitionChildren(children, 'QButton');

  return (
    <QFlex
      height="48px"
      align="center"
      padding="8px"
      borderTop="1px solid"
      borderColor="gray.200"
      background="gray.50"
    >
      {QButton}
      <QSpacer />
      {unmatched}
    </QFlex>
  );
};
CardFooter.displayName = 'QCardFooter';

export type QSimpleCardFooterProps = QReorderButtonsProps & {
  onDelete?: () => void;
};

const Simple: React.VFC<QSimpleCardFooterProps> = ({
  onDelete,
  ...reorderButtonsProps
}) => (
  <CardFooter>
    {onDelete && (
      <QButton
        isDestructive
        leftIcon="Trash"
        onClick={onDelete}
        variant="ghost"
      >
        Delete
      </QButton>
    )}
    <QReorderButtons {...reorderButtonsProps} />
  </CardFooter>
);
Simple.displayName = 'QCardFooter';

interface WithSimpleVariant extends React.FC {
  Simple: React.VFC<QSimpleCardFooterProps>;
}
(CardFooter as WithSimpleVariant).Simple = Simple;

/**
 * Renders a footer for a card.
 * Expects parent to provide outer border.
 *
 * For a simple footer with delete and/or reorder buttons,
 * use the `QCardFooter.Simple` variant.
 */
export const QCardFooter = CardFooter as WithSimpleVariant;
