import React from 'react';
import { WrapItem } from '@chakra-ui/react';
import { useCurrentUser } from '../../hooks';
import { QText } from '../../QAtoms';
import { DataProps } from '../../utils';

export type QMetadataBaseItemProps<T> = {
  label: string;
  value: T | null | undefined;
} & DataProps;

/**
 * An arbitrary label and string value pair.
 */
export const QStringMetadataItem: React.VFC<QMetadataBaseItemProps<string>> = ({
  label,
  value,
  ...rest
}) => {
  if (!value) {
    return null;
  }
  return (
    <WrapItem {...rest}>
      <QText fontSize="sm" fontWeight={400}>
        {label}:&nbsp;
      </QText>
      <QText fontSize="sm" fontWeight={600}>
        {value}
      </QText>
    </WrapItem>
  );
};
QStringMetadataItem.displayName = 'QStringMetadataItem';

type QDateMetadataItemProps = QMetadataBaseItemProps<Date> & {
  withTime?: boolean;
};

/**
 * And arbitrary label and date value pair.
 *
 * Relies on the `useCurrentUser` hook to localise the date.
 *
 * @param withTime - Whether to include the time in the formatted date.
 */
export const QDateMetadataItem: React.VFC<QDateMetadataItemProps> = ({
  label,
  value,
  withTime,
  ...rest
}) => {
  const { formatDate } = useCurrentUser();
  if (!value) {
    return null;
  }
  return (
    <QStringMetadataItem
      label={label}
      value={formatDate(value, withTime)}
      {...rest}
    />
  );
};
QDateMetadataItem.displayName = 'QDateMetadataItem';

export type QMetadataProps<T = string> = Pick<
  QMetadataBaseItemProps<T>,
  'value'
>;

export const QVersionMetadata: React.VFC<QMetadataProps> = ({
  value,
  ...rest
}) => <QStringMetadataItem label="Version" value={value} {...rest} />;
QVersionMetadata.displayName = 'QVersionMetadata';

export const QOwnerMetadata: React.VFC<QMetadataProps> = ({
  value,
  ...rest
}) => <QStringMetadataItem label="Owner" value={value} {...rest} />;
QOwnerMetadata.displayName = 'QOwnerMetadata';

export const QCreatedAtMetadata: React.VFC<QMetadataProps<Date>> = ({
  value,
  ...rest
}) => <QDateMetadataItem label="Created" value={value} withTime {...rest} />;
QCreatedAtMetadata.displayName = 'QCreatedAtMetadata';

export const QLastModifiedMetadata: React.VFC<QMetadataProps<Date>> = ({
  value,
  ...rest
}) => (
  <QDateMetadataItem label="Last modified" value={value} withTime {...rest} />
);
QLastModifiedMetadata.displayName = 'QLastModifiedMetadata';

export const QDueMetadata: React.VFC<QMetadataProps<Date>> = ({
  value,
  ...rest
}) => <QDateMetadataItem label="Due" value={value} {...rest} />;
QDueMetadata.displayName = 'QDueMetadata';
