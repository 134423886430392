import { useControllableState } from '@chakra-ui/react';
import React from 'react';
import { v1 as uuidv1 } from 'uuid';
import {
  QTabList,
  QTabPanel,
  QTabPanels,
  QTabs,
  QTabsWithCompositeProps,
} from '../../../QAtoms';
import { usePartitionedChildren } from '../../../utils';
import { SplitPanelLeft, SplitPanelLayoutProps } from '../SplitPanel';

export type SplitPanelWithTabsProps = Omit<SplitPanelLayoutProps, 'children'> &
  Omit<QTabsWithCompositeProps, 'orientation'> & {
    children: [React.ReactElement, ...React.ReactNode[]];
  };

/** A specialisation of the split panel layout that simplifies the use of tabs
 * in the panel area.
 *
 * Simply supply a header as the first child, followed by any number of QTab and child components.
 *
 * You do **NOT** need to wrap your tab contents in QTabPanel components.
 *
 * If you supply a `QFooter` component, it will be displayed at the bottom of the panel area.
 * If you supply multiple `QFooter` components, the one that matches the current tab index will be displayed.
 *
 * @example
 * <SplitPanelLeftWithTabs>
 *  <MyHeaderComponent />
 *  <QTab>Tab 1</QTab>
 *  <QTab>Tab 2</QTab>
 *  <QTab>Tab 3</QTab>
 *  <MyFirstTabContents />
 *  <MySecondTabContents />
 *  <MyThirdTabContents />
 *  <QFooter>...</QFooter>
 * </SplitPanelLeftWithTabs>
 */
export const SplitPanelLeftWithTabs: React.VFC<SplitPanelWithTabsProps> = ({
  children: [header, ...children],
  ...tabsProps
}) => {
  const {
    QTab: tabItems,
    QFooter: footers,
    unmatched: tabContents,
  } = usePartitionedChildren(children, 'QTab', 'QFooter');

  const [index, setIndex] = useControllableState({
    value: tabsProps.index,
    defaultValue: tabsProps.defaultIndex,
    onChange: tabsProps.onChange,
    shouldUpdate: (prev, next) => prev !== next,
  });

  return (
    <QTabs {...tabsProps} orientation="vertical" onChange={setIndex}>
      <SplitPanelLeft>
        {header}
        <QTabList>{tabItems}</QTabList>
        <QTabPanels h="100%">
          {tabContents.map((c) => (
            <QTabPanel key={uuidv1()} h="100%" p={0}>
              {c}
            </QTabPanel>
          ))}
        </QTabPanels>
        {footers.at(index) ?? footers.at(0) ?? null}
      </SplitPanelLeft>
    </QTabs>
  );
};
