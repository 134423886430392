import React from 'react';

export const CaretRight: React.FC = (props) => {
  return (
    <svg
      {...props}
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.4753 7.99995L6.468 3.99329L5.52533 4.93595L8.592 8.00262L5.52533 11.0646L6.468 12.0073L10.4753 7.99995Z"
        fill="#0069FF"
      />
    </svg>
  );
};

export default CaretRight;
