import React from 'react';
import { SpaceProps } from 'styled-system';
import { QualioTheme } from 'theme';

import {
  StyledExtraSmall,
  StyledSmall,
  StyledParagraph,
  StyledLead,
} from './Text.styles';

export type WeightOptions = 'normal' | 'bold';
export type TypeOptions =
  | 'default'
  | 'dark'
  | 'error'
  | 'success'
  | 'secondary'
  | 'warning';

type withWeightSizeOptions = 'sm' | 'text';
type withoutWeightSizeOptions = 'xs' | 'lead';
export type SizeOptions = withWeightSizeOptions | withoutWeightSizeOptions;

export type WithWeightTextProps = {
  size?: withWeightSizeOptions;
  type?: TypeOptions;
  weight?: WeightOptions;
} & SpaceProps<QualioTheme>;

export type WithoutWeightTextProps = {
  size?: withoutWeightSizeOptions;
  type?: TypeOptions;
} & SpaceProps<QualioTheme>;

/**
 * @deprecated
 * Please use equivalent component in QAtoms or QComponents
 */

export const Text: React.FC<WithWeightTextProps | WithoutWeightTextProps> = (
  textProps,
) => {
  const { size = 'text', type = 'default', children, ...styles } = textProps;
  const weight: WeightOptions =
    'weight' in textProps ? textProps.weight ?? 'normal' : 'normal';

  const styledText: Record<SizeOptions, React.ReactElement> = {
    xs: (
      <StyledExtraSmall type={type} {...styles}>
        {children}
      </StyledExtraSmall>
    ),
    sm: (
      <StyledSmall type={type} weight={weight} {...styles}>
        {children}
      </StyledSmall>
    ),
    text: (
      <StyledParagraph type={type} weight={weight} {...styles}>
        {children}
      </StyledParagraph>
    ),
    lead: (
      <StyledLead type={type} {...styles}>
        {children}
      </StyledLead>
    ),
  };

  return styledText[size];
};
