import React from 'react';
import {
  Table,
  TableBodyProps,
  TableCellProps,
  TableHeadProps,
  TableProps,
  TableRowProps,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { TableHeaderProps } from 'react-table';

/**
 * @deprecated Please use QDataTable instead.
 */
export const QTable: React.FC<TableProps> = (props: TableProps) => {
  return <Table {...props} />;
};

/**
 * @deprecated Please use QDataTable instead.
 */
export const QThead: React.FC<TableHeadProps> = (props: TableHeadProps) => {
  return <Thead {...props} />;
};

/**
 * @deprecated Please use QDataTable instead.
 */
export const QTbody: React.FC<TableBodyProps> = (props: TableBodyProps) => {
  return <Tbody {...props} />;
};

/**
 * @deprecated Please use QDataTable instead.
 */
export const QTh: React.FC<TableHeaderProps & { width?: string | number }> = (
  props: TableHeaderProps & { width?: string | number },
) => {
  return <Th {...props} />;
};

/**
 * @deprecated Please use QDataTable instead.
 */
export const QTr: React.FC<TableRowProps> = (props: TableRowProps) => {
  return <Tr {...props} />;
};

/**
 * @deprecated Please use QDataTable instead.
 */
export const QTd: React.FC<TableCellProps> = (props: TableCellProps) => {
  return <Td {...props} />;
};
