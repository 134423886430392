import { CollapseProps } from '@chakra-ui/react';
import { AnimatePresence, motion, Transition, Variants } from 'framer-motion';
import React, { forwardRef } from 'react';

export type QCollapseHorizontalProps = Pick<
  CollapseProps,
  'unmountOnExit' | 'children'
> & {
  isOpen: boolean;
  height?: `${number}%` | `${number}px`;
};

/**
 * QHorizontalCollapse animates its width to show or hide
 * its children.
 *
 * It is a fork of Chakra's Collapse component, changed
 * to work horizontally rather than vertically.
 * Many of Chakra's optional props have been removed.
 */
export const QCollapseHorizontal = forwardRef<
  HTMLDivElement,
  QCollapseHorizontalProps
>(({ isOpen, unmountOnExit, children, height = '100%' }, ref) => {
  const custom = {
    startingWidth: 0,
    endingWidth: 'auto',
    transitionEnd: {
      exit: unmountOnExit
        ? undefined
        : {
            display: 'none',
          },
    },
  };

  const show = unmountOnExit ? isOpen : true;
  const animate = isOpen || unmountOnExit ? 'enter' : 'exit';

  return (
    <AnimatePresence initial={false} custom={custom}>
      {show && (
        <motion.div
          ref={ref}
          className="chakra-collapse"
          style={{
            overflow: 'hidden',
            display: 'block',
            height: height,
          }}
          custom={custom}
          variants={variants}
          initial={unmountOnExit ? 'exit' : false}
          animate={animate}
          exit="exit"
          transition={transition}
        >
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  );
});

QCollapseHorizontal.displayName = 'QCollapseHorizontal';

const transition: Transition = { ease: 'easeOut', duration: 0.2 };

const variants: Variants = {
  exit: ({ startingWidth, transition, transitionEnd }) => ({
    opacity: 0,
    width: startingWidth,
    transitionEnd: transitionEnd?.exit,
    transition: transition?.exit,
  }),
  enter: ({ endingWidth, transition, transitionEnd }) => ({
    opacity: 1,
    width: endingWidth,
    transitionEnd: transitionEnd?.enter,
    transition: transition?.enter,
  }),
};
