import React, { forwardRef } from 'react';
import { Icon } from '@chakra-ui/react';
import { QIconProps } from './Icon';

export const MessageCheck = forwardRef<SVGSVGElement, Partial<QIconProps>>(
  (props, ref) => {
    return (
      <Icon viewBox="0 0 24 24" color="currentColor" {...props} ref={ref}>
        <svg width="24" height="24" fill="none">
          <path
            d="M5 3.99976c-.26522 0-.51957.10535-.70711.29289C4.10536 4.48019 4 4.73454 4 4.99976V18.5855l2.29289-2.2929c.18754-.1875.44189-.2928.70711-.2928h12c.2652 0 .5196-.1054.7071-.2929.1875-.1876.2929-.4419.2929-.7071V7.99976c0-.33334.2-1 1-1s1 .66666 1 1v7.00004c0 .7956-.3161 1.5587-.8787 2.1213-.5626.5626-1.3256.8787-2.1213.8787H7.41421l-3.7071 3.7071c-.286.286-.71612.3715-1.08979.2167C2.24364 21.7689 2 21.4042 2 20.9998V4.99976c0-.79565.31607-1.55872.87868-2.12132C3.44129 2.31583 4.20435 1.99976 5 1.99976h10c.3333 0 1 .2 1 1s-.6667 1-1 1H5Z"
            fill="currentColor"
            color="currentColor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M21.2929 1.29289c.3905-.390521 1.0237-.390521 1.4142 0 .3905.39053.3905 1.02369 0 1.41422l-10 9.99999c-.3905.3905-1.0237.3905-1.4142 0L8.29289 9.70711c-.39052-.39053-.39052-1.02369 0-1.41422.39053-.39052 1.02369-.39052 1.41422 0L12 10.5858l9.2929-9.29291Z"
            fill="currentColor"
            color="currentColor"
          />
        </svg>
      </Icon>
    );
  },
);

MessageCheck.displayName = 'MessageCheck';
