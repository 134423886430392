export const Text = {
  baseStyle: {
    lineHeight: 'base',
  },
  variants: {
    regular: {
      fontWeight: 'regular',
    },
    semibold: {
      fontWeight: 'semibold',
    },
  },
  defaultProps: {
    variant: 'regular',
  },
};
