import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { CloseOutlined } from '../../atoms/Icon';

const styles = {
  default: {
    backgroundColor: '#F3F7FF',
    border: '1px solid #6DA1FF',
  },
  error: {
    backgroundColor: '#FBEBEC',
    border: '1px solid #E35D6A',
  },
  success: {
    backgroundColor: '#EAF6EC',
    border: '1px solid #53B96A',
  },
  warning: {
    backgroundColor: '#FFF9E6',
    border: '1px solid #FFDA6A',
  },
};

export const StyledSpan = styled.span<{ title?: string }>`
  position: absolute;
  top: ${(props) => (props.title ? themeGet('sizes.5') : '10px')};
  right: ${themeGet('sizes.5')};
  cursor: pointer;
`;

export const StyledAlert = styled.article<{ type: string }>`
  position: relative;
  padding: ${themeGet('sizes.4')} ${themeGet('sizes.5')};
  margin-bottom: ${themeGet('sizes.4')};
  border-radius: ${themeGet('sizes.3')};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  ${(props) => styles[props.type]}
`;

export const StyledClosed = styled(CloseOutlined)``;
