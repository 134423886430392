import { z } from 'zod';
import { SearchDomain, SearchIndex } from '../../../../types/domains/Search';

const baseResult = z.object({
  qri: z.string(),
  title: z.string(),
  code: z.string(),
  companyId: z.number(),
  owner: z.string(),
});

const documentResult = baseResult.merge(
  z.object({
    domain: z.literal(SearchDomain.enum.documents),
    domainType: z.literal('document'),
    id: z.number(),
    matrixId: z.string().optional(),
    link: z.string(),
    version: z.string(),
    status: z.string(),
    author: z.string(),
  }),
);

const eventResult = baseResult.merge(
  z.object({
    domain: z.literal('quality-events'),
    domainType: z.literal('quality-event'),
    id: z.number(),
  }),
);

const supplierResult = baseResult
  .merge(
    z.object({
      domain: z.literal(SearchDomain.enum.suppliers),
      domainType: z.literal('supplier'),
      id: z.string(),
      version: z.number(),
      status: z.string(),
    }),
  )
  .omit({ code: true });

const changeControlResult = baseResult
  .merge(
    z.object({
      id: z.string(),
      domain: z.literal(SearchDomain.enum['change-management']),
      domainType: z.literal('change-control'),
      title: z.string().nullable(),
      change_control_reference: z.string(),
    }),
  )
  .omit({ code: true });

const changeRequestResult = baseResult.merge(
  z.object({
    id: z.string(),
    domain: z.literal(SearchDomain.enum['change-management']),
    domainType: z.literal('change-request'),
    title: z.string().nullable(),
    updatedAt: z.string().pipe(z.coerce.date()),
    createdAt: z.string().pipe(z.coerce.date()),
  }),
);

const userResult = z.object({
  id: z.number(),
  domain: z.literal(SearchDomain.enum['users']),
  domainType: z.literal('user'),
  full_name: z.string(),
  email: z.string(),
  qri: z.string(),
});

const searchResult = z.discriminatedUnion('domain', [
  documentResult,
  eventResult,
  supplierResult,
  changeControlResult,
  userResult,
]);

export type DocumentResult = z.infer<typeof documentResult>;
export type EventResult = z.infer<typeof eventResult>;
export type SupplierResult = z.infer<typeof supplierResult>;
export type ChangeControlResult = z.infer<typeof changeControlResult>;
export type ChangeRequestResult = z.infer<typeof changeRequestResult>;
export type UserResult = z.infer<typeof userResult>;

// NOTE: You might read the code below and think "why not just use a generic type?"
// And the answer is that at best, zod will force the results field to be optional,
// and at worst, you'll drive yourself mad trying to get it to work.
// It's not worth your time.

export const SearchResponse = z.object({
  total: z.number(),
  totalRelation: z.union([z.literal('eq'), z.literal('gte')]),
  page: z.number(),
  size: z.number(),
  results: searchResult.array(),
});

export type SearchResponse = z.infer<typeof SearchResponse>;

export const SearchResponseForDocuments = SearchResponse.extend({
  results: z.array(documentResult),
});
type DocSchema = typeof SearchResponseForDocuments;

export const SearchResponseForEvents = SearchResponse.extend({
  results: z.array(eventResult),
});
type EventSchema = typeof SearchResponseForEvents;

export const SearchResponseForSuppliers = SearchResponse.extend({
  results: z.array(supplierResult),
});
type SupplierSchema = typeof SearchResponseForSuppliers;

export const SearchResponseForChangeManagement = SearchResponse.extend({
  results: z.array(changeControlResult),
});
type ChangeManagementSchema = typeof SearchResponseForChangeManagement;

export const SearchResponseForChangeRequest = SearchResponse.extend({
  results: z.array(changeRequestResult),
});
type ChangeRequestSchema = typeof SearchResponseForChangeRequest;

export const SearchResponseForUsers = SearchResponse.extend({
  results: z.array(userResult),
});
type UserSchema = typeof SearchResponseForUsers;

export type AllSearchSchemas =
  | DocSchema
  | EventSchema
  | SupplierSchema
  | ChangeManagementSchema
  | ChangeRequestSchema
  | UserSchema;

const searchResponseSchemas = {
  documents: SearchResponseForDocuments,
  events: SearchResponseForEvents,
  suppliers: SearchResponseForSuppliers,
  'change-management': SearchResponseForChangeManagement,
  'change-requests': SearchResponseForChangeRequest,
  users: SearchResponseForUsers,
} as const satisfies Record<SearchIndex, AllSearchSchemas>;

/**
 * Returns the correct search response schema for the given entity.
 * Depending on the entity supplied, the `results` field will be different.
 *
 * Will throw an error if the entity is not recognized.
 */
export function SearchResponseSchemaFor(entity: 'documents'): DocSchema;
export function SearchResponseSchemaFor(entity: 'events'): EventSchema;
export function SearchResponseSchemaFor(entity: 'suppliers'): SupplierSchema;
export function SearchResponseSchemaFor(
  entity: 'change-management',
): ChangeManagementSchema;
export function SearchResponseSchemaFor(
  entity: 'change-requests',
): ChangeRequestSchema;
export function SearchResponseSchemaFor(entity: 'users'): UserSchema;
export function SearchResponseSchemaFor(
  entity: SearchIndex,
): AllSearchSchemas | never;
export function SearchResponseSchemaFor(entity: SearchIndex): AllSearchSchemas {
  if (!SearchIndex.options.includes(entity)) {
    throw new Error(`No search response schema defined for entity: ${entity}`);
  }

  return searchResponseSchemas[entity];
}
