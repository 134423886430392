import { z } from 'zod';

// The following is used by quicksearch v1
export const SearchIndex = z.enum([
  // 'audits',
  'change-management',
  'documents',
  'events',
  'suppliers',
  'users',
  'change-requests',
]);

export type SearchIndex = z.infer<typeof SearchIndex>;

export const SearchDomain = z.enum([
  SearchIndex.Enum['change-management'],
  SearchIndex.Enum.documents,
  SearchIndex.Enum.suppliers,
  'quality-events',
  SearchIndex.enum.users,
  SearchIndex.Enum['change-requests'],
  'registry',
  'design-controls',
]);

export type SearchDomain = z.infer<typeof SearchDomain>;

// The following is used by quicksearch v2
export const SearchResourcesSchema = z.enum([
  'change-control',
  'change-request',
  'registry-item',
  'registry-category',
  'document',
  'quality-event',
  'supplier',
  'user',
  'dc-requirement',
  'dc-testCase',
  'dc-change-control',
  'dc-risk',
]);
export type SearchResource = z.infer<typeof SearchResourcesSchema>;
