import React from 'react';
import {
  FormControl,
  FormControlProps,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
} from '@chakra-ui/react';

export interface QFormControlProps extends FormControlProps {
  label?: string;
  error?: string;
  helper?: string | React.ReactElement;
}

export const QFormControl: React.FC<QFormControlProps> = (props) => {
  const { label, error, helper, ...rest } = props;
  return (
    <FormControl {...rest}>
      {!!label && (
        <FormLabel
          fontWeight="semibold"
          fontSize="sm"
          marginBottom={helper ? 0 : '8px'}
        >
          {label}
        </FormLabel>
      )}
      {!!helper && (
        <FormHelperText fontSize="xs" marginBottom="8px" marginTop={0}>
          {helper}
        </FormHelperText>
      )}
      {props.children}
      {!!error && <FormErrorMessage>{error}</FormErrorMessage>}
    </FormControl>
  );
};
