export const Heading = {
  baseStyle: {
    fontWeight: 'semibold',
    wordBreak: 'break-word',
  },
  sizes: {
    '3xl': {
      fontSize: '3xl',
      lineHeight: 'base',
    },
    '2xl': {
      fontSize: '2rem',
      lineHeight: 'base',
    },
    xl: {
      fontSize: '1.75rem',
      lineHeight: 'base',
    },
    lg: {
      fontSize: '2xl',
      lineHeight: 'base',
    },
    md: {
      fontSize: 'xl',
      lineHeight: 'base',
    },
    sm: {
      fontSize: 'md',
      lineHeight: 'shorter',
    },
    xs: {
      fontSie: 'sm',
      lineHeight: 'shorter',
    },
  },
};
