import React from 'react';
import type { RowData } from '@tanstack/react-table';
import { QText } from '../../../QAtoms';
import { CellProps } from './types';
import { getDataCy } from './meta';

/**
 * A simple cell that renders the value as text.
 */
export function QTextCell<TData extends RowData, TValue>({
  getValue,
  row: { original },
  column,
}: CellProps<TData, TValue>): React.ReactElement {
  return <QText data-cy={getDataCy(column, original)}>{getValue()}</QText>;
}
