export const Modal = {
  baseStyle: {
    header: {
      textTransform: 'lowercase',
      'h1::first-letter': { textTransform: 'uppercase' },
      'h2::first-letter': { textTransform: 'uppercase' },
      'p::first-letter': { textTransform: 'uppercase' },
    },
  },
};
