import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { TagTypeOptions } from './Tag';

const generalStyles = css`
  border-color: ${themeGet('colors.gray.50')};

  &:hover {
    background-color: ${themeGet('colors.gray.10')};
  }
`;
const infoStyles = css`
  border-color: ${themeGet('colors.neutral.border')};
  &:hover {
    background-color: ${themeGet('colors.neutral.background')};
  }
`;
const errorStyles = css`
  border-color: ${themeGet('colors.error.border')};
  &:hover {
    background-color: ${themeGet('colors.error.background')};
  }
`;
const successStyles = css`
  border-color: ${themeGet('colors.success.border')};

  &:hover {
    background-color: ${themeGet('colors.success.background')};
  }
`;

const typeStyles: Record<TagTypeOptions, FlattenSimpleInterpolation> = {
  general: generalStyles,
  info: infoStyles,
  error: errorStyles,
  success: successStyles,
};

export const StyledTag = styled.span<{ type: TagTypeOptions }>`
  padding: ${themeGet('sizes.2')} 12px;
  border: ${themeGet('sizes.1')} solid;
  height: 24px;
  display: inline-block;
  border-radius: 18px;

  ${(props) => typeStyles[props.type]}
`;
