import React from 'react';
import type { RowData } from '@tanstack/react-table';
import { QBadge } from '../../../QAtoms';
import { CellProps } from './types';
import { CellEventGuard } from './CellEventGuard';
import { getDataCy } from './meta';

/**
 * A cell that renders the value in a badge.
 */
export function QCodeCell<TData extends RowData, TValue>({
  getValue,
  column,
  row,
}: CellProps<TData, TValue>): React.ReactElement {
  return (
    <CellEventGuard>
      <QBadge maxWidth="100%" data-cy={getDataCy(column, row.original)}>
        {getValue()}
      </QBadge>
    </CellEventGuard>
  );
}
