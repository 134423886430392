import { useCallback, useMemo, useState } from 'react';
import { useCumulativeSearchParams } from '../../../hooks/cumulativeSearchParams';
import { FilterDefinitions, FilterUpdateFn, ResolvedFilters } from './types';

export type UseFilteringSearchParamsResult<T extends FilterDefinitions> = {
  filters: ResolvedFilters<T>;
  setFilters: FilterUpdateFn;
  searchTerm: string;
  setSearchTerm: (searchTerm: string | null) => void;
  forcedAt: number;
};

export const useFilteringSearchParams = <T extends FilterDefinitions>(
  definitions: T,
  searchTermKey: string,
): UseFilteringSearchParamsResult<T> => {
  const [searchParams, setSearchParams] = useCumulativeSearchParams();

  const filters: ResolvedFilters<T> = useMemo(() => {
    const resolved = {} as ResolvedFilters<T>;
    for (const key in definitions) {
      const raw = searchParams.get(key);

      // If we can't parse the value, log an error
      // but don't bail out entirely. Make a best
      // effort to parse the rest of the filters.
      const { label, schema } = definitions[key];
      const result = schema.safeParse(raw);
      if (result.success) {
        // Ignore null (i.e. unset) filters, we only care
        // about active filters.
        if (result.data !== null) {
          resolved[key] = { label, value: result.data };
        }
      } else {
        console.error(
          `Failed to parse search param "${key}" with value "${raw}"`,
          result.error,
        );
      }
    }
    return resolved;
  }, [definitions, searchParams]);

  const setFilters: FilterUpdateFn = useCallback(
    (filters) => {
      for (const key in filters) {
        if (filters[key] === '' || filters[key] === undefined) {
          filters[key] = null;
        }
      }
      setSearchParams(filters);
    },
    [setSearchParams],
  );

  const searchTerm = searchParams.get(searchTermKey) ?? '';
  const [forcedAt, setForcedAt] = useState(0);
  const setSearchTerm = useCallback(
    (searchTerm: string | null, forcedAt?: number) => {
      setSearchParams({
        [searchTermKey]: searchTerm === '' ? null : searchTerm,
      });
      forcedAt && setForcedAt(forcedAt);
    },
    [setSearchParams, searchTermKey],
  );

  return useMemo(
    () => ({
      filters,
      setFilters,
      searchTerm,
      setSearchTerm,
      forcedAt,
    }),
    [filters, setFilters, searchTerm, setSearchTerm, forcedAt],
  );
};
