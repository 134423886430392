import React from 'react';

import { Tooltip, TooltipProps } from '@chakra-ui/react';

export type QTooltipProps = Required<Pick<TooltipProps, 'label'>> &
  Pick<TooltipProps, 'placement' | 'openDelay'>;

/**
 * Shows a tooltip when hovering over the children.
 *
 * To work correctly, the children must accept a `ref`.
 * See the Storybook for an example.
 *
 * @example
 * <QTooltip label="This is a tooltip">Hover me</QTooltip>
 */
export const QTooltip: React.FC<QTooltipProps> = ({
  label,
  placement = 'bottom',
  openDelay,
  children,
}) => (
  <Tooltip label={label} placement={placement} openDelay={openDelay}>
    {children}
  </Tooltip>
);
