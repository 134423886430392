export const ICON_RETRY =
  '<svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">' +
  '<path fill-rule="evenodd" clip-rule="evenodd" d="M7.07024 1.90487C8.50152 1.7035 9.95947 1.97274 11.2244 2.67202C12.4894 3.3713 ' +
  '13.4928 4.46274 14.0835 5.7819C14.6742 7.10105 14.8202 8.57645 14.4995 9.98579C14.1788 11.3951 13.4088 12.6621 12.3054 13.5957C11.202 ' +
  '14.5294 9.8251 15.0791 8.38212 15.1622C6.93913 15.2452 5.50824 14.8571 4.30504 14.0562C3.10184 13.2553 2.19151 12.0851 1.71122 ' +
  '10.7218C1.58887 10.3746 1.7712 9.99386 2.11847 9.87151C2.46574 9.74916 2.84644 9.93149 2.96878 10.2788C3.35302 11.3694 4.08128 ' +
  '12.3055 5.04384 12.9462C6.0064 13.587 7.15111 13.8975 8.3055 13.831C9.45989 13.7646 10.5614 13.3248 11.4441 12.5779C12.3268 ' +
  '11.831 12.9429 10.8174 13.1994 9.68994C13.456 8.56246 13.3392 7.38214 12.8666 6.32682C12.3941 5.2715 11.5913 4.39834 10.5794 ' +
  '3.83892C9.56739 3.27949 8.40102 3.0641 7.256 3.2252C6.11098 3.3863 5.04932 3.91516 4.23101 4.7321C4.22625 4.73685 4.22142 4.74152 ' +
  '4.21652 4.74613L2.3497 6.50029H4.66667C5.03486 6.50029 5.33333 6.79877 5.33333 7.16696C5.33333 7.53515 5.03486 7.83363 4.66667 ' +
  '7.83363H0.666667C0.298477 7.83363 0 7.53515 0 7.16696V3.16696C0 2.79877 0.298477 2.50029 0.666667 2.50029C1.03486 2.50029 ' +
  '1.33333 2.79877 1.33333 3.16696V5.62572L3.29632 3.78118C4.31823 2.76417 5.64237 2.10576 7.07024 1.90487Z" fill="#0069FF"/></svg>';
