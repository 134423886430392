import React, { createContext, useCallback, useContext, useMemo } from 'react';
import { useFilteringSearchParams } from './searchParams';
import {
  FilteringContextValue,
  FilterDefinitions,
  ResolvedFilters,
  FilterUpdateFn,
} from './types';

export const FilterContext =
  createContext<FilteringContextValue<FilterDefinitions> | null>(null);

// There is no guarantee that the T of the context will match the T of this function.
export const useFilteringMaybe = <
  T extends FilterDefinitions,
>(): FilteringContextValue<T> | null =>
  useContext(FilterContext) as FilteringContextValue<T> | null;

// There is no guarantee that the T of the context will match the T of this function.
export const useFiltering = <
  T extends FilterDefinitions,
>(): FilteringContextValue<T> => {
  const context = useFilteringMaybe();
  if (!context) {
    throw new Error('useFilterContext must be used within a FilterProvider');
  }
  return context as FilteringContextValue<T>;
};

export type FilterProviderProps<T extends FilterDefinitions> = {
  definitions: T;
  searchTermKey: string;
  children: React.ReactNode;
};

export const FilterProvider = <T extends FilterDefinitions>({
  definitions,
  searchTermKey,
  children,
}: FilterProviderProps<T>): React.ReactElement => {
  const { filters, setFilters, searchTerm, setSearchTerm, forcedAt } =
    useFilteringSearchParams(definitions, searchTermKey);

  const removeFilter = useCallback(
    (key: string) => {
      setFilters({ [key]: null });
    },
    [setFilters],
  );

  const value: FilteringContextValue<T> = useMemo(
    () => ({
      definitions,
      filters,
      forcedAt,
      setFilters,
      removeFilter,
      searchTerm,
      setSearchTerm,
    }),
    [filters, forcedAt, setFilters, removeFilter],
  );

  return (
    <FilterContext.Provider value={value}>{children}</FilterContext.Provider>
  );
};

/**
 * Works the same as FilterProvider, but stores state locally rather than in the URL.
 */
export const ScopedFilterProvider = <T extends FilterDefinitions>({
  definitions,
  children,
}: Omit<FilterProviderProps<T>, 'searchTermKey'>): React.ReactElement => {
  const [filters, setFiltersRaw] = React.useState<ResolvedFilters<T>>(
    {} as ResolvedFilters<T>,
  );
  const [searchTerm, setSearchTermRaw] = React.useState('');
  const [forcedAt, setForcedAt] = React.useState(0);

  const removeFilter = useCallback(
    (key: string) => {
      setFiltersRaw((prev) => ({ ...prev, [key]: null }));
    },
    [setFiltersRaw],
  );

  const setFilters: FilterUpdateFn = useCallback(
    (filters) => setFiltersRaw((prev) => ({ ...prev, ...filters })),
    [setFiltersRaw],
  );

  const setSearchTerm = useCallback(
    (searchTerm: string | null, forcedAt?: number) => {
      setSearchTermRaw(searchTerm ?? '');
      if (forcedAt) {
        setForcedAt(forcedAt);
      }
    },
    [],
  );

  const value: FilteringContextValue<T> = useMemo(
    () => ({
      definitions,
      filters,
      forcedAt,
      setFilters,
      removeFilter,
      searchTerm,
      setSearchTerm,
    }),
    [filters, setFilters, searchTerm, setSearchTerm],
  );

  return (
    <FilterContext.Provider value={value}>{children}</FilterContext.Provider>
  );
};
