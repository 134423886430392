import React from 'react';

export const CaretUp: React.FC = (props) => {
  return (
    <svg
      {...props}
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.00001 5.52539L3.99335 9.53206L4.93601 10.4754L8.00268 7.40872L11.0693 10.4754L12.0073 9.53206L8.00001 5.52539Z"
        fill="#0069FF"
      />
    </svg>
  );
};

export default CaretUp;
