import { RowData } from '@tanstack/react-table';
import React from 'react';
import { QTag, QTooltip } from '../../../QAtoms';
import { getDataCy, getMetaField } from './meta';
import { CellProps } from './types';

const WILDCARD_STATUS = '*';

export function QStatusCell<TData extends RowData>({
  getValue,
  column,
  row: { original },
}: CellProps<TData, string>): React.ReactElement {
  const { statuses, tooltip } = getMetaField(column, 'status');
  const v = getValue();
  const tooltipContent = tooltip?.(original);

  const element = (
    <QTag
      variantColor={statuses[v] ?? statuses[WILDCARD_STATUS]}
      data-cy={getDataCy(column, original)}
    >
      {v}
    </QTag>
  );

  return tooltipContent ? (
    <QTooltip label={tooltipContent}>{element}</QTooltip>
  ) : (
    element
  );
}
