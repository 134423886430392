import React, { useEffect, useRef, useState } from 'react';
import { Badge, HTMLChakraProps } from '@chakra-ui/react';
import { DataProps, QMarginProps, QText, QTooltip } from '../..';

export type QBadgeProps = QMarginProps & HTMLChakraProps<'span'> & DataProps;

export const QBadge: React.FC<QBadgeProps> = ({ children, ...rest }) => {
  const textRef = useRef<HTMLParagraphElement | null>(null);
  const [textIsTruncated, setTextIsTruncated] = useState(false);

  useEffect(() => {
    if (!textRef.current) {
      return;
    }
    // The text has been truncated with an ellipse if the scrollWidth is
    // greater than the clientWidth (this means that it has overflowed
    // its bounding box and is not fully visible).
    setTextIsTruncated(
      textRef.current.scrollWidth > textRef.current.clientWidth,
    );
  }, [textRef.current]);

  const element = (
    <Badge {...rest}>
      <QText ref={textRef} isTruncated>
        {children}
      </QText>
    </Badge>
  );

  if (textIsTruncated && children) {
    return <QTooltip label={children}>{element}</QTooltip>;
  }
  return element;
};

QBadge.displayName = 'QBadge';
