import React from 'react';
import { QText } from '../Text/Text';

type Colour = `${string}.${number}00`;

export interface QDotSeparatorProps {
  color?: Colour;
  fontSize?: 'md' | 'sm' | 'xs';
}

export const QDotSeparator: React.FC<QDotSeparatorProps> = ({
  color = 'gray.400',
  fontSize,
}) => {
  return (
    <QText fontSize={fontSize} color={color}>
      •
    </QText>
  );
};
