import React from 'react';

/**
 * @deprecated Use {@link [partitionChildren](./partitionChildren.ts)}, or its associated hook, instead.
 */
export const getChildrenOfType = (
  children: React.ReactNode,
  ...expectedTypes: string[]
): (React.ReactChild | React.ReactFragment | React.ReactPortal)[] => {
  type TrickyReactElementWithDisplayNameReference = {
    type: { displayName: string };
  };
  return React.Children.toArray(children).filter(
    (item) =>
      React.isValidElement(item) &&
      expectedTypes.includes(
        (item as unknown as TrickyReactElementWithDisplayNameReference).type
          .displayName,
      ),
  );
};

/**
 * @deprecated Use {@link [partitionChildren](./partitionChildren.ts)}, or its associated hook, instead.
 */
export const excludeChildrenOfType = (
  children: React.ReactNode,
  ...expectedTypes: string[]
): (React.ReactChild | React.ReactFragment | React.ReactPortal)[] => {
  type TrickyReactElementWithDisplayNameReference = {
    type: { displayName: string };
  };
  return React.Children.toArray(children).filter(
    (item) =>
      !React.isValidElement(item) ||
      !expectedTypes.includes(
        (item as unknown as TrickyReactElementWithDisplayNameReference).type
          .displayName,
      ),
  );
};
