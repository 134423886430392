import { z, ZodBoolean, ZodDate, ZodNumber, ZodSchema, ZodString } from 'zod';
import { FilterDefinitions } from './types';

/** Converts empty values to null before parsing and allows null values.
 */
const emptyMeansNull = <T extends ZodSchema>(schema: T): ZodSchema =>
  z.preprocess(
    (v) => (v === undefined || v === '' ? null : v),
    schema.nullable(),
  );

export type SchemaModifier<T> = (schema: T) => ZodSchema;
export type SchemaConstructor<T> = (modify?: SchemaModifier<T>) => ZodSchema;

export const NumberSchema: SchemaConstructor<ZodNumber> = (modify) => {
  const schema = modify?.(z.coerce.number()) ?? z.coerce.number();
  return z.preprocess(
    (v) => (isNaN(Number(v)) ? null : v),
    emptyMeansNull(schema),
  );
};

export const BooleanSchema: SchemaConstructor<ZodBoolean> = (modify) => {
  const schema = modify?.(z.boolean()) ?? z.boolean();
  return z.preprocess((v) => {
    if (v === 'true') {
      return true;
    }
    if (v === 'false') {
      return false;
    }
    return v;
  }, emptyMeansNull(schema));
};
export const StringSchema: SchemaConstructor<ZodString> = (modify) => {
  const schema = modify?.(z.string()) ?? z.string();
  return emptyMeansNull(schema);
};

export const DateSchema: SchemaConstructor<ZodDate> = (modify) => {
  const schema = modify?.(z.date()) ?? z.date();
  return emptyMeansNull(schema);
};

/** A Zod object schema that can be used as a react-hook-form resolver. */
export type FilterFormSchema<T extends FilterDefinitions> = z.ZodObject<{
  [K in keyof T]: T[K]['schema'];
}>;
export type ValidatedFormData<T extends FilterDefinitions> = z.infer<
  FilterFormSchema<T>
>;
