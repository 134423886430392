import React, { forwardRef } from 'react';
import { Icon } from '@chakra-ui/react';
import { QIconProps } from './Icon';

export const ResourceRegistry = forwardRef<SVGSVGElement, Partial<QIconProps>>(
  (props, ref) => {
    return (
      <Icon
        height="16"
        viewBox="0 0 16 16"
        color="currentColor"
        {...props}
        ref={ref}
      >
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16">
          <rect width="16" height="16" fill="#E6E7EB" rx="2" />
          <g clipPath="url(#a)">
            <path
              fill="#14151A"
              fillRule="evenodd"
              d="m4.667 5.083.001.007a.123.123 0 0 0 .013.027.515.515 0 0 0 .122.125c.137.109.362.225.677.33.626.208 1.517.345 2.52.345 1.003 0 1.894-.137 2.52-.345.315-.105.54-.221.678-.33a.516.516 0 0 0 .12-.125.123.123 0 0 0 .016-.034l-.002-.006a.123.123 0 0 0-.013-.028.516.516 0 0 0-.121-.125c-.138-.109-.363-.224-.678-.33C9.894 4.387 9.003 4.25 8 4.25c-1.003 0-1.894.136-2.52.345-.315.105-.54.22-.677.329a.515.515 0 0 0-.122.125.124.124 0 0 0-.013.028l-.001.006Zm6.667 1.053c-.168.085-.353.16-.55.226-.732.244-1.716.388-2.784.388-1.068 0-2.052-.144-2.783-.388a3.974 3.974 0 0 1-.55-.226V8l.001.007a.125.125 0 0 0 .014.028.511.511 0 0 0 .12.125c.136.108.36.224.674.329.624.208 1.514.344 2.524.344s1.9-.136 2.524-.344c.315-.105.538-.22.674-.329a.511.511 0 0 0 .12-.125.125.125 0 0 0 .014-.028L11.334 8V6.136Zm.833-1.053c0-.355-.216-.627-.454-.814-.242-.19-.566-.344-.93-.465-.73-.244-1.715-.387-2.783-.387-1.068 0-2.052.143-2.783.387-.364.121-.688.275-.93.465-.237.187-.454.46-.454.814v5.833c0 .354.214.626.451.814.242.191.565.345.928.466.73.244 1.715.387 2.788.387 1.074 0 2.058-.143 2.788-.387.363-.121.687-.275.928-.466.237-.188.45-.46.45-.814V5.083Zm-.833 3.972a3.77 3.77 0 0 1-.546.224c-.73.244-1.714.387-2.788.387-1.073 0-2.058-.143-2.788-.387a3.949 3.949 0 0 1-.545-.224v1.861l.001.008a.125.125 0 0 0 .014.028.51.51 0 0 0 .12.125c.136.108.36.224.674.329.624.208 1.514.344 2.524.344s1.9-.136 2.524-.344c.315-.105.538-.22.674-.329a.51.51 0 0 0 .12-.125.126.126 0 0 0 .014-.028l.002-.008V9.055Z"
              clipRule="evenodd"
            />
          </g>
        </svg>
      </Icon>
    );
  },
);

ResourceRegistry.displayName = 'ResourceRegistry';
