import { getChildrenOfType, QBox, QStack } from '../../../..';
import React, { useEffect } from 'react';
import { useQStepperContext } from '../../StepperContext';

type QStepsProps = {
  children: React.ReactNode;
};

export const QSteps: React.FC<QStepsProps> = ({ children }) => {
  const { setSteps, activeStep, steps } = useQStepperContext();

  const childrenArray = React.Children.toArray(children);
  const stepsElements = getChildrenOfType(childrenArray, 'QStep');
  const progressBarElements = getChildrenOfType(
    childrenArray,
    'QStepperProgress',
  );
  const actionButtonElements = getChildrenOfType(
    childrenArray,
    'QStepsActions',
  );

  useEffect(() => {
    setSteps(
      stepsElements.map((item, index) => {
        if (React.isValidElement(item)) {
          return {
            ...item.props,
            index,
            component: React.isValidElement(item) ? item.props.children : item,
          };
        }
      }),
    );
  }, [children]);

  return (
    <QBox display="flex" h="100%" flexDirection={['column', 'column', 'row']}>
      <QBox
        w={['100%', '100%', '256px']}
        borderRadius="base"
        p={4}
        border="1px"
        borderColor="gray.200"
        mr={[0, 0, 4]}
        mb={[4, 4, 0]}
        h="fit-content"
      >
        {progressBarElements}
        <QStack direction={['row', 'row', 'column']} spacing={3}>
          {stepsElements.map((child, i) =>
            React.isValidElement(child)
              ? React.cloneElement(child, { index: i, ...child.props })
              : null,
          )}
        </QStack>
        {actionButtonElements}
      </QBox>
      <QBox h="100%" flexGrow={1} display="flex" flexDirection="column">
        <QBox h="100%" pl={1}>
          {steps?.[activeStep]?.children}
        </QBox>
      </QBox>
    </QBox>
  );
};
