import { Text } from '../Text/Text';
import React from 'react';
import { SpaceProps } from 'styled-system';
import { QualioTheme } from 'theme';
import {
  InputIconWrapper,
  StyledCheckOutlined,
  StyledInput,
  StyledLabel,
  StyledTextarea,
  StyledWarningFilled,
} from './Input.styles';
import { useInternalForm } from '../../components/Form/Form.context';

type InputType = 'text' | 'textarea' | 'number';

export type InputProps = {
  label?: string;
  name: string;
  type?: InputType;
  initialValue?: string;
  placeholder?: string;
  error?: string;
  success?: string | boolean;
  readonly?: boolean;
  disabled?: boolean;
  required?: boolean;
  rows?: number;
  step?: number;
  min?: number;
  max?: number;
  large?: boolean;
} & SpaceProps<QualioTheme>;

/**
 * @deprecated
 * Please use equivalent component in QAtoms or QComponents
 */

export const Input: React.FC<InputProps> = ({
  label,
  name,
  type = 'text',
  error,
  success, // eslint-disable-next-line @typescript-eslint/no-unused-vars
  initialValue = '', // this is used in the containing compound component
  placeholder,
  readonly = false,
  disabled = false,
  required = false,
  rows = 3,
  step = 1,
  min = 0,
  max,
  large = false,
  ...styles
}) => {
  const { register, dirty, errors } = useInternalForm();
  const hasError = errors[name]?.message || error;
  const hasSuccess = (!!dirty[name] && !hasError) || success;

  const salt = Math.floor(Math.random() * 100000);
  const id = `${name}-${salt}`;

  const props = {
    ...register(name),
    disabled: disabled || readonly,
    readonly,
    placeholder,
    error: hasError,
    success: hasSuccess,
    type,
    step,
    min,
    max,
    large,
    id,
    label,
    'aria-label': name,
    ...styles,
  };

  const iconProps = {
    success: hasSuccess,
    error: hasError,
    large,
    label,
  };

  return (
    <div style={{ display: 'block', position: 'relative' }}>
      {label ? (
        <StyledLabel htmlFor={id}>
          <Text size={large ? 'text' : 'sm'}>
            {label}
            {required && ' (required)'}
          </Text>
        </StyledLabel>
      ) : null}
      {type === 'textarea' ? (
        <StyledTextarea rows={rows} {...props} />
      ) : (
        <StyledInput {...props} />
      )}
      {props.success ? (
        <InputIconWrapper {...iconProps}>
          <StyledCheckOutlined {...iconProps} />
        </InputIconWrapper>
      ) : null}
      {props.error ? (
        <InputIconWrapper {...iconProps}>
          <StyledWarningFilled {...iconProps} />
        </InputIconWrapper>
      ) : null}

      <Text size="xs" type="success">
        {success || null}
      </Text>
      <Text size="xs" type="error">
        {hasError ? error || errors[name]?.message : null}
      </Text>
    </div>
  );
};
