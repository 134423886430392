export default {
  primary: {
    120: '#003F99',
    110: '#0A56C2',
    100: '#0069FF',
    80: '#3D82FF',
    60: '#6DA1FF',
    40: '#B2CEFF',
    20: '#DBE8FF',
    10: '#F3F7FF',
  },
  secondary: {
    100: '#001855',
    80: '#263B6F',
    60: '#617096',
    40: '#ADB5C8',
    20: '#DBDFE7',
    10: '#F0F1F5',
    background: '#F5F7FA',
  },
  gray: {
    100: '#2E2E33',
    80: '#45454D',
    60: '#737480',
    40: '#B6B7C2',
    20: '#E1E1E5',
    10: '#F2F3F5',
    5: '#F8F9FA',
  },
  success: {
    text: '#18662A',
    icon: '#28A745',
    border: '#53B96A',
    background: '#EAF6EC',
  },
  warning: {
    text: '#715400',
    icon: '#FFC107',
    border: '#FFDA6A',
    background: '#FFF9E6',
  },
  error: {
    text: '#A91E2B',
    icon: '#E52C3E',
    border: '#E35D6A',
    background: '#FBEBEC',
  },
  neutral: {
    text: '#0A56C2',
    icon: '#3D82FF',
    border: '#6DA1FF',
    background: '#F3F7FF',
  },
  background: {
    primary: '#FFF',
    secondary: '#F8F9FA',
    border: '#E1E1E5',
  },
  divider: '#D7DBE0',
  black: '#000',
  white: '#FFF',
};
