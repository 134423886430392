import React from 'react';

export const ArrowUp: React.FC = (props) => {
  return (
    <svg
      {...props}
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.66667 5.21996L11.06 7.60663L12 6.66663L8 2.66663L4 6.66663L4.94 7.60663L7.33333 5.21996V13.3333H8.66667V5.21996Z"
        fill="#0069FF"
      />
    </svg>
  );
};

export default ArrowUp;
