export const Drawer = {
    variants: {
      clickThrough: {
        dialog: {
          pointerEvents: "auto"
        },
        dialogContainer: {
          pointerEvents: "none"
        }
      }
    }
};
