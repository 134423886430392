import React from 'react';
import * as Chakra from '@chakra-ui/react';
import { BORDER_STYLE } from './common';
import { useCellEventGuardContext } from '../Cells/CellEventGuard';
import { DataProps } from '../../../utils';

export type QTableRowProps = {
  isSelected?: boolean;
  toggleSelected?: (event: unknown) => void;
} & DataProps;

export const QTableRow: React.FC<QTableRowProps> = ({
  isSelected,
  toggleSelected,
  children,
  ...dataProps
}) => {
  const [interactingWithCellContent] = useCellEventGuardContext();
  return (
    <Chakra.Tr
      {...BORDER_STYLE}
      backgroundColor={isSelected ? 'blue.50' : undefined}
      _hover={{
        backgroundColor:
          !interactingWithCellContent && toggleSelected
            ? getHoverColor(isSelected)
            : undefined,
      }}
      onClick={interactingWithCellContent ? undefined : toggleSelected}
      {...dataProps}
    >
      {children}
    </Chakra.Tr>
  );
};

const getHoverColor = (isSelected: boolean | undefined) =>
  isSelected ? 'blue.100' : 'gray.50';
