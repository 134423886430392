import React, { useCallback, useEffect, useMemo } from 'react';
import {
  QueryFunctionContext as ReactQueryFunctionContext,
  QueryOptions as ReactQueryOptions,
  useQuery,
  UseQueryOptions as ReactUseQueryOptions,
} from 'react-query';
import { PaginationContextValue, usePaginationMaybe } from '../../Pagination';
import { SortingContextValue, useSortingMaybe } from '../../Sorting';
import {
  FilterDefinitions,
  ResolvedFilters,
  useFilteringMaybe,
} from '../..//Filtering';
import { DataContext } from '../context';
import { DataProvider } from '../types';
import { useDebounce } from '../../../../hooks/useDebounce';

/**
 * The keys to use for the query parameters in the URL.
 * This is used by the `pageParam` to generate the URL search params.
 *
 * @see {@link PageParams.toUrlSearchParams}
 */
export type QueryParamKeys = {
  /** When we have a user-defined search term, what key should we use? */
  searchTerm: string;
  /**
   * When we have pagination info, what key should we use for the page index?
   * For example, you might use `offset` if using limit/offest pagination.
   */
  pageIndex: string;
  /**
   * When we have pagination info, what key should we use for the page size?
   * For example, you might use `limit` if using limit/offest pagination.
   */
  pageSize: string;
  /**
   * When we have sorting info, what key should we use for the sort field?
   */
  sortBy?: string;
};

export type PageParams = {
  /**
   * The user-entered search term, if any.
   */
  searchTerm: string | null;
  /**
   * The filters that should be applied to the data, if any.
   */
  filters: ResolvedFilters<FilterDefinitions> | null;
  /**
   * The sorting info, if a sorting provider was found.
   */
  sorting: SortingContextValue | null;
  /**
   * Pagination info, if a pagination provider was found.
   */
  pagination: PaginationContextValue | null;
  /**
   * Converts the page params to a URLSearchParams object.
   *
   * @see {@link QueryParamKeys}
   */
  toUrlSearchParams: () => URLSearchParams;
};

type ExtendedQueryKey<T extends readonly unknown[]> = [
  ...T,
  number | null, // forcedAt
  number | null, // Pagination page size
  number | null, // Pagination page index
  SortingContextValue | null, // Sorting info if available
  ResolvedFilters<FilterDefinitions> | null, // Filtering info if available
  string | null, // Search term if set
];

type BaseQueryKey = readonly unknown[];

export type QueryOptions<TData, TQueryKey extends BaseQueryKey> = Omit<
  ReactQueryOptions<
    TData[] | TDataWithCount<TData>,
    unknown,
    TData[] | TDataWithCount<TData>,
    ExtendedQueryKey<TQueryKey>
  >,
  'queryKey' | 'queryFn'
> &
  Pick<ReactUseQueryOptions, 'enabled'>;

export type QueryFunctionContext<
  TQueryKey extends BaseQueryKey,
  TQueryParams extends PageParams,
> = Pick<
  Required<
    ReactQueryFunctionContext<ExtendedQueryKey<TQueryKey>, TQueryParams>
  >,
  'queryKey' | 'pageParam' | 'meta'
>;

export type TDataWithCount<TData> = {
  data: TData[];
  itemCount?: number;
};

export type RemoteDataProviderProps<TData, TQueryKey extends BaseQueryKey> = {
  /**
   * The query key to use for the remote query.
   * The actual query key used will be this key plus the pagination and search term.
   */
  queryKey: TQueryKey;
  /**
   * The function to call to fetch the data.
   * The context is guaranteed to have a `pageParam`, though the individual fields
   * within may be null if the corresponding feature is not available.
   * If providing the `itemCount` as part of the `TDataWithCount` result, then you should
   * not provide an `itemCount` in the Pagination provider.
   *
   * @see {@link PageParams}
   */
  queryFn: (
    context: QueryFunctionContext<TQueryKey, PageParams>,
  ) => Promise<TData[] | TDataWithCount<TData>>;
  /**
   * Options to pass to the `useQuery` hook.
   */
  queryOptions?: QueryOptions<TData, TQueryKey>;
  /**
   * The keys to use for the query parameters in the URL.
   * This is used by the `pageParam` to generate the URL search params for you.
   *
   * @see {@link PageParams.toUrlSearchParams}
   */
  queryParamKeys: QueryParamKeys;
  children: React.ReactNode;
};

export const Remote = <TData, TQueryKey extends BaseQueryKey>({
  queryKey,
  queryFn,
  queryOptions,
  queryParamKeys,
  children,
}: RemoteDataProviderProps<TData, TQueryKey>): React.ReactElement => {
  const pagination = usePaginationMaybe();
  const sorting = useSortingMaybe();
  const filtering = useFilteringMaybe();

  const updateSearchTerm = useCallback(
    (term: string) => {
      const prev = filtering?.searchTerm;
      if (prev !== term) {
        pagination?.setPageIndex(0);
        filtering?.setSearchTerm(term);
      }
    },
    [pagination, filtering?.searchTerm, filtering?.setSearchTerm],
  );

  const debouncedSearchTerm = useDebounce(filtering?.searchTerm, 1000);

  const query = useQuery<
    TData[] | TDataWithCount<TData>,
    unknown,
    TData[] | TDataWithCount<TData>,
    ExtendedQueryKey<TQueryKey>
  >(
    [
      ...queryKey,
      filtering?.forcedAt ?? null,
      pagination?.pageSize ?? null,
      pagination?.pageIndex ?? null,
      sorting,
      filtering?.filters ?? null,
      debouncedSearchTerm ?? null,
    ],
    (context) =>
      queryFn({
        ...context,
        pageParam: pageParamFromContext(context, queryParamKeys, pagination),
      }),
    queryOptions,
  );

  useEffect(() => {
    const { data } = query;
    if (!pagination?.setItemCount || !data) {
      return;
    }

    if (Array.isArray(data)) {
      if (data.length === 0) {
        // We've reached the end of the data,
        // so we know there are no more pages.
        pagination.setPageCount?.(pagination.pageIndex + 1);
      }
      return;
    }

    pagination.setItemCount(data.itemCount ?? 0);

    if (pagination.setPageCount && data.itemCount) {
      pagination.setPageCount(
        data.itemCount > 0
          ? Math.ceil(data.itemCount / pagination.pageSize)
          : 1,
      );
    }
  }, [query.data]);

  const value: DataProvider<TData> = useMemo(
    () => ({
      onSearchTermChange: updateSearchTerm,
      data: Array.isArray(query.data) ? query.data : query.data?.data ?? [],
      isLoading: query.isLoading,
      error: query.error,
      isFetchingNextPage: query.isLoading,
      fetchNextPage: () =>
        pagination === null
          ? console.error('Cannot fetch next page: no pagination provider!')
          : pagination.setPageIndex(pagination.pageIndex + 1),
      hasNextPage:
        pagination !== null &&
        (Array.isArray(query.data)
          ? query.data?.length ?? 0
          : query.data?.itemCount ?? 0) >= pagination.pageSize,
    }),
    [query, pagination, updateSearchTerm],
  );

  return <DataContext.Provider value={value}>{children}</DataContext.Provider>;
};

const pageParamFromContext = <T extends BaseQueryKey>(
  context: ReactQueryFunctionContext<ExtendedQueryKey<T>, PageParams>,
  keys: QueryParamKeys,
  pagination: PaginationContextValue | null,
): PageParams => {
  const searchTerm = context.queryKey.at(-1) as string | null;
  const filters = context.queryKey.at(
    -2,
  ) as ResolvedFilters<FilterDefinitions> | null;
  const sorting = context.queryKey.at(-3) as SortingContextValue | null;
  return {
    searchTerm,
    filters,
    sorting,
    pagination,
    toUrlSearchParams: () => {
      const params = new URLSearchParams();
      if (searchTerm) {
        params.set(keys.searchTerm, searchTerm);
      }
      if (filters) {
        Object.entries(filters).forEach(([key, { value }]) => {
          if (value !== null) {
            params.set(
              key,
              value instanceof Date ? value.toISOString() : `${value}`,
            );
          }
        });
      }
      if (sorting?.column) {
        params.set(
          keys.sortBy ?? 'orderBy',
          `${sorting.descending ? '-' : ''}${sorting.column}`,
        );
      }
      if (pagination) {
        params.set(keys.pageIndex, pagination.pageIndex.toString());
        params.set(keys.pageSize, pagination.pageSize.toString());
      }
      return params;
    },
  };
};
