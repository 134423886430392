import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

export const StepBody = styled.div`
  flex: 1;
  justify-content: center;
  align-items: flex-start;
  display: flex;
  padding: 24px 0;
`;

export const StepBodyFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: ${themeGet('sizes.1')} solid ${themeGet('colors.divider')};
  padding-top: ${themeGet('sizes.5')};
`;

export const StepBodyFooterActionWrapper = styled.div`
  display: flex;
  align-items: center;
`;
