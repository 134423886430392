import React from 'react';
import { usePartitionedChildren } from '../../utils';

export const QEmptyStateSecondaryButtonGroup: React.FC = ({ children }) => {
  const { QButton } = usePartitionedChildren(children, 'QButton');

  // Only max 2 secondary action buttons of type QButton is allowed but not required for all use cases
  return (
    <>
      {QButton[0] ?? null}
      {QButton[1] ?? null}
    </>
  );
};

QEmptyStateSecondaryButtonGroup.displayName = 'QEmptyStateSecondaryButtonGroup';
