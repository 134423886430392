import React from 'react';
import { QHeadingProps } from '../../QAtoms';
import { QBox, QFlex } from '..';
import { Default as DefaultLayout, DefaultLayoutProps } from './Default';

export type SplitPanelLayoutProps = Omit<DefaultLayoutProps, 'children'> & {
  children: [
    React.ReactElement<QHeadingProps>,
    React.ReactElement,
    React.ReactElement,
    ...React.ReactNode[],
  ];
};

export const SplitPanelLeft: React.VFC<SplitPanelLayoutProps> = ({
  children: [header, panel, content, ...footer],
  ...rest
}) => (
  <DefaultLayout {...rest}>
    {header}
    <QFlex flex="1" w="100%">
      <QBox minW="256px" flex="0 0 256px" marginRight="32px">
        {panel}
      </QBox>
      <QBox flex="1">{content}</QBox>
    </QFlex>
    <>{footer}</>
  </DefaultLayout>
);

export const SplitPanelRight: React.VFC<SplitPanelLayoutProps> = ({
  children: [header, panel, content, ...footer],
  ...rest
}) => (
  <DefaultLayout {...rest}>
    {header}
    <QFlex flex="1" w="100%">
      <QBox flex="1">{content}</QBox>
      <QBox minW="280px" flex="0 0 25%" marginLeft="32px">
        {panel}
      </QBox>
    </QFlex>
    <>{footer}</>
  </DefaultLayout>
);
