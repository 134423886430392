import { z } from 'zod';

const referenceDate = z.object({
  created: z.coerce.date().nullish(),
  lastModified: z.coerce.date().nullish(),
});

const QriInfoParser = z.object({
  companyId: z.number(),
  date: referenceDate,
  domain: z.string(),
  hasAccess: z.boolean().optional(),
  identifiers: z.object({
    code: z.string().nullish(),
    id: z.coerce.string(),
    matrixId: z.string().nullish(),
    qri: z.string(),
  }),
  name: z.string(),
  owner: z
    .object({
      email: z.string().optional(),
      fullName: z.string(),
      id: z.coerce.string(),
    })
    .nullable(),
  resource: z.string(),
  short: z.boolean(),
  status: z.string(),
  urls: z.object({
    view: z.string().nullable(),
    data: z.string(),
  }),
});

const QriReference = QriInfoParser.extend({
  relationsToSubjectQris: z.array(
    z.object({
      direction: z.enum(['to', 'from']),
      qri: z.string(),
      created: z.number(),
    }),
  ),
});

export const ReferenceSearchResponseSchema = z.object({
  qris: z.array(QriInfoParser),
  references: z.array(QriReference),
  size: z.number(),
  lastSearchAfterHash: z.string().optional(),
  hasMoreReferences: z.boolean(),
});

export type QriReference = z.infer<typeof QriReference>;

export type ReferenceSearchResponse = z.infer<
  typeof ReferenceSearchResponseSchema
>;
