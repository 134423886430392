import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { StyledCaretDown, StyledCaretUp } from '../../atoms/Icon';

export const StyledCell = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledTh = styled.th<{ sorted: boolean }>`
  padding: ${themeGet('sizes.4')};
  vertical-align: bottom;
  padding-right: ${({ sorted }) => `${sorted ? '0' : themeGet('sizes.5')}`};
  border-bottom: ${themeGet('sizes.2')} solid ${themeGet('colors.gray.20')};

  &:hover {
    border-bottom: ${themeGet('sizes.2')} solid ${themeGet('colors.gray.20')};
    cursor: pointer;
  }
  ${StyledCaretDown} {
    margin-left: ${themeGet('sizes.4')};
  }
  ${StyledCaretUp} {
    margin-left: ${themeGet('sizes.4')};
  }
`;

export const StyledTd = styled.td`
  height: 40px;
  padding: ${themeGet('sizes.4')};
  border-top: ${themeGet('sizes.1')} solid ${themeGet('colors.gray.20')};
`;

export const StyledTr = styled.tr<{ clickable: boolean }>`
  &:hover {
    ${StyledTd} {
      background-color: ${themeGet('colors.secondary.background')};
    }
    cursor: ${({ clickable }) => (clickable ? 'pointer' : 'default')};
  }
`;

export const StyledTable = styled.table`
  border-spacing: 0;
  border-collapse: collapse;
  font-size: ${themeGet('fontSizes.1')};
  width: 100%;
`;
