import React, { useRef } from 'react';

export type ChakraContainer = {
  chakraContainerRef: React.RefObject<HTMLDivElement>;
};

const ChakraContainerContext = React.createContext<ChakraContainer | undefined>(
  undefined,
);

export const useChakraContainer = (): ChakraContainer => {
  const context = React.useContext(ChakraContainerContext);
  if (!context) {
    throw new Error(
      'useChakraContainer can only be used inside ChakraContainerProvider',
    );
  }
  return context;
};

export const ChakraContainerProvider: React.FC = ({ children }) => {
  const chakraContainerRef = useRef<HTMLDivElement>(null);
  return (
    <ChakraContainerContext.Provider value={{ chakraContainerRef }}>
      <div ref={chakraContainerRef} />
      {children}
    </ChakraContainerContext.Provider>
  );
};
