import { QBox, QButtonGroup, QDivider, QSpacer } from '../../../..';
import React from 'react';

type QStepperPanelActionsProps = {
  children: React.ReactNode;
};

export const QStepperPanelActions: React.FC<QStepperPanelActionsProps> = ({
  children,
}) => (
  <QBox height="fit-content">
    <QDivider />
    <QBox display="flex" justifyContent="space-between" pt={6}>
      <QSpacer />
      <QButtonGroup>{children}</QButtonGroup>
    </QBox>
  </QBox>
);

QStepperPanelActions.displayName = 'QStepperPanelActions';
