import React from 'react';
import { createIcon } from '@chakra-ui/react';

/**
 *
 *   <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
 *     <circle cx="10" cy="10" r="6" fill={fill}/>
 *   </svg>
 */

//https://chakra-ui.com/docs/components/media-and-icons/icon#using-the-createicon-function
export const Dot = createIcon({
  displayName: 'Dot',
  viewBox: '0 0 20 20',
  path: <circle cx="10" cy="10" r="6" fill="currentColor" />,
});
