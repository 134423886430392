export * from './TabsOptions';

import React from 'react';
import { Tab, TabList, Tabs, TabsProps } from '@chakra-ui/react';

export type QTabHeader = {
  id: string;
  title: string | React.ReactElement;
};

type CommonProps = Pick<TabsProps, 'orientation' | 'isLazy'> & {
  variant?: 'enclosed';
};

export type QTabsProps = CommonProps & {
  tabs: readonly QTabHeader[];
  onClick?: (header: QTabHeader) => void;
  defaultActiveTabId?: string;
  activeTabId?: string;
};

export type QTabsWithCompositeProps = CommonProps &
  Pick<TabsProps, 'index' | 'onChange' | 'defaultIndex'>;

/** A wrapper around the Chakra UI Tabs component.
 * @see https://v1.chakra-ui.com/docs/components/disclosure/tabs
 */
export const QTabs: React.FC<QTabsProps | QTabsWithCompositeProps> = (
  props,
) => {
  if ('tabs' in props) {
    const {
      defaultActiveTabId,
      activeTabId,
      onClick,
      tabs,
      orientation = 'horizontal',
      variant,
      ...rest
    } = props;
    let defaultActiveTabIndex = 0;
    if (defaultActiveTabId) {
      const foundIndex = tabs.findIndex((tab) => tab.id === defaultActiveTabId);
      defaultActiveTabIndex = foundIndex ?? 0;
    }

    let activeTabIndex = 0;
    if (activeTabId) {
      const foundIndex = tabs.findIndex((tab) => tab.id === activeTabId);
      activeTabIndex = foundIndex ?? 0;
    }

    return (
      <Tabs
        h="full"
        variant={variant ?? orientation}
        defaultIndex={defaultActiveTabIndex}
        index={activeTabIndex}
        orientation={orientation}
        {...rest}
      >
        <TabList>
          {tabs.map((tab) => (
            <Tab
              key={tab.id}
              onClick={onClick ? () => onClick(tab) : undefined}
            >
              {tab.title}
            </Tab>
          ))}
        </TabList>
      </Tabs>
    );
  }

  const { orientation = 'horizontal', ...rest } = props;
  return (
    <Tabs h="full" orientation={orientation} variant={orientation} {...rest}>
      {props.children}
    </Tabs>
  );
};

QTabs.displayName = 'QTabs';
