import React from 'react';

import axios, { AxiosProgressEvent } from 'axios';
import {
  ActualFileObject,
  FilePondFile,
  FilePondInitialFile,
  ProgressServerConfigFunction,
} from 'filepond';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import { FilePond, registerPlugin } from 'react-filepond';
import { Md5 } from 'ts-md5';

import 'filepond/dist/filepond.min.css';
import { QTheme } from '../../QTheme';
import { AttachmentsContainer } from './Attachment.styles';
import { ICON_REMOVE } from './icons/iconRemove';
import { ICON_RETRY } from './icons/iconRetry';

registerPlugin(FilePondPluginFileValidateSize);
registerPlugin(FilePondPluginFileValidateType);

export const TOTAL_SIZE_EXCEEDED_MSG = 'Attachments combined exceed size limit';
export const FILE_TOO_LARGE_MSG = 'File is too large';

export type QAttachmentInfo = {
  filename: string;
  filesize: number;
  contentType: string;
};

export type QAttachmentDetails = QAttachmentInfo & {
  md5Sum: string;
};

export type QAttachment = QAttachmentInfo & {
  id: string;
};

export enum Destination {
  S3 = 'S3',
  MEDTECH = 'MEDTECH',
}

export type QAttachmentUploadInfo = {
  destination: Destination;
  url: string;
  id?: string;
};

export type DeletableFile = Pick<FilePondFile, 'id' | 'filename'>;

export type QAttachmentsProps = {
  filePondRef?: React.RefCallback<FilePond>;
  attachments: Array<QAttachment>;
  uploadInfoProvider: (
    fileDetails: QAttachmentDetails,
  ) => Promise<QAttachmentUploadInfo>;
  onAdd?: (file: QAttachment) => Promise<void>;
  onClick: (fileId: string) => Promise<void>;
  onFilesProcessed?: () => Promise<void>;
  onRemove: (fileId: string) => Promise<void>;
  /** If defined, should return true if the deletion should be confirmed immediately, false otherwise */
  onBeforeRemove?: (file: DeletableFile) => boolean;
  /** If defined, it should return true if the file should be added, false otherwise */
  onBeforeAddFile?: (file: QAttachment) => Promise<boolean>;
  onRevert: (fileId: string) => Promise<void>;
  onError?: (error, file?) => void;
  onWarning?: (warning, file?) => void;
  isBusy?: (isBusy: boolean) => void;
  maxFiles: number;
  maxFileSize: string;
  maxTotalFileSize: string;
  maxParallelUploads?: number;
  allowMultiple?: boolean;
  itemInsertLocation?: (a: QAttachmentInfo, b: QAttachmentInfo) => number;
  acceptedFileTypes?: string[];
  disabled?: boolean;
};

export const QAttachments: React.FC<QAttachmentsProps> = (props) => {
  const initialAttachments: Array<FilePondInitialFile> = props.attachments.map(
    (attachment) => ({
      source: attachment.id,
      filename: attachment.filename,
      options: {
        type: 'local',
        file: {
          name: attachment.filename,
          size: attachment.filesize,
          type: attachment.contentType,
        },
      },
    }),
  );

  const [uploadedFiles, setUploadedFiles] =
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    React.useState<any[]>(initialAttachments);

  const allowMultiple =
    props.allowMultiple === undefined
      ? props.maxFiles > 1
      : props.allowMultiple;
  const setBusy = (isBusy: boolean) =>
    setTimeout(() => props.isBusy && props.isBusy(isBusy));

  const maxParallelUploads = props.maxParallelUploads || 2;

  let label: string;
  if (props.maxFiles > 1) {
    label = `<span class="filepond--label-action">Browse</span> or drag and drop your files<br/><span class="filepond--sub-label">Max. ${props.maxFiles} files, up to ${props.maxFileSize} per file, ${props.maxTotalFileSize} total.</span>`;
  } else {
    label = `<span class="filepond--label-action">Browse</span> or drag and drop a file<br/><span class="filepond--sub-label">Maximum file size is ${props.maxFileSize}.</span>`;
  }

  return (
    <AttachmentsContainer QTheme={QTheme}>
      {props.children}
      <FilePond
        files={uploadedFiles}
        ref={props.filePondRef}
        beforeRemoveFile={(file) => {
          if (props.onBeforeRemove) {
            return props.onBeforeRemove(file);
          }
          return true;
        }}
        beforeAddFile={(file) => {
          if (props.onBeforeAddFile) {
            return props.onBeforeAddFile({
              id: file.serverId,
              filesize: file.fileSize,
              filename: file.filename,
              contentType: file.fileType,
            });
          }
          return Promise.resolve(true);
        }}
        onprocessfile={(error, uploadedFile) => {
          if (!error && props.onAdd) {
            props.onAdd({
              id: uploadedFile.serverId,
              filesize: uploadedFile.fileSize,
              filename: uploadedFile.filename,
              contentType: uploadedFile.fileType,
            });
          }
        }}
        onaddfilestart={(file) => {
          if (uploadedFiles.map((f) => f.filename).includes(file.filename)) {
            return file.abortLoad();
          }
          setBusy(true);
        }}
        onprocessfiles={() => {
          setBusy(false);
          if (allowMultiple && props.onFilesProcessed) {
            props.onFilesProcessed();
          }
        }}
        onaddfile={(error, file) => {
          if (!error && !allowMultiple) {
            setUploadedFiles([...uploadedFiles, file]);
          }
        }}
        onupdatefiles={(files) => {
          setUploadedFiles(files);
        }}
        onremovefile={(error, file) => {
          if (!error) {
            const updatedArrayOfUploadedFiles = uploadedFiles.filter(
              (f) => f.source !== file.source,
            );
            setUploadedFiles(updatedArrayOfUploadedFiles);
          }
        }}
        maxParallelUploads={maxParallelUploads}
        allowMultiple={allowMultiple}
        forceRevert={true}
        server={{
          process: (
            fieldName: string,
            file: FilePondFile | ActualFileObject,
            metadata: { [key: string]: string | number },
            load: (p: string | { [key: string]: string | number }) => void,
            error: (errorText: string) => void,
            progress: ProgressServerConfigFunction,
            abort: () => void,
          ) =>
            processHandler(
              props.uploadInfoProvider,
              fieldName,
              file,
              metadata,
              load,
              error,
              progress,
              abort,
            ),
          remove: (source, load, error) =>
            removeHandler(props.onRemove, source, load, error),
          revert: (uniqueFileId, load, error) =>
            revertHandler(props.onRevert, uniqueFileId, load, error),
        }}
        labelIdle={label}
        onactivatefile={(file) => props.onClick(file.serverId)}
        styleButtonRemoveItemPosition={'right'}
        onerror={props.onError}
        onwarning={props.onWarning}
        labelTapToUndo=""
        labelTapToCancel="Click to cancel"
        labelTapToRetry=""
        acceptedFileTypes={props.acceptedFileTypes}
        labelFileTypeNotAllowed="File of invalid type"
        iconRemove={ICON_REMOVE}
        iconRetry={ICON_RETRY}
        iconUndo={ICON_REMOVE}
        maxFiles={props.maxFiles}
        maxFileSize={props.maxFileSize}
        maxTotalFileSize={props.maxTotalFileSize}
        labelMaxFileSizeExceeded={FILE_TOO_LARGE_MSG}
        labelMaxFileSize={`Max. ${props.maxFileSize} per file`}
        labelMaxTotalFileSizeExceeded={TOTAL_SIZE_EXCEEDED_MSG}
        labelMaxTotalFileSize={`Max. ${props.maxTotalFileSize} total`}
        labelFileProcessingError="Error uploading"
        labelFileRemoveError="Error deleting"
        labelFileProcessingRevertError="Error deleting"
        itemInsertLocation={(a, b) => {
          if (props.itemInsertLocation && a.file && b.file) {
            return props.itemInsertLocation(
              toQAttachmentInfo(a),
              toQAttachmentInfo(b),
            );
          }
          return 0;
        }}
        disabled={props.disabled}
      />
    </AttachmentsContainer>
  );
};

const toQAttachmentInfo = (file: FilePondFile): QAttachmentInfo => ({
  filename: file.filename,
  filesize: file.fileSize,
  contentType: file.fileType,
});

const processHandler = (
  uploadInfoProvider: (
    fileDetails: QAttachmentDetails,
  ) => Promise<QAttachmentUploadInfo>,
  _fieldName: string,
  file,
  _metadata: { [key: string]: string | number },
  load: (p: string | { [key: string]: string | number }) => void,
  error: (errorText: string) => void,
  progress: ProgressServerConfigFunction,
  abort: () => void,
) => {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const md5Sum = new Md5().appendByteArray(file).end()?.toString() as string;

  uploadInfoProvider({
    filename: file.name,
    filesize: file.size,
    contentType: file.type,
    md5Sum,
  })
    .then((uploadInfo) => {
      const getAxiosS3Config = () => ({
        data: file,
        method: 'PUT',
        withCredentials: false,
      });

      const getAxiosMedtechConfig = () => {
        const data = new FormData();
        data.append('file_1', file);
        return {
          data,
          method: 'POST',
          withCredentials: true,
        };
      };

      const isMedtechDestination =
        uploadInfo.destination === Destination.MEDTECH;

      const config = isMedtechDestination
        ? getAxiosMedtechConfig()
        : getAxiosS3Config();

      const encodedFileName = encodeURIComponent(file.name);
      axios
        .request({
          url: uploadInfo.url,
          ...config,
          cancelToken: source.token,
          onUploadProgress: (e: AxiosProgressEvent) =>
            progress(!!e.total, e.loaded, e.total ?? e.loaded),
          headers: {
            'Content-Type': file.type,
            'Content-Disposition': `inline; filename="${encodedFileName}"; filename*=UTF-8''${encodedFileName}`,
          },
        })
        .then((res) => {
          const uploadId = isMedtechDestination ? res.data.id : uploadInfo.id;
          load(uploadId);
        })
        .catch((thrown) => {
          if (axios.isCancel(thrown)) {
            abort();
          } else {
            error(thrown);
          }
        });
    })
    .catch(error);
  return {
    abort: () => {
      source.cancel();
    },
  };
};

const removeHandler = (
  onRemove: (fileId: string) => Promise<void>,
  source: string,
  load: (p: string | { [key: string]: string | number } | void) => void,
  error: (errorText: string) => void,
) => {
  onRemove(source)
    .then(() => load())
    .catch(error);
};

const revertHandler = (
  onRevert: (fileId: string) => Promise<void>,
  uniqueFileId: string,
  load: (p: string | { [key: string]: string | number } | void) => void,
  error: (errorText: string) => void,
) => {
  onRevert(uniqueFileId)
    .then(() => load())
    .catch(error);
};
