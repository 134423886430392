import React from 'react';
import { ToastProvider, UtilitiesProvider } from './hooks';
import { CurrentUser } from './types/CurrentUser';
import { QualioQThemeProvider } from './QTheme';

type QualioQFrontEndProviderProps = {
  analytics?: SegmentAnalytics.AnalyticsJS;
  user: CurrentUser;
};

export const QualioQFrontEndProvider: React.FC<QualioQFrontEndProviderProps> =
  ({ user, analytics, children }) => (
    <UtilitiesProvider user={user} analytics={analytics}>
      <QualioQThemeProvider>
        <ToastProvider>{children}</ToastProvider>
      </QualioQThemeProvider>
    </UtilitiesProvider>
  );
