import { PaginationOptions, PaginationState } from '@tanstack/react-table';
import { createContext, useContext } from 'react';
import { PaginationContextValue } from './types';

const context = createContext<PaginationContextValue | null>(null);

export const { Provider: PaginationProvider } = context;

export const usePaginationMaybe = (): PaginationContextValue | null =>
  useContext(context);

// -------------------
// The code below is specific to @tanstack/react-table and adapts
// the more generic context above to make using it with the table
// easier.
//
// The reason this is separated from the code above is so that
// data providers, etc don't need to know about the table library.

export type TablePaginationContextValue = {
  state?: PaginationState;
} & PaginationOptions;

/**
 * Adapts a pagination context for use as table options.
 */
export const usePaginationForTable = (): TablePaginationContextValue => {
  const pagination = usePaginationMaybe();

  return {
    manualPagination: pagination !== null && pagination.clientSide !== true,
    state: pagination ?? undefined,
  };
};
