import { QBox, QText, useQStepperContext } from '../../../..';
import React from 'react';
import { QStepVariantOptions, QStepVariantStyles } from './StepStyles';

export type QStepProps = {
  title: string;
  description?: string;
  variant?: Exclude<QStepVariantOptions, 'active'>;
  index?: number;
};

export const QStep: React.FC<QStepProps> = ({
  title,
  description,
  variant,
  index,
  ...rest
}) => {
  const { activeStep, steps, onStepItemClicked } = useQStepperContext();

  const isVisited =
    typeof index === 'number' &&
    steps?.find((item) => item.index === index)?.variant === 'visited';

  let variantName;

  switch (true) {
    case activeStep === index:
      variantName = 'active';
      break;
    case !!variant:
      variantName = variant;
      break;
    case !!isVisited:
      variantName = 'visited';
      break;
    default:
      variantName = 'default';
      break;
  }
  const styles = QStepVariantStyles[variantName];

  return (
    <QBox
      {...rest}
      sx={styles.container}
      onClick={
        typeof index === 'number' && variantName !== 'locked'
          ? () => onStepItemClicked(index)
          : undefined
      }
    >
      <QBox>
        <QText sx={styles.title}>{title}</QText>
        <QText sx={styles.description}>{description}</QText>
      </QBox>
      <QBox sx={styles.iconContainer}>{styles.iconElement}</QBox>
    </QBox>
  );
};

QStep.displayName = 'QStep';
