import React from 'react';
import { usePartitionedChildren } from '../../utils';
import { QCenter, QStack } from '../../QLayouts';

export const QEmptyStateButtonGroup: React.FC = ({ children }) => {
  const { QEmptyStatePrimaryButton, QEmptyStateSecondaryButtonGroup } =
    usePartitionedChildren(
      children,
      'QEmptyStatePrimaryButton',
      'QEmptyStateSecondaryButtonGroup',
    );

  return (
    <QCenter>
      <QStack direction="row" mt={10}>
        {QEmptyStateSecondaryButtonGroup ?? null}
        {QEmptyStatePrimaryButton ?? null}
      </QStack>
    </QCenter>
  );
};

QEmptyStateButtonGroup.displayName = 'QEmptyStateButtonGroup';
