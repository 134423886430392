import React from 'react';
import { QPropertiesPanelOutlet } from '../../QOrganisms/PropertiesPanel/outlet';
import { QPropertiesPanelStateProvider } from '../../QOrganisms/PropertiesPanel/context';
import { QFlex } from '..';

export type DefaultLayoutProps = {
  children: [React.ReactElement, ...(React.ReactElement | null)[]];
};

export const Default: React.VFC<DefaultLayoutProps> = ({
  children: [header, ...content],
}) => (
  <QPropertiesPanelStateProvider>
    <QFlex direction="row" flexGrow={1}>
      <QFlex direction="column" h="100%" w="100%" alignItems="stretch">
        {header}
        <QFlex direction="column" flex="1" paddingTop="24px">
          {content}
        </QFlex>
      </QFlex>
      <QPropertiesPanelOutlet />
    </QFlex>
  </QPropertiesPanelStateProvider>
);
